<ng-container *ngIf="!options.hasArrived; else arrived">
	<div class="infoLine">
		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/arrow-right-circle.svg"
				alt=""
			/>
			<span class="dialog">
				{{ options.bookingConfirmationDate | date: 'dd/MM/yyyy' }}
			</span>
			<span class="subText">Booking confirmation</span>
		</span>

		<span class="line">
			<span class="smallDot left"></span>
			<span class="smallDot right"></span>
		</span>

		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/shipIcon.svg"
				alt=""
			/>
			<span class="dialog">
				<p class="dialogTitle">
					{{ options.ETDPort }}
				</p>
				<p>
					{{ options.ETDDate | date: 'dd/MM/yyyy' }}
				</p>
			</span>
			<span class="subText">ETD</span>
		</span>

		<div class="mixedLineContainer">
			<div class="line">
				<span class="smallDot left"></span>
				<span class="bigOrangeDot right"></span>
			</div>
			<div class="line dashed short">
				<span class="smallDot right"></span>
			</div>
		</div>

		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/flag.svg"
				alt=""
			/>
			<span class="dialog">
				<p class="dialogTitle">
					{{ options.ETAPort }}
				</p>
				<p>
					{{ options.ETADate | date: 'dd/MM/yyyy' }}
				</p>
			</span>
			<span class="subText">ETA</span>
		</span>

		<div class="line grey dashed">
			<span class="smallDot left grey"></span>
			<span class="smallDot right grey"></span>
		</div>

		<span class="roundIcon grey">
			<img
				src="/assets/Icons/NavHunterHeader/greyTick.svg"
				alt=""
			/>
			<span class="dialog grey">
				<p>
					{{ options.containerDischargeDate | date: 'dd/MM/yyyy' ?? '( - )' }}
				</p>
			</span>
			<span class="subText grey">Container discharge</span>
		</span>
	</div>
</ng-container>

<ng-template #arrived>
	<div class="infoLine">
		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/arrow-right-circle.svg"
				alt=""
			/>
			<span class="dialog">
				{{ options.bookingConfirmationDate | date: 'dd/MM/yyyy' }}
			</span>
			<span class="subText">Booking confirmation</span>
		</span>

		<span class="line">
			<span class="smallDot left"></span>
			<span class="smallDot right"></span>
		</span>

		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/shipIcon.svg"
				alt=""
			/>
			<span class="dialog">
				<p class="dialogTitle">
					{{ options.ETDPort }}
				</p>
				<p>
					{{ options.ETDDate | date: 'dd/MM/yyyy' }}
				</p>
			</span>
			<span class="subText">ETD</span>
		</span>

		<div class="mixedLineContainer">
			<div class="line large">
				<span class="smallDot left"></span>
				<span class="smallDot right"></span>
			</div>
		</div>

		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/flag.svg"
				alt=""
			/>
			<span class="dialog">
				<p class="dialogTitle">
					{{ options.ETAPort }}
				</p>
				<p>
					{{ options.ETADate | date: 'dd/MM/yyyy' }}
				</p>
			</span>
			<span class="subText">ETA</span>
		</span>

		<div class="line">
			<span class="smallDot left"></span>
			<span class="bigOrangeDot right"></span>
		</div>

		<span class="roundIcon">
			<img
				src="/assets/Icons/NavHunterHeader/greyTick.svg"
				alt=""
			/>
			<span class="dialog">
				<p>
					{{ options.containerDischargeDate | date: 'dd/MM/yyyy' ?? '( - )' }}
				</p>
			</span>
			<span class="subText">Container discharge</span>
		</span>
	</div>
</ng-template>
