import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Pages/login/login.component';
import { RegisterComponent } from './Pages/login/register/register.component';
import { HasTokenGuard } from './Services/Utils/Guards/has-token.guard';
import { IsTeacherGuard } from './Services/Utils/Guards/is-teacher.guard';
import { IsStudentGuard } from './Services/Utils/Guards/is-student.guard';

const routes: Routes = [
	{ path: '', component: LoginComponent },

	/**
	 * LOGIN
	 */
	{ path: 'login', component: LoginComponent },
	{ path: 'register', component: RegisterComponent },
	{
		path: 'home',
		loadChildren: () =>
			import('./Pages/home/home.module').then((m) => m.HomeModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'cases',
		loadChildren: () =>
			import('./Pages/cases/cases.module').then((m) => m.CasesModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'messages',
		loadChildren: () =>
			import('./Pages/messages/messages.module').then((m) => m.MessagesModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'commercial-offers',
		loadChildren: () =>
			import('./Pages/commercial-offers/commercial-offers.module').then(
				(m) => m.CommercialOffersModule
			),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'third-parties',
		loadChildren: () =>
			import('./Pages/third-parties/third-parties.module').then(
				(m) => m.ThirdPartiesModule
			),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'nav-hunter',
		loadChildren: () =>
			import('./Pages/nav-hunter/nav-hunter.module').then(
				(m) => m.NavHunterModule
			),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'shipment',
		loadChildren: () =>
			import('./Pages/shipment/shipment.module').then((m) => m.ShipmentModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'shipment-documentation',
		loadChildren: () =>
			import(
				'./Pages/shipment-documentation/shipment-documentation.module'
			).then((m) => m.ShipmentDocumentationModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'import',
		loadChildren: () =>
			import('./Pages/import/import.module').then((m) => m.ImportModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'vessel',
		loadChildren: () =>
			import('./Pages/vessel/vessel.module').then((m) => m.VesselModule),
		canActivate: [HasTokenGuard, IsStudentGuard]
	},
	{
		path: 'teacher',
		loadChildren: () =>
			import('./Pages/teacher/teacher.module').then((m) => m.TeacherModule),
		canActivate: [HasTokenGuard, IsTeacherGuard]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
