import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { iNavHunterOptionIcon } from 'src/app/Shared/Interfaces/Utils/iNavHunterOptionIcon';
import { iNavHunterSearchLine } from 'src/app/Shared/Interfaces/Utils/iNavHunterSearchLine';

@Component({
	selector: 'nav-hunter-search-line',
	templateUrl: './nav-hunter-search-line.component.html',
	styleUrls: ['./nav-hunter-search-line.component.scss']
})
export class NavHunterSearchLineComponent implements OnInit {
	@Output() select: EventEmitter<iNavHunterSearchLine> =
		new EventEmitter<iNavHunterSearchLine>();

	@Input() content: iNavHunterSearchLine = {
		transitTime: 2,
		price: 940,
		cargoType: 'LCL',
		originPortName: 'VANCOUVER',
		originPortCode: 'CAVAN',
		originPortCountry: 'CA',
		destinationPortName: 'ANTWERP',
		destinationPortCode: 'BEANR',
		destinationPortCountry: 'BE',
		transferPortName: 'DALIAN',
		transferPortCode: 'CNDLC',
		departureDate: 'Dec. 07',
		arrivalDate: 'Dec. 09',
		validUntil: 'Dec. 05, 2023',
		shippingLineName: 'Naviera 01',
		shippingLineImage: '/assets/Images/NavHunter/placeholderNaviera.png',
		type: 'Faster',
		shippingLineId: 1
	};

	isExtraLineShown: boolean = false;

	chatacteristics: iNavHunterOptionIcon = {
		option: 'Cheaper'
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes['content']) {
			if (changes['content'].currentValue) {
				let content = changes['content'].currentValue;
				this.chatacteristics.option = content.type;
			}
		}
	}

	constructor() {}

	ngOnInit(): void {}

	showExtraLine() {
		this.isExtraLineShown = !this.isExtraLineShown;
	}

	setPlaceholderImage(event) {
		event.target.src = '/assets/Images/NavHunter/placeholderNaviera.png';
	}

	sendSelect() {
		this.select.emit(this.content);
	}
}
