import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	StaticUtilitiesService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ContainerTypeService } from 'src/app/Services/Api/container-type.service';
import { CostService } from 'src/app/Services/Api/cost.service';
import { WareContainerService } from 'src/app/Services/Api/ware-container.service';
import { CalculateCostMarginPriceService } from 'src/app/Services/Utils/CalculateCostMarginPrice.service';
import { AddInlandTransportationCostPopupClass } from 'src/app/Shared/Class/commercial-offers/AddInlandTransportationCostPopupClass';
import { iCalculateCostMarginPriceObject } from 'src/app/Shared/Interfaces/Utils/iCalculateCostMarginPriceObject';

@Component({
	selector: 'app-addInlandTransportationCost-popup',
	templateUrl: './addInlandTransportationCost-popup.component.html',
	styleUrls: ['./addInlandTransportationCost-popup.component.scss']
})
export class AddInlandTransportationCostPopupComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.closePopup('noreturn');
		}
	}

	addInlandTransportationCostPopupClass: AddInlandTransportationCostPopupClass =
		new AddInlandTransportationCostPopupClass();

	constructor(
		public dialogRef: MatDialogRef<AddInlandTransportationCostPopupComponent>,
		private containerTypeSE: ContainerTypeService,
		private wareContainerSE: WareContainerService,
		private costSE: CostService,
		private calculateCostMarginPriceSE: CalculateCostMarginPriceService,
		@Inject(MAT_DIALOG_DATA) public modalData: any
	) {}

	ngOnInit() {
		this.getWareContainerSelector();
		this.getCostTitleSelector();
	}

	/**
	 * HANDLERS
	 */

	saveContainerType(res: iResultHttp) {
		let { data } = res;
		this.addInlandTransportationCostPopupClass.containerTypeSelectorOptions.items =
			data;
	}

	successgetCostTitleSelectorHandler(res: iResultHttp) {
		this.addInlandTransportationCostPopupClass.costTitleConceptCustomSelectorOptions.items =
			res.data;
	}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}

	addLineToContainerTypesLinesArray() {
		this.addInlandTransportationCostPopupClass.containerTypesLines.push({
			containerType: null,
			quantity: 0,
			price: null,
			margin: null,
			cost: null
		});
		this.checkIfCompleted();
	}

	addLineToScalingLines() {
		this.addInlandTransportationCostPopupClass.scalingLines.push({
			upToKm: null,
			cost: null,
			margin: null,
			price: null
		});
	}

	deleteThisLine(containerTypesLine, index) {
		if (
			this.addInlandTransportationCostPopupClass.containerTypesLines.length ===
				1 &&
			index === 0
		)
			return;
		this.addInlandTransportationCostPopupClass.containerTypesLines.splice(
			index,
			1
		);
		this.checkIfCompleted();
	}

	removeThisLineScalingLines(index) {
		if (
			this.addInlandTransportationCostPopupClass.scalingLines.length === 1 &&
			index === 0
		)
			return;
		this.addInlandTransportationCostPopupClass.scalingLines.splice(index, 1);
	}

	getCalculatedObject(event: iCalculateCostMarginPriceObject, index) {
		this.addInlandTransportationCostPopupClass.containerTypesLines[index].cost =
			event.cost;
		this.addInlandTransportationCostPopupClass.containerTypesLines[
			index
		].margin = event.margin;
		this.addInlandTransportationCostPopupClass.containerTypesLines[
			index
		].price = event.price;
		this.checkIfCompleted();
	}

	getCalculatedObjectPrice(event) {
		this.addInlandTransportationCostPopupClass.calculatedObjectPrice = event;
		this.checkIfCompleted();
	}

	getCalculatedPricePerKmAboveContainer(event) {
		this.addInlandTransportationCostPopupClass.pricePerKmAboveTheScaling =
			event;
		this.checkIfCompleted();
	}

	goToMaps() {
		let origin = this.addInlandTransportationCostPopupClass.origin
			?.split(' ')
			.join('+');
		let destination = this.addInlandTransportationCostPopupClass.destination
			?.split(' ')
			.join('+');
		if (!origin || !destination) {
			StaticUtilitiesService.showWarning(
				'Please fill in both origin and destination fields'
			);
			return;
		}
		window.open(
			`https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`,
			'_blank'
		);
	}

	calculatedScaling(event, scalingLine) {
		scalingLine.cost = event.cost;
		scalingLine.margin = event.margin;
		scalingLine.price = event.price;
		this.checkIfCompleted();
	}

	generateReturnDataAndClosePopup() {
		let returnValue: any = {};

		// If it is price per container and type
		if (this.addInlandTransportationCostPopupClass.calculationRule === 1) {
			returnValue.containers =
				this.addInlandTransportationCostPopupClass.containerTypesLines;
			returnValue.calculationRule =
				this.addInlandTransportationCostPopupClass.calculationRule;
			returnValue.costTitleConcept =
				this.addInlandTransportationCostPopupClass.costTitleConcept;
		}

		if (this.addInlandTransportationCostPopupClass.calculationRule === 2) {
			returnValue = {
				calculationRule:
					this.addInlandTransportationCostPopupClass.calculationRule,
				price:
					this.addInlandTransportationCostPopupClass.calculatedObjectPrice
						.price,
				cost: this.addInlandTransportationCostPopupClass.calculatedObjectPrice
					.cost,
				margin:
					this.addInlandTransportationCostPopupClass.calculatedObjectPrice
						.margin,
				costTitleConcept:
					this.addInlandTransportationCostPopupClass.costTitleConcept,
				concept: this.addInlandTransportationCostPopupClass.costTitleConcept
			};
		}

		if (this.addInlandTransportationCostPopupClass.calculationRule === 3) {
			const priceToReturn = this.selectCorrectPrice();
			returnValue = priceToReturn;
		}

		this.closePopup(returnValue);
	}

	selectCorrectPrice() {
		const pricesArray = this.addInlandTransportationCostPopupClass.scalingLines;
		const targetKm = this.addInlandTransportationCostPopupClass.targetKm;
		const priceAboveScaling =
			this.addInlandTransportationCostPopupClass.pricePerKmAboveTheScaling;

		if (targetKm && priceAboveScaling) {
			const priceLine = pricesArray.find(
				(price) => price.upToKm && Number(price.upToKm) > Number(targetKm)
			);

			if (priceLine) {
				return {
					...priceLine,
					calculationRule:
						this.addInlandTransportationCostPopupClass.calculationRule,
					costTitleConcept:
						this.addInlandTransportationCostPopupClass.costTitleConcept
				};
			} else {
				const maxUpToKm = Math.max(
					...pricesArray.map((price) => price.upToKm || 0)
				);
				const difference = Number(targetKm) - Number(maxUpToKm);
				return {
					cost: Number(priceAboveScaling.cost!) * Number(difference),
					margin: Number(priceAboveScaling.margin!),
					price:
						Number(priceAboveScaling.margin! / 0.01) *
						(Number(priceAboveScaling.cost!) * Number(difference)),
					calculationRule:
						this.addInlandTransportationCostPopupClass.calculationRule,
					costTitleConcept:
						this.addInlandTransportationCostPopupClass.costTitleConcept
				};
			}
		} else {
			StaticUtilitiesService.showWarning(
				'Please fill all required data before submitting'
			);
			return null;
		}
	}

	checkIfCompleted() {
		const step: number =
			this.addInlandTransportationCostPopupClass.calculationRule!;

		if (step === 1) {
			const objectToCheck: any = {
				concept: this.addInlandTransportationCostPopupClass.costTitleConcept,
				calculationRule:
					this.addInlandTransportationCostPopupClass.calculationRule
			};
			this.addInlandTransportationCostPopupClass.isChecked =
				StaticUtilitiesService.isCompleteObject(objectToCheck) &&
				this.calculateCostMarginPriceSE.customIsFullArrayPermitingZero(
					this.addInlandTransportationCostPopupClass.containerTypesLines
				);
			return;
		}
		if (step === 2) {
			const objectToCheck: any = {
				concept: this.addInlandTransportationCostPopupClass.costTitleConcept,
				cost: this.addInlandTransportationCostPopupClass.calculatedObjectPrice
					.cost,
				price:
					this.addInlandTransportationCostPopupClass.calculatedObjectPrice
						.price,
				calculationRule:
					this.addInlandTransportationCostPopupClass.calculationRule
			};
			this.addInlandTransportationCostPopupClass.isChecked =
				StaticUtilitiesService.isCompleteObject(objectToCheck);
			return;
		}

		if (step === 3) {
			const objectToCheck: any = {
				concept: this.addInlandTransportationCostPopupClass.costTitleConcept,
				calculationRule:
					this.addInlandTransportationCostPopupClass.calculationRule,
				destination: this.addInlandTransportationCostPopupClass.destination,
				origin: this.addInlandTransportationCostPopupClass.origin,
				targetKm: this.addInlandTransportationCostPopupClass.targetKm,
				cost: this.addInlandTransportationCostPopupClass
					.pricePerKmAboveTheScaling.cost,
				price:
					this.addInlandTransportationCostPopupClass.pricePerKmAboveTheScaling
						.price
			};
			this.addInlandTransportationCostPopupClass.isChecked =
				this.calculateCostMarginPriceSE.customIsFullArrayPermitingZero(
					this.addInlandTransportationCostPopupClass.scalingLines
				) && StaticUtilitiesService.isCompleteObject(objectToCheck);
			return;
		}
	}

	/**
	 * API CALLS
	 */

	getWareContainerSelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.wareContainerSE.getWareContainerSelector(
			behaviorSubject,
			this.modalData.commercialOfferId
		);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: this.saveContainerType(res)
				});
			});
	}

	getCostTitleSelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.costSE.getSelectorTitles(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successgetCostTitleSelectorHandler(res)
				});
			});
	}
}
