import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'userPill',
	templateUrl: './user-pill.component.html',
	styleUrls: ['./user-pill.component.scss']
})
export class UserPillComponent implements OnInit {
	@Input() performance: number = 0;
	name: string = localStorage.getItem('name') ?? '-';

	constructor() {}

	ngOnInit(): void {}
}
