<div class="confirmPopupGeneralContainer">
	<div class="body">
		<p class="title">Add container</p>
		<div class="line">
			<formatted-selector
				[label]="'Select a container'"
				[optionsSelector]="containerTypeSelectorOptions"
				[(ngModel)]="idContainerSelected"
			></formatted-selector>
		</div>
	</div>
	<div class="footer">
		<btn
			[classList]="'generalButton action red'"
			(click)="forceClosePopup()"
		>
			<ng-container>
				<span>Discard</span>
			</ng-container>
		</btn>
		<btn
			[classList]="'generalButton action green'"
			(click)="closePopup(idContainerSelected)"
		>
			<ng-container>
				<span>Add container</span>
			</ng-container>
		</btn>
	</div>
</div>
