import {
	iBaseObject,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { iAddress } from '../iAddress';
import { iPaymentMethod } from '../iPaymentMethod';

export interface iThirdPartySingle extends iBaseObject {
	id: number | null;
	name: string;
	email: string;
	phoneOne: string;
	phoneTwo: string;
	contactPerson: string;
	entityType: string;
	cif: string;
	addresses: Array<iAddress>;
	paymentMethod: iPaymentMethod;
	types: Array<{ thirdType: { type: string; id: number } }>;
	clientType: string;
	availableCredit: number | null;
	period: number | null;
}

export class iThirdPartySingle extends iBaseObject {
	static convertFromCollection(ob: any): Array<iThirdPartySingle> {
		let iThirdPartySingleCollection: Array<iThirdPartySingle> = [];
		ob.forEach((element: any) => {
			iThirdPartySingleCollection.push(this.convertFromObject(element));
		});
		return iThirdPartySingleCollection;
	}

	static convertFromObject(ob: any): iThirdPartySingle {
		let iThirdPartySingleObj = new iThirdPartySingle();
		iThirdPartySingleObj.id = ob.id;
		iThirdPartySingleObj.name = ob.name;
		iThirdPartySingleObj.email = ob.email;
		iThirdPartySingleObj.phoneOne = ob.phoneOne;
		iThirdPartySingleObj.phoneTwo = ob.phoneTwo;
		iThirdPartySingleObj.contactPerson = ob.contactPerson;
		iThirdPartySingleObj.entityType = ob.entityType;
		iThirdPartySingleObj.cif = ob.cif;
		iThirdPartySingleObj.addresses = iAddress.convertFromCollection(
			iStaticUtilities.normalizeNamesCollection(ob.addresses)
		);
		iThirdPartySingleObj.paymentMethod = ob.paymentMethod
			? iPaymentMethod.convertFromObject(
					iStaticUtilities.normalizeNames(ob.paymentMethod)
				)
			: iPaymentMethod.createEmptyObject();
		iThirdPartySingleObj.types = iStaticUtilities.normalizeNamesCollection(
			ob.thirdThirdTypes
		);
		iThirdPartySingleObj.clientType = ob.clientType;
		iThirdPartySingleObj.availableCredit = ob.availableCredit;
		iThirdPartySingleObj.period = ob.period;
		return iThirdPartySingleObj;
	}

	static createEmptyObject(): iThirdPartySingle {
		let iThirdPartySingleObj = new iThirdPartySingle();
		iThirdPartySingleObj.id = null;
		iThirdPartySingleObj.name = '';
		iThirdPartySingleObj.email = '';
		iThirdPartySingleObj.contactPerson = '';
		iThirdPartySingleObj.clientType = '';
		iThirdPartySingleObj.availableCredit = null;
		iThirdPartySingleObj.period = null;
		return iThirdPartySingleObj;
	}
}
