import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2
} from '@angular/core';
import { IScrollNavigator } from '../../Interfaces/Utils/iScrollNavigator';

@Component({
	selector: 'scrollNavigator',
	templateUrl: './scroll-navigator.component.html',
	styleUrls: ['./scroll-navigator.component.scss']
})
export class ScrollNavigatorComponent implements OnInit {
	@Input() scrollNavigatorElements: Array<IScrollNavigator> = [];
	@Input() selectedNavigatorElement: IScrollNavigator = {
		text: '',
		anchor: ''
	};

	clickedElement: any = null;
	timeout: any = null;
	isRunning: boolean = false;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {}

	goToAnchorSelected(event) {
		this.clickedElement = event.target!;
		this.clickedElement.classList.add('transition');
		this.removeTransition();
		(
			document.getElementById(
				this.selectedNavigatorElement.anchor
			) as HTMLElement
		).scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest'
		});
	}

	removeTransition() {
		if (!this.isRunning) {
			this.isRunning = true;
			setTimeout(() => {
				this.clickedElement.classList.remove('transition');
				this.isRunning = false;
			}, 1000);
		} else {
			let spans = this.el.nativeElement.querySelectorAll('.navSpan');
			spans.forEach((span) => {
				if (span.innerHTML != this.clickedElement.innerHTML) {
					span.classList.remove('transition');
				}
			});
			this.isRunning = true;
			setTimeout(() => {
				this.clickedElement.classList.remove('transition');
				this.isRunning = false;
			}, 1000);
		}
	}
}
