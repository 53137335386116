import {
	IOptionCustomTextArea,
	StaticUtilitiesService,
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import {
	iMainMessageList,
	iMainMessageListFilter
} from '../Interfaces/Messages/iMainMessageList';
import { iThreadMessageFilter } from '../Interfaces/Messages/iThreadMessage';
import { Subject } from 'rxjs';

export class Messages {
	isOpenedMessages: boolean = false;
	isDataLoaded: boolean = false;
	messageList: Array<iMainMessageList> =
		StaticDataHandlerService.getMessageListArray();
	threadMessagesSelected: Array<any> = [];
	viewAllThread: boolean = false;
	selectedMessage: iMainMessageList = iMainMessageList.createEmptyObject();
	iMainMessageListFilter: iMainMessageListFilter = {
		returns_per_page: 9999,
		page_number: 1,
		total: 0
	};
	iThreadMessageListFilter: iThreadMessageFilter = {
		returns_per_page: 9999,
		page_number: 1
	};
	responseOptions: IOptionCustomTextArea = {
		placeholder: 'Write here to reply to the message',
		rows: 20,
		cols: 20
	};

	subjectOptions: iOptionsCustomInput = {
		type: 'text',
		placeholder: 'Subject',
		readOnly: true
	};
	toOptions: iOptionsSelector = {
		placeholder: 'To',
		items: [],
		append: true,
		clearable: false,
		bindValue: 'id',
		search: true,
		bindLabel: 'name'
	};
	messageOptions: iOptionsSelector = {
		placeholder: 'Message',
		items: [],
		append: true,
		clearable: false,
		bindValue: 'id',
		bindLabel: 'name',
		search: true
	};
	caseOptions: iOptionsSelector = {
		placeholder: 'Case',
		items: [],
		append: true,
		clearable: false,
		bindValue: 'id',
		search: true,
		bindLabel: 'name'
	};

	listenForUpdateDataSubject: Subject<any> = new Subject<any>();
	constructor() {}

	clearSelectedMessage() {
		this.selectedMessage = iMainMessageList.createEmptyObject();
		this.threadMessagesSelected = [];
		this.viewAllThread = false;
	}
	setLastThreadMessageOpened() {
		// if (this.selectedMessage.thread.length > 0) {
		//   this.threadMessagesSelected.push(
		//     this.selectedMessage.thread[this.selectedMessage.thread.length - 1].id
		//   );
		// }
	}
	createNewThread() {
		return {
			id: StaticUtilitiesService.generateRandomCode(4),
			company: 'Company test',
			image: 'https://picsum.photos/50/50?random=69',
			user: localStorage.getItem('name'),
			message: '',
			timestamp: '',
			attachFiles: []
		};
	}
	createNewMessage() {
		return {
			id: StaticUtilitiesService.generateRandomCode(4),
			image: 'https://picsum.photos/50/50?random=69',
			company: null,
			title: '',
			shortDescription: '',
			important: false,
			case: null,
			thread: [this.createNewThread()]
		};
	}
	generateAttachedFiles(element: any) {
		return {
			url: element.base64,
			title: element.file.name
		};
	}
}
