import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	StaticUtilitiesService,
	iOptionsCustomInput,
	iOptionsSelector,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { DocumentsService } from 'src/app/Services/Api/documents.service';
import { AddShipmentDocumentClass } from 'src/app/Shared/Class/shipment/AddShipmentDocumentClass';
import { iFileWithBase64 } from 'src/app/Shared/Interfaces/Utils/iFileWithBase64';

@Component({
	selector: 'app-add-shipment-document',
	templateUrl: './add-shipment-document.component.html',
	styleUrls: ['./add-shipment-document.component.scss']
})
export class AddShipmentDocumentComponent implements OnInit {
	extraClass: AddShipmentDocumentClass = new AddShipmentDocumentClass();

	constructor(
		private documentsSE: DocumentsService,
		public dialogRef: MatDialogRef<AddShipmentDocumentComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any
	) {}

	ngOnInit(): void {
		this.getTypes();
	}

	/**
	 * HANDLERS
	 */

	successGetTypesHandler(res: iResultHttp) {
		const { data } = res;
		this.extraClass.selectorOptions.items = data;
	}

	successSubmitPopUpHandler() {
		this.closePopup();
	}

	handleChangeFile(files: iFileWithBase64[]) {
		this.extraClass.files = files;
	}

	/**
	 * FUNCIONALITIES
	 */

	closePopup() {
		let p = { returnValue: '' };
		this.dialogRef.close(p);
	}

	forceClosePopup() {
		this.dialogRef.close();
	}

	/**
	 * Api calls
	 */

	getTypes() {
		const subject = StaticUtilitiesService.createSubject();

		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();

		this.documentsSE.getSelector(behaviorSubject);

		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, [
					{
						method: () => this.successGetTypesHandler(res),
						error: false
					},
					{
						error: true
					}
				]);
			});
	}

	submitPopUp() {
		const studentLead = iStaticUtilities.simpleDecrypt(
			localStorage.getItem('studentLead') || ''
		);
		const data = {
			studentLead: studentLead,
			label: this.extraClass.modelType,
			name: this.extraClass.modelName,
			url: this.extraClass.files[0].base64
		};

		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.documentsSE.postFiles(behaviorSubject, data);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successSubmitPopUpHandler()
				});
			});
	}
}
