<div class="subHeader">
	<p class="subHeader-title">Resultados de la búsqueda</p>
	<div class="subHeader-optionsLine">
		<div class="smallOptions">
			<customInput
				[(ngModel)]="navHunterOptions.portOfOrigin"
				[options]="navHunterOptions.portOfOriginOptions"
			></customInput>
			<customInput
				[(ngModel)]="navHunterOptions.portOfDestination"
				[options]="navHunterOptions.portOfDestinationOptions"
			></customInput>
		</div>

		<div class="hoverable">
			<customInput
				[(ngModel)]="navHunterOptions.shippmentDetails"
				[options]="navHunterOptions.shippmentDetailsOptions"
			></customInput>

			<div class="containersBox">
				<div
					class="containerLine"
					*ngFor="
						let containerLine of navHunterOptions.body.containers;
						index as index
					"
				>
					<div class="customFormContainer">
						<customInput
							[(ngModel)]="containerLine.type"
							[options]="navHunterOptions.containerNameOptions"
						></customInput>
					</div>
					<customInput
						[(ngModel)]="containerLine.quantity"
						[options]="navHunterOptions.unitsOptions"
					></customInput>
				</div>
			</div>
		</div>
		<div class="smallerOptions">
			<customInput
				[(ngModel)]="navHunterOptions.type"
				[options]="navHunterOptions.typeOptions"
			></customInput>
			<customInput
				[(ngModel)]="navHunterOptions.desiredDepartureDate"
				[options]="navHunterOptions.desiredDepartureDateOptions"
			></customInput>
		</div>
	</div>
</div>
