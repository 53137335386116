import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';

export class RegisterClass {
	// isDataCompleted
	isDataCompleted: boolean = false;

	// bothEmailsCheck
	bothEmailsCheck: boolean = false;

	// regexEmail
	regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

	// Custom input options
	emailOptions: iOptionsCustomInput = {
		placeholder: 'Email',
		type: 'email',
		label: 'Email',
		required: true
	};
	repeatEmailOptions: iOptionsCustomInput = {
		placeholder: 'Repeat email',
		type: 'email',
		label: 'Repeat email',
		required: true
	};
	passwordOptions: iOptionsCustomInput = {
		placeholder: 'Password',
		type: 'password',
		label: 'Password',
		required: true,
		icon: 'visibility_off'
	};
	nameOptions: iOptionsCustomInput = {
		placeholder: 'Name',
		type: 'text',
		label: 'Name',
		required: true
	};
	surnameOptions: iOptionsCustomInput = {
		placeholder: 'Surname',
		type: 'text',
		label: 'Surname',
		required: true
	};

	// selectorOptions
	teacherOptions: iOptionsSelector = {
		items: [],
		bindLabel: 'name',
		bindValue: 'id',
		classList: 'register',
		placeholder: 'Select your teacher',
		search: true,
		clearable: false
	};
	countryOptions: iOptionsSelector = {
		items: [],
		bindLabel: 'name',
		bindValue: 'name',
		classList: 'register',
		placeholder: 'Country',
		search: true,
		clearable: false
	};
	cityOptions: iOptionsSelector = {
		items: [],
		bindLabel: 'name',
		bindValue: 'id',
		classList: 'register',
		placeholder: 'City',
		search: true,
		clearable: false
	};

	ngModels: {
		email: null | string;
		repeatEmail: null | string;
		password: null | string;
		name: null | string;
		surname: null | string;
		teacher: null | number;
		country: null | string;
		city: null | string;
	} = {
		email: null,
		repeatEmail: null,
		password: null,
		name: null,
		surname: null,
		teacher: null,
		country: null,
		city: null
	};
}
