import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { BoatLoaderComponent } from '../boat-loader/boat-loader.component';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';

export interface Istep {
	name: string;
	img: string;
	group: number;
	canEdit: boolean;
	canAvance: boolean;
}

@Component({
	selector: 'step-controller',
	templateUrl: './step-controller.component.html',
	styleUrls: ['./step-controller.component.scss']
})
export class StepControllerComponent implements OnInit {
	@Output() position: EventEmitter<any> = new EventEmitter<any>();
	@Input() stepsArray: Istep[] = [];

	selectedPosition: number = 0;

	completedsGroups: number[] = [];

	groupesCompleted = {};

	constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['stepsArray']) {
			if (changes['stepsArray'].currentValue !== null) {
				this.checkCompletedGroups();
			}
		}
	}

	/**
	 * FUNCTIONALITIES
	 */

	checkCompletedGroups() {
		const groups = this.stepsArray.map((step) => step.group);
		const uniqueGroups = groups.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});

		uniqueGroups.forEach((group) => {
			const stepsInGroup = this.stepsArray.filter(
				(step) => step.group === group
			);
			const canEdit = stepsInGroup.every((step) => {
				return step.canEdit;
			});
			this.groupesCompleted[group] = canEdit;
		});
	}

	onClickSendIndex(index: number, group: number) {
		// This checks if the next step is available to be accessed
		// if (!this.stepsArray[index].canAvance) {
		// 	StaticUtilitiesService.showWarning(
		// 		'Please complete this form before continue forward'
		// 	);
		// 	return;
		// }
		this.selectedPosition = index;
		this.position.emit(index);
		this.checkCompletedGroups();
	}
}
