import { Component, OnInit } from '@angular/core';
import {
	PopupService,
	StaticUtilitiesService,
	iDate,
	iResultHttp,
	iStaticUtilities,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { IConfirmPopup } from '../../Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from 'src/app/Popups/confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { ResponseMessageBridgeService } from 'src/app/Services/Utils/responseMessageBridge.service';
import { Messages } from '../../Class/messages';
import { StudentLeadsService } from 'src/app/Services/Api/student-leads.service';
import { ThirdPartyService } from 'src/app/Services/Api/third-party.service';
import { iNewMessage } from '../../Interfaces/Messages/iNewMessage';
import { MessageService } from 'src/app/Services/Api/message.service';
import { error } from 'console';
import { FeedbackService } from 'src/app/Services/Utils/Feedback.service';
import { GeneralLoaderService } from 'src/app/Services/Utils/generalLoader.service';

@Component({
	selector: 'newMessage',
	templateUrl: './new-message.component.html',
	styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit {
	pos1 = 0;
	pos2 = 0;
	pos3 = 0;
	pos4 = 0;
	elementHeader: HTMLElement | null = null;
	generalElement: HTMLElement | null = null;
	contentEditable: HTMLElement | null = null;
	minimiceWindow: boolean = false;
	messageClass = new Messages();
	model: string = '';
	formatos: Array<string> = ['application/pdf'];
	file: any = null;
	newMessage: iNewMessage = this.getNewMessage();
	studentLead: number | null = null;
	constructor(
		private responseMessageBridgeSE: ResponseMessageBridgeService,
		private popupSE: PopupService,
		private staticUtilitiesSE: StaticUtilitiesService,
		private studenLeadSE: StudentLeadsService,
		private thirdPartySE: ThirdPartyService,
		private messageSE: MessageService,
		private feedbackSE: FeedbackService,
		private generalLoaderSE: GeneralLoaderService
	) {}

	ngOnInit(): void {
		this.minimiceWindow = iStaticUtilities.normalizeBoolean(
			sessionStorage.getItem('minimiced')
		);
		this.elementHeader = document.querySelector(
			'.responseMessageGeneralContainer .header'
		) as HTMLElement;
		this.generalElement = document.querySelector(
			'.responseMessageGeneralContainer'
		) as HTMLElement;
		this.contentEditable = document.querySelector('.editable') as HTMLElement;
		this.contentEditable.focus();
		this.setNewMessageData();
		this.getStudentLeadSelector();
		this.getThirdPartySelector();
		this.listenForUpdateData();
		setTimeout(() => {
			this.dragElement();
		}, 500);
	}

	/**
	 * HANDLERS
	 */
	successTemplateSelectorForStudentHandler(res: iResultHttp) {
		const { data } = res;
		this.messageClass.messageOptions.items = [...data];
	}

	successGetAddresForMessageHandler(res: iResultHttp) {
		const { data } = res;
		this.newMessage.body = data.body;
		this.newMessage.subject = data.subject;
		this.saveNewData();
	}

	errorCreateNewMessageResponseHandler(res: iResultHttp) {
		if (res.status == 418) {
			const parsedMessage = JSON.parse(String(res.message));
			this.feedbackSE.setFeedback(parsedMessage);
			this.generalLoaderSE.addToLoaderAmount();
			setTimeout(() => {
				this.responseMessageBridgeSE.toggleOpenNewMessage(false);
				this.feedbackSE.setFeedback(parsedMessage);
				this.generalLoaderSE.removeFromLoaderAmount();
			}, 500);
		}
	}

	/**
	 * FUNCTIONALITIES
	 */

	dragElement() {
		this.elementHeader?.addEventListener('mousedown', this.dragMouseDown);
	}

	dragMouseDown = (e: MouseEvent) => {
		e.preventDefault();
		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		document.addEventListener('mouseup', this.closeDragElement);
		document.addEventListener('mousemove', this.elementDrag);
	};

	elementDrag = (e: MouseEvent) => {
		e.preventDefault();

		if (this.generalElement) {
			this.pos1 = this.pos3 - e.clientX;
			this.pos2 = this.pos4 - e.clientY;
			this.pos3 = e.clientX;
			this.pos4 = e.clientY;
			this.generalElement.style.top =
				this.generalElement.offsetTop - this.pos2 + 'px';
			this.generalElement.style.left =
				this.generalElement.offsetLeft - this.pos1 + 'px';
		}
	};

	closeDragElement = () => {
		document.removeEventListener('mouseup', this.closeDragElement);
		document.removeEventListener('mousemove', this.elementDrag);
	};
	toggleMinimiceWindow() {
		this.minimiceWindow = !this.minimiceWindow;
		sessionStorage.setItem('minimiced', String(this.minimiceWindow));
	}
	closeNewMessage() {
		this.responseMessageBridgeSE.toggleOpenNewMessage(false);
		sessionStorage.clear();
	}
	confirmCloseResponseMessage() {
		const confirmCloseResponseMessage: IConfirmPopup = {
			buttonConfirmText: 'Discard',
			buttonCancelText: 'Cancel',
			title: 'Are you sure you want to discard the email?',
			description:
				'Are you sure you want to discard the email? If you discard it, you will have to rewrite it.',
			icon: '/assets/Icons/GeneraalUseIcons/warning.svg'
		};
		const subject = StaticUtilitiesService.createSubject();
		this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
		this.popupSE
			.returnData()
			.pipe(takeUntil(subject))
			.subscribe((res: any) => {
				if (res == null) {
					return;
				}
				this.closeNewMessage();
				subject.next('');
				subject.complete();
			});
	}

	openFile(file: any) {
		window.open(file.url, '_blank');
	}
	setFocus() {
		this.contentEditable!.focus();
	}
	createThirdParty() {
		this.staticUtilitiesSE.goTo(
			'/third-parties/new-third-party',
			slideNavigationTypes.slideToTop
		);
		if (
			!iStaticUtilities.normalizeBoolean(sessionStorage.getItem('minimiced'))
		) {
			this.toggleMinimiceWindow();
		}
	}
	getNewMessage() {
		let message = JSON.parse(sessionStorage.getItem('newMessage')!);
		if (message.leadId) this.templateSelectorForStudent(message.leadId);
		return message;
	}
	/**
	 * GETTERS & SETTERS
	 */
	saveNewData() {
		sessionStorage.setItem('newMessage', JSON.stringify(this.newMessage));
	}
	setNewMessageData() {
		this.model = this.newMessage.body;
	}
	getModificableElement(event: any) {
		this.newMessage.body = event.target.innerText;
		this.saveNewData();
	}
	getMessagesSelector(event) {
		let leadId = this.messageClass.caseOptions.items.find(
			(item) => item.id === event
		)?.lead;
		this.newMessage.leadId = leadId;
		this.newMessage.studentLead = event;
		this.saveNewData();
		this.templateSelectorForStudent(leadId);
	}
	getFile(event: any) {
		let BreakException = {};
		try {
			event.forEach((element: any) => {
				if (!this.formatos.includes(element.file.type)) {
					StaticUtilitiesService.showError('Document format not allowed');
					this.file = null;
					throw BreakException;
				}
				if (element.file.size > 10485760) {
					StaticUtilitiesService.showError('File too heavy');
					this.file = null;
					throw BreakException;
				}
				this.newMessage.documents?.push(
					this.messageClass.generateAttachedFiles(element)
				);
				this.saveNewData();
			});
		} catch (e) {
			if (e !== BreakException) throw e;
		}
	}
	removeAttachedFile(file: any) {
		this.newMessage.documents?.splice(
			this.newMessage.documents?.indexOf(file),
			1
		);
		this.saveNewData();
	}
	listenForUpdateData() {
		this.responseMessageBridgeSE
			.getUpdateThirdData()
			.pipe(takeUntil(StaticUtilitiesService.createSubject()))
			.subscribe((res: any) => {
				if (res.update) {
					this.getThirdPartySelector();
				}
			});
	}
	/**
	 * HANDLERS
	 */

	successGetStudentLeadSelectorResponseHandler(res: iResultHttp) {
		this.messageClass.caseOptions.items = [...res.data];
	}
	successGetThirdPartySelectorResponseHandler(res: iResultHttp) {
		this.messageClass.toOptions.items = [...res.data];
	}
	successCreateNewMessageResponseHandler(res: iResultHttp) {
		StaticUtilitiesService.showFeedback('Email sent successfully');
		this.responseMessageBridgeSE.updateMessagesData();
		this.closeNewMessage();
	}
	/**
	 * CALLS TO API
	 */
	getStudentLeadSelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.studenLeadSE.getAllSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetStudentLeadSelectorResponseHandler(res),
					error: false
				});
			});
	}
	getThirdPartySelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.thirdPartySE.getAllSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetThirdPartySelectorResponseHandler(res),
					error: false
				});
			});
	}
	createNewMessage() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.messageSE.create(behaviorSubject, this.newMessage);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, [
					{
						method: () => this.successCreateNewMessageResponseHandler(res)
					},
					{
						method: () => this.errorCreateNewMessageResponseHandler(res),
						error: true
					}
				]);
			});
	}
	templateSelectorForStudent(caseId) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.messageSE.templateSelectorForStudent(behaviorSubject, caseId);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successTemplateSelectorForStudentHandler(res)
				});
			});
	}
	getAddresForMessage(addressId) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.messageSE.getAddresForMessage(behaviorSubject, addressId, {
			studentLead: this.newMessage.studentLead ?? 0
		});
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetAddresForMessageHandler(res)
				});
			});
	}
}
