import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
	providedIn: 'root'
})
export class CostConceptService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	private processCostConcepts(
		costConcepts: any,
		behaviorSubject: BehaviorSubject<any>
	) {
		this.res = new iResultHttp({
			status: 200,
			ok: true,
			message: '',
			data: costConcepts
		});
		this.sendNextObservable(behaviorSubject, this.res);
	}

	getSelector(behaviorSubject: BehaviorSubject<any>) {
		const costConceptsFromStorage =
			LocalStorageHandlerService.getEncodedItem('cost-concepts');

		if (costConceptsFromStorage) {
			this.processCostConcepts(costConceptsFromStorage, behaviorSubject);
			return;
		}

		this.http
			.get(this.urlApi + '/api/other/cost/concept/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					LocalStorageHandlerService.setEncodedItem('cost-concepts', data);
					this.processCostConcepts(data, behaviorSubject);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getSelector,
						args: [behaviorSubject]
					});
				}
			});
	}
}
