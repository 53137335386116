<div class="fullHeader">
	<div class="logo-info">
		<div
			class="logo"
			(click)="goTo('/nav-hunter/home')"
		>
			<img
				class="iconoLogo"
				src="/assets/Icons/NavHunterHeader/navHunterIcon.png"
				alt=""
			/>
			<img
				src="/assets/Icons/NavHunterHeader/NavHunter.png"
				alt=""
			/>
		</div>
		<div class="info">
			<img
				src="/assets/Icons/NavHunterHeader/alert-octagon.svg"
				alt=""
			/>
			<p>Simulated/game environment for Valencia Cargo Expert students.</p>
		</div>
	</div>

	<div class="links">
		<div class="home">
			<p (click)="goTo('/nav-hunter/locate')">Locate your package</p>
		</div>
		<div class="home">
			<p (click)="goTo('/nav-hunter/home')">Home</p>
		</div>
	</div>
</div>
