<div
	class="line optionLine"
	(click)="showExtraLine()"
>
	<div class="bothLines">
		<div class="fromToContainer">
			<div class="dateContainer">
				<span class="icon">
					<img
						src="/assets/Images/NavHunter/arrow-right-circle.svg"
						alt="arrow"
					/>
				</span>
				<div class="dateAndplace">
					<p>{{ content.departureDate | date: 'MMM d' }}</p>
					<p>{{ content.originPortName }}, {{ content.originPortCountry }}</p>
				</div>

				<div class="dotsAndLine">
					<span class="dot"></span>
					<span
						class="dottedLine"
						*ngIf="!content.transferPortName || !content.transferPortCode"
					></span>
					<span
						class="transferPortCode"
						*ngIf="content.transferPortName && content.transferPortCode"
					>
						{{ content.transferPortCode }}
					</span>
					<span class="dot"></span>
				</div>

				<span class="icon">
					<img
						src="/assets/Images/NavHunter/flag.svg"
						alt="arrow"
					/>
				</span>

				<div class="dateAndplace">
					<p>{{ content.arrivalDate | date: 'MMM d' }}</p>
					<p>
						{{ content.destinationPortName }},
						{{ content.destinationPortCountry }}
					</p>
				</div>
			</div>
		</div>
		<div class="timeContainer">
			<span class="icon">
				<img
					src="/assets/Images/NavHunter/clock.svg"
					alt="arrow"
				/>
			</span>

			<div class="transitAndTransshipment">
				<p>
					Transit time:
					<b>{{ content.transitTime }} days</b>
				</p>
				<p *ngIf="!content.transferPortName || !content.transferPortCode">
					Direct
				</p>
				<p *ngIf="content.transferPortName && content.transferPortCode">
					1 Transshipment
				</p>
			</div>
		</div>
		<div class="priceContainer">
			<span class="icon">
				<img
					src="/assets/Images/NavHunter/shopping-cart.svg"
					alt="arrow"
				/>
			</span>
			<p>{{ content.price | currency: 'EUR' }}</p>
		</div>
	</div>
	<div
		class="bothLines bottom"
		[ngClass]="{ extraLineOppened: isExtraLineShown }"
	>
		<div class="bottomContainer first">
			<p class="type">{{ content.cargoType }}</p>
			<nav-hunter-option-icon
				[characteristics]="chatacteristics"
			></nav-hunter-option-icon>
			<div class="naviera">
				<img
					src="{{ content.shippingLineImage }}"
					alt=""
					(error)="setPlaceholderImage($event)"
				/>
				<p>{{ content.shippingLineName }}</p>
			</div>
		</div>
		<div class="bottomContainer">
			<p>
				Valid until:
				<b>{{ content.validUntil | date: 'MMM d' }}</b>
			</p>
		</div>
		<div class="bottomContainer last">
			<btn
				class="buttonWidth"
				classList="generalButton secondaryButton"
				(click)="$event.stopPropagation(); sendSelect()"
			>
				Select
			</btn>
		</div>
	</div>
	<div id="wrapper">
		<div
			class="extraLine"
			[ngClass]="{ active: isExtraLineShown }"
		>
			<div class="portCard first">
				<i
					class="country_flags"
					[attr.country]="content.originPortCountry"
					size="ultra_small"
				></i>
				<p>{{ content.originPortCode }}, {{ content.originPortName }}</p>
			</div>
			<div
				class="portCard"
				*ngIf="content.transferPortName && content.transferPortCode"
			>
				<i
					class="country_flags"
					[attr.country]="content.transferPortCountry"
					size="ultra_small"
				></i>
				<p>{{ content.transferPortCode }}, {{ content.transferPortName }}</p>
			</div>
			<div class="portCard last">
				<i
					class="country_flags"
					[attr.country]="content.destinationPortCountry"
					size="ultra_small"
				></i>
				<p>
					{{ content.destinationPortCode }}, {{ content.destinationPortName }}
				</p>
			</div>

			<div class="dottedLine"></div>
		</div>
	</div>
</div>
