import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { iFeedback } from 'src/app/Shared/Interfaces/Utils/iFeedback';

@Injectable({
	providedIn: 'root'
})
export class FeedbackService {
	private feedbackBS: BehaviorSubject<iFeedback | null> =
		new BehaviorSubject<iFeedback | null>(null);

	constructor() {}

	getFeedback() {
		return this.feedbackBS;
	}

	setFeedback(feedback: iFeedback | null) {
		this.feedbackBS.next(feedback);
	}

	clearFeedback() {
		this.feedbackBS.next(null);
	}
}
