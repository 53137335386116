import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iThirdPartySingle } from 'src/app/Shared/Interfaces/Api/ThirdParty/iThirdPartySingle';
import {
	iThirdPartyList,
	iThirdPartyListFilter
} from 'src/app/Shared/Interfaces/Api/ThirdParty/iThirdPartyList';
import { iThirdPartyForm } from 'src/app/Shared/Interfaces/Api/ThirdParty/iThirdPartyForm';
import { iThirdPartySelector } from 'src/app/Shared/Interfaces/Api/ThirdParty/iThirdPartySelector';

@Injectable({
	providedIn: 'root'
})
export class ThirdPartyService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getAll(
		behaviorSubject: BehaviorSubject<any>,
		filters: iThirdPartyListFilter
	) {
		this.http
			.get(
				this.urlApi + '/api/third' + this.optionsGet(filters),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizeNamesCollection =
						iThirdPartyList.convertFromCollection(data.data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: {
							total: data.total,
							data: iStaticUtilities.normalizeNamesCollection(
								normalizeNamesCollection
							)
						}
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAll,
						args: [behaviorSubject]
					});
				}
			});
	}
	getAllSelector(behaviorSubject: BehaviorSubject<any>) {
		this.http
			.get(this.urlApi + '/api/third/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iThirdPartySelector.convertFromCollection(
							iStaticUtilities.normalizeNamesCollection(data)
						)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAllSelector,
						args: [behaviorSubject]
					});
				}
			});
	}
	getTypeSelector(
		behaviorSubject: BehaviorSubject<any>,
		id: thirdPartyTypes[]
	) {
		this.http
			.get(this.urlApi + '/api/third/selector?type=' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iThirdPartySelector.convertFromCollection(
							iStaticUtilities.normalizeNamesCollection(data)
						)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getTypeSelector,
						args: [behaviorSubject, id]
					});
				}
			});
	}
	getSingle(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/third/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedNames = iThirdPartySingle.convertFromObject(
						iStaticUtilities.normalizeNames(data)
					);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedNames
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getSingle,
						args: [behaviorSubject, id]
					});
				}
			});
	}
	getFormattedAddress(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/third/address/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedNames = data;
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedNames
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getFormattedAddress,
						args: [behaviorSubject, id]
					});
				}
			});
	}
	validateThirdParty(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(
				this.urlApi +
					'/api/third/client/validate' +
					this.optionsGet({ clientId: id }),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: ''
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getSingle,
						args: [behaviorSubject, id]
					});
				}
			});
	}
	create(behaviorSubject: BehaviorSubject<any>, thirdParty: iThirdPartyForm) {
		this.http
			.post(this.urlApi + '/api/third', thirdParty, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: data.message,
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.create,
						args: [behaviorSubject, thirdParty]
					});
				}
			});
	}
	update(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(this.urlApi + '/api/third/' + data.id, data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'Third party has been successfully updated.',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.update,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	getAddresses(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/third/addresses/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
				}
			});
	}

	deactivate(
		behaviorSubject: BehaviorSubject<any>,
		ids: Array<number | string>
	) {
		let idsObjs = this.convertArrayObject(ids, 'ids');
		this.http
			.put(this.urlApi + '/api/third/deactivate', idsObjs, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'Third party has been successfully removed.',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.deactivate,
						args: [behaviorSubject, ids]
					});
				}
			});
	}

	getCompleteAddress(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/address/preview/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
				}
			});
	}
}

export enum thirdPartyTypes {
	airline = 1,
	agentNetwork = 2,
	client = 3,
	consignee = 4,
	containerRoadCarrier = 5,
	correspondent = 6,
	courierAgent = 7,
	customsBroker = 8,
	freightForwarder = 9,
	generalSalesAgent = 10,
	goodsRoadCarrier = 11,
	handlingAgent = 12,
	haulier = 13,
	insuranceCompany = 14,
	internacionalRoadCarrier = 15,
	nvocc = 16,
	portCommunitySystem = 17,
	portTerminal = 18,
	prospectiveClient = 19,
	railwayOperator = 20,
	salesRepresentative = 21,
	shippingLine = 22,
	subagentAtDestination = 23,
	supplier = 24,
	warehouse = 25,
	weighingCenter = 26,
	shipConsignee = 27,
	shippingAgent = 28,
	shipper = 29
}
