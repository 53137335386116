<div
	class="responseMessageGeneralContainer"
	[ngClass]="{ minimiced: minimiceWindow }"
>
	<div class="header">
		<p>{{ message.subject }}</p>
		<div class="icons">
			<mat-icon (click)="toggleMinimiceWindow()">expand_more</mat-icon>
			<mat-icon (click)="confirmCloseResponseMessage()">close</mat-icon>
		</div>
	</div>

	<div class="to">
		<selector
			[options]="messageClass.messageOptions"
			[(ngModel)]="this.thread.studentTemplateId"
			(change)="saveNewData(); getBodyForMessage(this.thread.studentTemplateId)"
		></selector>
	</div>

	<div class="body">
		<div class="editable">
			<p>{{ thread.body }}</p>
		</div>
		<div class="attachGeneralContainer">
			<ng-container *ngFor="let file of thread.documents; let i = index">
				<div
					class="attachContainer"
					(click)="$event.stopPropagation(); openFile(file)"
				>
					<mat-icon
						(click)="$event.stopPropagation(); removeAttachedFile(file)"
					>
						close
					</mat-icon>
					<div class="icon">
						<div class="white">
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
						</div>
					</div>
					<span>Attached file {{ i + 1 }}</span>
				</div>
			</ng-container>
		</div>
	</div>
	<btn
		class="attachDocuments"
		fileInput
		[accept]="formatos"
		[(ngModel)]="file"
		[multiple]="true"
		(change)="getFile(file)"
	>
		<ng-container>
			<img
				src="/assets/Icons/Messages/attachDocument.svg"
				alt=""
			/>
			<span>Attach documents</span>
		</ng-container>
	</btn>
	<btn
		class="sendMessage"
		(click)="replyMessage()"
	>
		<ng-container>
			<img
				src="/assets/Icons/Messages/sendMessage.svg"
				alt=""
			/>
			<span>Send message</span>
		</ng-container>
	</btn>
</div>

<div
	class="minimicedWindow"
	[ngClass]="{ minimiced: minimiceWindow }"
	(click)="toggleMinimiceWindow()"
>
	<p>{{ message.subject }}</p>
	<div class="icons">
		<mat-icon (click)="$event.stopPropagation(); toggleMinimiceWindow()">
			expand_less
		</mat-icon>
		<mat-icon (click)="$event.stopPropagation(); confirmCloseResponseMessage()">
			close
		</mat-icon>
	</div>
</div>
