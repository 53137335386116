import { Component, Input, OnInit } from '@angular/core';
import {
	PopupService,
	StaticUtilitiesService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/confirm-popup/confirm-popup.component';
import { MessageService } from 'src/app/Services/Api/message.service';
import { ResponseMessageBridgeService } from 'src/app/Services/Utils/responseMessageBridge.service';
import { Messages } from '../../Class/messages';
import { iReplyMessage } from '../../Interfaces/Messages/iReplyMessage';
import { iMainMessageList } from '../../Interfaces/Messages/iMainMessageList';
import { IConfirmPopup } from '../../Interfaces/Utils/iConfirmPopup';
import { iFeedback } from '../../Interfaces/Utils/iFeedback';
import { FeedbackService } from 'src/app/Services/Utils/Feedback.service';

@Component({
	selector: 'feedback-shower',
	templateUrl: './feedback-shower.component.html',
	styleUrls: ['./feedback-shower.component.scss']
})
export class FeedbackShowerComponent implements OnInit {
	elementHeader: HTMLElement | null = null;
	generalElement: HTMLElement | null = null;
	contentEditable: HTMLElement | null = null;
	pos1 = 0;
	pos2 = 0;
	pos3 = 0;
	pos4 = 0;

	@Input() feedback: iFeedback | null = null;
	minimiceWindow: boolean = false;
	model: string = '';
	constructor(
		private responseMessageBridgeSE: ResponseMessageBridgeService,
		private popupSE: PopupService,
		private messageSE: MessageService,
		private feedbackSE: FeedbackService
	) {}

	ngOnInit(): void {
		this.minimiceWindow = iStaticUtilities.normalizeBoolean(
			sessionStorage.getItem('minimiced')
		);
		this.elementHeader = document.querySelector(
			'.responseMessageGeneralContainer .header'
		) as HTMLElement;
		this.generalElement = document.querySelector(
			'.responseMessageGeneralContainer'
		) as HTMLElement;
		setTimeout(() => {
			this.dragElement();
		}, 500);
	}

	/**
	 * HANDLERS
	 */

	/**
	 * FUNCTIONALITIES
	 */

	dragElement() {
		this.elementHeader?.addEventListener('mousedown', this.dragMouseDown);
	}

	dragMouseDown = (e: MouseEvent) => {
		e.preventDefault();
		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		document.addEventListener('mouseup', this.closeDragElement);
		document.addEventListener('mousemove', this.elementDrag);
	};

	elementDrag = (e: MouseEvent) => {
		e.preventDefault();

		if (this.generalElement) {
			this.pos1 = this.pos3 - e.clientX;
			this.pos2 = this.pos4 - e.clientY;
			this.pos3 = e.clientX;
			this.pos4 = e.clientY;
			this.generalElement.style.top =
				this.generalElement.offsetTop - this.pos2 + 'px';
			this.generalElement.style.left =
				this.generalElement.offsetLeft - this.pos1 + 'px';
		}
	};

	closeDragElement = () => {
		document.removeEventListener('mouseup', this.closeDragElement);
		document.removeEventListener('mousemove', this.elementDrag);
	};

	toggleMinimiceWindow() {
		this.minimiceWindow = !this.minimiceWindow;
		sessionStorage.setItem('minimiced', String(this.minimiceWindow));
	}
	closeResponseMessage() {
		this.responseMessageBridgeSE.toggleOpenResponseMessage(false);
		sessionStorage.clear();
	}
	confirmCloseResponseMessage() {
		const confirmCloseResponseMessage: IConfirmPopup = {
			buttonConfirmText: 'Close',
			buttonCancelText: 'Cancel',
			title: 'Are you sure you want to close the feedback viewer?',
			description:
				'Are you sure you want to close the feedback viewer? If you close it, you will have to re send the form.',
			icon: '/assets/Icons/GeneraalUseIcons/warning.svg'
		};
		const subject = StaticUtilitiesService.createSubject();
		this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
		this.popupSE
			.returnData()
			.pipe(takeUntil(subject))
			.subscribe((res: any) => {
				if (res == null) {
					return;
				}
				this.feedbackSE.clearFeedback();
				subject.next('');
				subject.complete();
			});
	}

	setFocus() {
		this.contentEditable!.focus();
	}

	trackByMessage(index: number, message: iFeedback) {
		return index;
	}

	/**
	 * CALLS TO API
	 */
}
