import { Injectable } from '@angular/core';
import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';

/**
 * LocalStorageHandlerService is a service that provides static methods for interacting with localStorage.
 * It includes methods for setting, getting, and clearing items in localStorage.
 * It also provides methods for setting and getting encoded items.
 */
@Injectable({
	providedIn: 'root'
})
export class LocalStorageHandlerService {
	constructor() {}

	/**
	 * Sets an item in localStorage.
	 * @param {string} key - The key under which the item is stored.
	 * @param {any} value - The value to be stored.
	 */

	public static setItem(key: string, value: any): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	/**
	 * Sets an encoded item in localStorage.
	 * @param {string} key - The key under which the item is stored.
	 * @param {any} value - The value to be stored.
	 */

	public static setEncodedItem(key: string, value: any): void {
		localStorage.setItem(
			key,
			iStaticUtilities.simpleCrypt(JSON.stringify(value))
		);
	}

	/**
	 * Retrieves an item from localStorage.
	 * @param {string} key - The key under which the item is stored.
	 * @returns {any} - The value of the item, or null if the item does not exist.
	 */

	public static getItem(key: string): any {
		let valueFromLocalStorage = localStorage.getItem(key);
		if (valueFromLocalStorage) {
			let value = JSON.parse(valueFromLocalStorage);
			return value;
		}
		return null;
	}

	/**
	 * Retrieves an encoded item from localStorage.
	 * @param {string} key - The key under which the item is stored.
	 * @returns {any} - The value of the item, or null if the item does not exist.
	 */

	public static getEncodedItem(key: string): any {
		let encodedValue = localStorage.getItem(key);
		if (encodedValue) {
			let value = JSON.parse(iStaticUtilities.simpleDecrypt(encodedValue));
			return value;
		}
		return null;
	}

	/**
	 * Clears all items from localStorage.
	 */

	public static clearLocalStorage(): void {
		localStorage.clear();
	}
}
