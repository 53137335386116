import { transition, trigger } from '@angular/animations';
import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
	AnimationControllerService,
	PopupService,
	StaticUtilitiesService,
	TokenManagmentService,
	VariablesDesignSystemService,
	iResultHttp,
	iStaticUtilities,
	iUnsubscribeDestroy,
	slideNavigationFunctions,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './Services/Api/login.service';
import { ResponseMessageBridgeService } from './Services/Utils/responseMessageBridge.service';
import { IConfirmPopup } from './Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from './Popups/confirm-popup/confirm-popup.component';
import { GeneralLoaderService } from './Services/Utils/generalLoader.service';
import { BoatLoaderComponent } from './Shared/Components/boat-loader/boat-loader.component';
import { iMainMessageList } from './Shared/Interfaces/Messages/iMainMessageList';
import { MessageService } from './Services/Api/message.service';
import { iFeedback } from './Shared/Interfaces/Utils/iFeedback';
import { FeedbackService } from './Services/Utils/Feedback.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('routerTransition', [
			transition(
				'* => slideToLeft',
				slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
			),
			transition(
				'* => slideToRight',
				slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
			),
			transition(
				'* => slideToTop',
				slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
			),
			transition(
				'* => slideToBottom',
				slideNavigationFunctions.getAnimation(
					slideNavigationTypes.slideToBottom
				)
			),
			transition(
				'* => slideToLeftDuplicate',
				slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
			),
			transition(
				'* => slideToRightDuplicate',
				slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
			),
			transition(
				'* => slideToTopDuplicate',
				slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
			),
			transition(
				'* => slideToBottomDuplicate',
				slideNavigationFunctions.getAnimation(
					slideNavigationTypes.slideToBottom
				)
			)
		])
	]
})
export class AppComponent extends iUnsubscribeDestroy implements OnInit {
	@ViewChild('threadMessageList') threadMessageList!: ElementRef;

	title = 'Valencia Cargo Expert';
	isResponseActive: boolean = sessionStorage.getItem('case') ? true : false;
	isNewActive: boolean = false;
	isThreadMessageListActive: boolean = false;
	threadsOpened: Array<any> = [];
	isNavHunter: boolean = false;
	interval: any = null;
	bubbleUnreadMessage: number = 0;
	isNotificationActive: boolean = false;
	feedback: iFeedback | null = null;

	constructor(
		private router: Router,
		private titleSE: Title,
		public animService: AnimationControllerService,
		private loginSE: LoginService,
		private responseMessageBridgeSE: ResponseMessageBridgeService,
		private popupSE: PopupService,
		private generalLoaderSE: GeneralLoaderService,
		private messageSE: MessageService,
		private staticUtilitiesSE: StaticUtilitiesService,
		private feedbackSE: FeedbackService
	) {
		super();
	}

	ngOnInit() {
		VariablesDesignSystemService.setIsRelease(environment.production);
		VariablesDesignSystemService.setUrlApi(environment.urlApi);
		this.generalLoaderSE.setLoaderComponent(BoatLoaderComponent);
		this.checkUrl();
		this.refreshTokenHandler();
		this.setWatermark();
		this.routerHandler();
		this.setActiveWindowHandler();
		this.responseMessageListener();
		this.newMessageListener();
		this.threadMessageListListener();
		this.getCountUnreadMessages();
		this.subscribeToFeedback();
		this.interval = setInterval(() => {
			this.getCountUnreadMessages();
		}, 60000);
	}

	override ngOnDestroy(): void {
		this._unsubInd4.next('');
		this._unsubInd4.complete();
	}

	/**
	 * HANDLERS
	 */

	successGetCountUnreadMessages(res) {
		sessionStorage.setItem('unreadMessagesCount', res.data);
		if (Number(res.data) > this.bubbleUnreadMessage) {
			this.responseMessageBridgeSE.updateMessagesData();
			this.isNotificationActive = true;
			this.bubbleUnreadMessage = Number(res.data);
		}
		this.responseMessageBridgeSE.setMessageCount(res.data);
		setTimeout(() => {
			this.isNotificationActive = false;
		}, 5000);
	}

	/**
	 * MISCELANEOUS
	 */

	setWatermark() {
		console.log(
			'%cDeveloped by: ',
			'color: rgb(90, 170,221); font-size: 24px;'
		);
		console.log(
			'%cQUASAR DYNAMICS',
			'color: rgb(90, 170,221); font-size: 64px;font-weigth: bold;'
		);
		console.log(
			'%cIf you like the project, you can find many more on our website:   https://quasardynamics.com/',
			'color: rgb(90, 170,221); font-size: 18px;'
		);
	}

	/**
	 * HANDLERS
	 */

	refreshTokenHandler() {
		TokenManagmentService.listenerTokenLost()
			.pipe(takeUntil(this._unsub))
			.subscribe((res) => {
				this.loginSE.isLogged()
					? StaticUtilitiesService.showError(
							'Ha habido un problema, intentalo más tarde'
						)
					: null;

				this.loginSE.logOut();
			});

		TokenManagmentService.listenerStartRefreshToken()
			.pipe(takeUntil(this._unsub))
			.subscribe((res) => {
				if (!location.pathname.includes('login')) {
					this.loginSE.refreshToken();
				}
			});
	}

	routerHandler() {
		this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route: ActivatedRoute = this.router.routerState.root;
					let routeTitle = '';
					while (route!.firstChild) {
						route = route.firstChild;
					}
					if (route.snapshot.data['title']) {
						routeTitle = route!.snapshot.data['title'];
					}
					return routeTitle;
				})
			)
			.subscribe((title: string) => {
				if (title) {
					this.titleSE.setTitle(`Valencia Cargo Expert - ${title}`);
				}
			});
	}
	setActiveWindowHandler() {
		if (sessionStorage.getItem('active') === 'new') {
			this.isNewActive = true;
			this.isResponseActive = false;
		} else {
			this.isNewActive = false;
			this.isResponseActive = true;
			if (sessionStorage.getItem('message')) {
				this.isResponseActive = true;
			} else {
				this.isResponseActive = false;
			}
		}
		if (localStorage.getItem('threadMessageList')) {
			this.isThreadMessageListActive = true;
			this.obtainThreadMessagesList();
		}
	}
	obtainThreadMessagesList() {
		const parsedSessionStorage: Array<iMainMessageList> = JSON.parse(
			localStorage.getItem('threadMessageList')!
		);
		const threads = Array.from(
			new Set(parsedSessionStorage.map((obj) => obj.thread))
		);
		this.threadsOpened = threads;
	}
	/**
	 * LISTENERS
	 */

	newMessageListener() {
		this.responseMessageBridgeSE
			.getToggleOpenNewMessage()
			.pipe(takeUntil(StaticUtilitiesService.createSubject()))
			.subscribe((res: any) => {
				if (res == null) {
					return;
				}
				this.isResponseActive
					? this.confirmForceCloseResponseMessage('response')
					: (this.isNewActive = res);
			});
	}
	responseMessageListener() {
		this.responseMessageBridgeSE
			.getToggleOpenResponseMessage()
			.pipe(takeUntil(StaticUtilitiesService.createSubject()))
			.subscribe((res: any) => {
				if (res == null) {
					return;
				}
				this.isNewActive
					? this.confirmForceCloseResponseMessage('new')
					: (this.isResponseActive = res);
			});
	}
	threadMessageListListener() {
		this.responseMessageBridgeSE
			.getToggleOpenThreadMessageList()
			.pipe(takeUntil(StaticUtilitiesService.createSubject()))
			.subscribe((res: any) => {
				if (res == null) {
					return;
				}
				this.isThreadMessageListActive = res;
				if (this.isThreadMessageListActive) {
					this.obtainThreadMessagesList();
				}
			});
	}
	listenerOptionHandler(type: 'new' | 'response') {
		switch (type) {
			case 'new':
				this.isNewActive = false;
				this.isResponseActive = true;
				sessionStorage.setItem('active', 'response');
				break;
			case 'response':
				this.isResponseActive = false;
				this.isNewActive = true;
				sessionStorage.setItem('active', 'new');

				break;
		}
	}
	/**
	 * FUNCTIONALITIES
	 */
	confirmForceCloseResponseMessage(type: 'new' | 'response') {
		const confirmCloseResponseMessage: IConfirmPopup = {
			buttonConfirmText: 'Discard',
			buttonCancelText: 'Cancel',
			title: 'There is a new unsent message',
			description:
				'Are you sure you want to discard the email? If you decide to continue, the message will be lost and you will have to retype it.',
			icon: '/assets/Icons/GeneraalUseIcons/warning.svg'
		};
		const subject = StaticUtilitiesService.createSubject();
		this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
		this.popupSE
			.returnData()
			.pipe(takeUntil(subject))
			.subscribe((res: any) => {
				if (res == null) {
					return;
				}
				this.listenerOptionHandler(type);
				subject.next('');
				subject.complete();
			});
	}

	checkUrl() {
		if (location.pathname.includes('nav-hunter')) {
			this.isNavHunter = true;
		}
	}
	subscribeToFeedback() {
		this.feedbackSE
			.getFeedback()
			.pipe(takeUntil(this._unsubInd4))
			.subscribe((res) => {
				if (res === undefined) return;
				this.feedback = res;
			});
	}

	/**
	 * API CALLS
	 */

	getCountUnreadMessages() {
		if (!localStorage.getItem('nmsr')) return;
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.messageSE.getCountUnreadMessages(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: this.successGetCountUnreadMessages(res),
					error: false
				});
			});
	}
}
