import { Component, OnInit } from '@angular/core';
import {
	StaticUtilitiesService,
	iResultHttp,
	sha256,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CitiesService } from 'src/app/Services/Api/cities.service';
import { CountryService } from 'src/app/Services/Api/country.service';
import { LoginService } from 'src/app/Services/Api/login.service';
import { RegisterClass } from 'src/app/Shared/Class/registerClass';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	extraClass: RegisterClass = new RegisterClass();

	constructor(
		private staticUtilitiesSE: StaticUtilitiesService,
		private countrySE: CountryService,
		private citiesSE: CitiesService,
		private loginSE: LoginService
	) {}

	ngOnInit() {
		this.getCountries();
		this.getTeacherSelector();
	}

	/**
	 * HANDLERS
	 */

	successGetCountriesHandler(res: iResultHttp) {
		let { data } = res;
		this.extraClass.countryOptions.items = data;
	}

	setCities(res: any) {
		let { data } = res;
		this.extraClass.cityOptions.items = data;
	}

	successRegisterHandler(res: iResultHttp) {
		StaticUtilitiesService.showFeedback('User registered successfully');
		this.staticUtilitiesSE.goTo('login', slideNavigationTypes.slideToLeft);
	}

	successGetTeacherSelectorHandler(res: iResultHttp) {
		let { data } = res;
		this.extraClass.teacherOptions.items = data;
	}

	/**
	 * FUNCTIONALITY
	 */

	goToLogin() {
		this.staticUtilitiesSE.goTo('login', slideNavigationTypes.slideToLeft);
	}

	checkEmail(email) {
		if (!this.extraClass.regexEmail.test(email)) {
			StaticUtilitiesService.showWarning('The email is not in a valid format');
		}

		if (
			this.extraClass.ngModels.email &&
			this.extraClass.ngModels.repeatEmail &&
			this.extraClass.regexEmail.test(this.extraClass.ngModels.email) &&
			this.extraClass.regexEmail.test(this.extraClass.ngModels.repeatEmail)
		) {
			this.extraClass.bothEmailsCheck =
				this.extraClass.ngModels.email === this.extraClass.ngModels.repeatEmail;
			if (!this.extraClass.bothEmailsCheck) {
				StaticUtilitiesService.showWarning('Both emails must be the same');
			}
		}
		this.checkIfCompleted();
	}

	checkIfCompleted() {
		this.extraClass.isDataCompleted =
			StaticUtilitiesService.isCompleteObject(this.extraClass.ngModels) &&
			this.extraClass.bothEmailsCheck;
	}

	sendCountryToApi(event) {
		this.getCitiesByCountryName(event);
		this.checkIfCompleted();
	}

	registerUser() {
		const bodyToPass = {
			user: {
				name: this.extraClass.ngModels.name!.trim(),
				surname: this.extraClass.ngModels.surname!.trim(),
				email: this.extraClass.ngModels.email!.trim(),
				country: this.extraClass.ngModels.country!.trim(),
				city: this.extraClass.ngModels.city!.trim(),
				repeatEmail: this.extraClass.ngModels.repeatEmail!.trim(),
				teacher: this.extraClass.ngModels.teacher,
				password: sha256(this.extraClass.ngModels.password!.trim()!),
				teacherId: this.extraClass.ngModels.teacher
			}
		};
		this.register(bodyToPass);
	}

	/**
	 * API CALLS
	 */

	getCountries() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.countrySE.getSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, [
					{
						method: this.successGetCountriesHandler(res)
					}
				]);
			});
	}

	getCitiesByCountryName(country: string) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.citiesSE.getCitiesByCountry(behaviorSubject, country);
		behaviorSubject.pipe(takeUntil(subject)).subscribe((res) => {
			if (res == null) {
				return;
			}
			if (res.error) {
				StaticUtilitiesService.showError(res.msg);
				return;
			} else {
				this.setCities(res);
			}
			subject.next(0);
			subject.complete();
		});
	}

	register(dataToPass: any) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.loginSE.register(behaviorSubject, dataToPass);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successRegisterHandler(res)
				});
			});
	}

	getTeacherSelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.loginSE.getTeacherSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetTeacherSelectorHandler(res)
				});
			});
	}
}
