<div class="box">
	<p *ngIf="title.length !== 0">{{ title }}</p>
	<div
		class="formLine"
		[ngClass]="{ longAndShort: hasSideButtons }"
	>
		<formatted-selector
			[required]="this.required"
			(change)="handleChangeSelector(true)"
			[(ngModel)]="selectedThirdParty"
			[optionsSelector]="extraClass.nameSelectorOptions"
			[label]="labels.thirdPartyTitle"
			[readOnly]="!ableToEdit"
			*ngIf="ableToEdit"
		></formatted-selector>

		<customInput
			[(ngModel)]="extraClass.previewThird"
			[options]="extraClass.readOnlyThird"
			*ngIf="!ableToEdit"
		></customInput>

		<btn
			*ngIf="hasSideButtons"
			classList="generalButton create"
			[ngClass]="{ disabled: !ableToEdit }"
			(click)="goToThird()"
		>
			+ Create third party
		</btn>
	</div>
	<div
		class="formLine"
		[ngClass]="{ longAndShort: hasSideButtons }"
	>
		<formatted-selector
			[required]="this.required"
			(change)="handleChangeSelector()"
			[(ngModel)]="selectedAddress"
			[ngClass]="{ disabled: !selectedThirdParty }"
			[label]="labels.addressTitle"
			[optionsSelector]="extraClass.addressSelectorOptions"
			*ngIf="ableToEdit"
		></formatted-selector>

		<customInput
			[(ngModel)]="extraClass.previewAddressName"
			[options]="extraClass.readOnlyAddress"
			*ngIf="!ableToEdit"
		></customInput>

		<btn
			*ngIf="hasSideButtons"
			classList="generalButton create"
			[ngClass]="{ disabled: !ableToEdit }"
			(click)="goToAddress()"
			[ngClass]="{ disabled: !selectedThirdParty || !ableToEdit }"
		>
			+ Add address
		</btn>
	</div>
	<div class="formLine">
		<customTextArea
			[(ngModel)]="extraClass.previewAddress"
			[options]="extraClass.addressCustomTextareaOptions"
			class="disabled"
		></customTextArea>
	</div>
	<ng-container *ngIf="haveFooter">
		<div class="formLine doble">
			<customInput
				[(ngModel)]="extraClass.footerData.thirdName"
				[options]="extraClass.nameOfCOntactCustomInputOptions"
			></customInput>
			<customInput
				[(ngModel)]="extraClass.footerData.thirdEmail"
				[options]="extraClass.emailCustomInputOptions"
			></customInput>
		</div>
		<div class="formLine doble">
			<customInput
				[(ngModel)]="extraClass.footerData.thirdCif"
				[options]="extraClass.vatCustomInputOptions"
			></customInput>
			<customInput
				[(ngModel)]="extraClass.footerData.thirdPhone"
				[options]="extraClass.phoneNumberCustomInputOptions"
			></customInput>
		</div>
	</ng-container>
	<ng-container *ngIf="arrayLength > 1">
		<div class="formLine flexEnd">
			<btn
				classList="generalButton editButton"
				(click)="handleClickOnDelete()"
			>
				<mat-icon>delete</mat-icon>
			</btn>
		</div>
	</ng-container>
</div>
