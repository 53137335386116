import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {
	StaticUtilitiesService,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';

@Component({
	selector: 'shiping-top-line',
	templateUrl: './shiping-top-line.component.html',
	styleUrls: ['./shiping-top-line.component.scss']
})
export class ShipingTopLineComponent implements OnInit {
	@Output() getSelectedStatus: EventEmitter<any> = new EventEmitter<any>();
	@Output() dollarFolder: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() folder: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() headerData: any = null;
	@Input() isDataLoadedHeaderData: boolean = false;
	@Input() isDocumentation: boolean = false;

	selectedState: number = 0;
	selectedStateName: string = 'Draft';
	statusArray: any[] = [
		{ name: 'Draft', id: 1 },
		{ name: 'Accepted', id: 2 },
		{ name: 'Rejected', id: 3 },
		{ name: 'Generated', id: 4 }
	];

	// Bool
	isStatusSelectorVisible: boolean = false;

	constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['headerData']) {
			if (changes['headerData'].currentValue) {
				this.selectedState = this.statusArray?.find(
					(x) => x?.name.toLowerCase() === this.headerData.state?.toLowerCase()
				)?.id;
			}
		}
	}

	/**
	 * FUNCTIONALITIES
	 */

	setState(state) {
		this.selectedState = state.id;
		this.selectedStateName = state.name;
		this.getSelectedStatus.emit(state);
	}

	sendDollarFolder() {
		this.dollarFolder.emit(true);
	}

	navegateToDocuments() {
		this.staticUtilitiesSE.goTo(
			'/shipment-documentation',
			slideNavigationTypes.slideToLeft
		);
	}

	sendFolder() {
		this.folder.emit(true);
	}
}
