import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from '@angular/core';

@Component({
	selector: 'progressGradientBar',
	templateUrl: './progress-greadient-bar.component.html',
	styleUrls: ['./progress-greadient-bar.component.scss']
})
export class ProgressGreadientBarComponent implements OnInit, OnChanges {
	@Input() value: number = 0;
	path: SVGPathElement | null = null;
	longitudTotal: number | null = null;
	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		changes['value'] ? (this.value = changes['value'].currentValue) : 0;
		this.actualizarProgreso(this.value);
	}

	ngOnInit(): void {}
	ngAfterViewChecked(): void {
		this.path = document.querySelector('.svgCircle path')! as SVGPathElement;
		this.longitudTotal = this.path.getTotalLength();
		this.actualizarProgreso(this.value);
	}
	calcularValorDeseado(porcentajeProgreso: number) {
		return (porcentajeProgreso / 100) * this.longitudTotal!;
	}
	actualizarProgreso(porcentajeProgreso: number) {
		let valorDeseado = this.calcularValorDeseado(porcentajeProgreso);
		if (this.path) this.path.style.strokeDashoffset = valorDeseado.toString();
	}
}
