<div class="line equalThree">
	<div class="customInputSobreTexto">
		<customInput
			[(ngModel)]="calculateObject.cost"
			[options]="costCustomInputOptions"
			(keyup)="calculate('cost')"
		></customInput>
		<p class="text">€</p>
	</div>
	<div class="customInputSobreTexto">
		<customInput
			[(ngModel)]="calculateObject.margin"
			[options]="marginCustomInputOptions"
			(keyup)="calculate('margin')"
		></customInput>
		<p class="text">%</p>
	</div>
	<div class="customInputSobreTexto">
		<customInput
			[(ngModel)]="calculateObject.price"
			[options]="priceCustomInputOptions"
			(keyup)="calculate('price')"
		></customInput>
		<p class="text">€</p>
	</div>
</div>
