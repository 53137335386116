import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iOfferOverview } from '../../Interfaces/Utils/iOfferOverview';

@Component({
	selector: 'offer-overview',
	templateUrl: './offer-overview.component.html',
	styleUrls: ['./offer-overview.component.scss']
})
export class OfferOverviewComponent implements OnInit {
	@Output() see: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() discard: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() complete: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() overviewData: iOfferOverview;
	@Input() isDraft: boolean = false;

	constructor() {
		this.overviewData = new iOfferOverview();
		this.overviewData.startData();
	}

	ngOnInit(): void {}

	seeDetails() {
		this.see.emit(true);
	}
	discardOffer() {
		this.discard.emit(true);
	}
	completeOffer() {
		this.complete.emit(true);
	}
}
