<div
	*ngIf="files.length === 0"
	class="add-shipment-document__dropzone"
	fileInput
	[accept]="supportedFormats"
	[(ngModel)]="ngFiles"
	[multiple]="multiple"
	(change)="getFile(ngFiles)"
>
	<div class="add-shipment-document__dropzoneContent--empty">
		<img
			src="assets/Icons/Shiping/place-item.svg"
			alt="place-item"
		/>
		<p>Drag and drop your files here or browse your pc</p>
	</div>
</div>
<div
	*ngIf="files.length !== 0"
	class="add-shipment-document__dropzone"
>
	<section
		[class]="
			'add-shipment-document__dropzoneContent ' +
			(multiple ? '' : 'add-shipment-document__dropzoneContent--single')
		"
		fileInput
		[accept]="supportedFormats"
		[(ngModel)]="ngFiles"
		[multiple]="multiple"
		(change)="getFile(ngFiles)"
	>
		<ng-container *ngIf="multiple">
			<article *ngFor="let file of files; index as i">
				<img
					src="assets/Icons/Shiping/note.svg"
					alt="file"
				/>
				<span>{{ file.file.name }}</span>
				<button (click)="deleteFile(i)">
					<mat-icon>cancel</mat-icon>
				</button>
			</article>
		</ng-container>
		<ng-container *ngIf="!multiple">
			<article>
				<img
					src="assets/Icons/Shiping/note.svg"
					alt="file"
				/>
				<span>
					{{ files[0].file.name }}
				</span>
				<button (click)="deleteFile(null)">
					<mat-icon>cancel</mat-icon>
				</button>
			</article>
		</ng-container>
	</section>
</div>
