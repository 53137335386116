<div class="loginGeneralContainer">
	<div class="left">
		<img
			class="logoVCE"
			src="/assets/Icons/Login/VCX_LOGO_oscuro_1.svg"
			alt=""
		/>
	</div>

	<div class="right">
		<form class="form">
			<div class="icons">
				<img
					src="/assets/Icons/Login/iconLogin1.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin2.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin3.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin4.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin5.svg"
					alt=""
				/>
				<p>Ally at the port</p>
			</div>

			<label for="">Log into your account</label>
			<customInput
				name="email"
				[options]="loginClass.emailOptions"
				[(ngModel)]="loginClass.loginData.username"
				(input)="loginClass.checkIfCompletedAndValidFormat()"
			></customInput>
			<customInput
				name="pass"
				[options]="loginClass.passwordOptions"
				[(ngModel)]="loginClass.loginData.password"
				(input)="loginClass.checkIfCompletedAndValidFormat()"
			></customInput>
			<btn
				[ngClass]="{ disabled: !loginClass.isDataCompleted }"
				(click)="
					loginClass.isDataCompleted ? login() : $event.stopPropagation()
				"
			>
				<ng-container>
					<span>Log in</span>
				</ng-container>
			</btn>
			<!-- <a class="forgotPassword">I forgot my password or username</a> -->
			<a
				class="forgotPassword"
				(click)="goToRegister()"
			>
				Register
			</a>
		</form>
	</div>
</div>
