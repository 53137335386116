import {
	StaticUtilitiesService,
	iOptionsCustomInput
} from '@quasar-dynamics/basic-designsystem';
import { ILogin } from '../Interfaces/Api/iLogin';

export class Login {
	/**
	 * FORM VARIABLES
	 */
	loginData: ILogin = {
		username: '',
		password: ''
	};

	/**
	 * OTHER VARIABLES
	 */
	isDataCompleted: boolean = false;

	/**
	 * INPUT OPTIONS
	 */
	emailOptions: iOptionsCustomInput = {
		label: 'E-mail:',
		placeholder: 'Introduce your e-mail',
		type: 'text'
	};
	passwordOptions: iOptionsCustomInput = {
		label: 'Password:',
		placeholder: 'Introduce your password',
		type: 'password'
	};

	constructor() {}

	/**
	 * VALIDATE DATA
	 */

	checkIfCompletedAndValidFormat() {
		this.isDataCompleted = StaticUtilitiesService.isCompleteObject(
			this.loginData
		);
	}
}
