import { Injectable } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DebouncerService {
	private debouncerTimeout: any;

	constructor() {}

	debounce(callbackFunction: Function, time: number = 300) {
		clearTimeout(this.debouncerTimeout);
		this.debouncerTimeout = setTimeout(() => {
			callbackFunction();
		}, time);
	}
}
