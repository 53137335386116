import { Injectable } from '@angular/core';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

@Injectable({
	providedIn: 'root'
})
export class CalculateCostMarginPriceService {
	constructor() {}

	calculate(
		calculateObject,
		priceCustomInputOptions,
		marginCustomInputOptions,
		option
	) {
		switch (option) {
			case 'cost':
				if (
					calculateObject.cost &&
					calculateObject.price &&
					calculateObject.margin
				) {
					this.resetMarginAndPrice(
						calculateObject,
						priceCustomInputOptions,
						marginCustomInputOptions
					);
				}
				// this.setCustomInputOptionsReadOnly(false, false, marginCustomInputOptions, priceCustomInputOptions);
				if (!calculateObject.margin) {
					calculateObject.price = calculateObject.cost;
					this.calculate(
						calculateObject,
						priceCustomInputOptions,
						marginCustomInputOptions,
						'price'
					);
				}
				break;

			case 'margin':
				if (calculateObject.margin) {
					calculateObject.price = this.calculatePriceWithMargin(
						calculateObject.cost,
						calculateObject.margin
					);
					// priceCustomInputOptions.readOnly = true;
				} else {
					calculateObject.price = calculateObject.cost;
					// priceCustomInputOptions.readOnly = false;
				}
				break;

			case 'price':
				if (calculateObject.price) {
					calculateObject.margin = this.calculateMarginWithPrice(
						calculateObject.cost,
						calculateObject.price
					);
					// marginCustomInputOptions.readOnly = true;
				} else {
					// marginCustomInputOptions.readOnly = false;
					calculateObject.margin = null;
				}
				break;

			default:
				break;
		}

		if (!calculateObject.cost) {
			this.resetMarginAndPrice(
				calculateObject,
				priceCustomInputOptions,
				marginCustomInputOptions
			);
			// this.setCustomInputOptionsReadOnly(true, true, marginCustomInputOptions, priceCustomInputOptions);
		}
	}

	resetMarginAndPrice(
		calculateObject,
		priceCustomInputOptions,
		marginCustomInputOptions
	) {
		calculateObject.margin = null;
		calculateObject.price = null;
	}

	setCustomInputOptionsReadOnly(
		marginReadOnly,
		priceReadOnly,
		marginCustomInputOptions,
		priceCustomInputOptions
	) {
		marginCustomInputOptions.readOnly = marginReadOnly;
		priceCustomInputOptions.readOnly = priceReadOnly;
	}

	calculatePriceWithMargin(cost, margin) {
		return parseFloat((cost * (1 + margin / 100)).toFixed(2));
	}

	calculateMarginWithPrice(cost, price) {
		return parseFloat((((price - cost) / cost) * 100).toFixed(2));
	}

	customIsFullArrayPermitingZero(array: any[]) {
		let booleansArray: boolean[] = [];
		if (array.length === 0) return false;
		array.forEach((element, index) => {
			Object.keys(element).forEach((key) => {
				if (key === 'margin' && element[key] === 0) {
					booleansArray.push(true);
				} else if (
					element[key] === false ||
					element[key] === undefined ||
					element[key] === null ||
					element[key] === 0
				) {
					booleansArray.push(false);
				} else {
					booleansArray.push(true);
				}
			});
		});
		return booleansArray.every((element) => element === true);
	}
}
