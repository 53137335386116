import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges
} from '@angular/core';
import {
	StaticUtilitiesService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CargoTypeService } from 'src/app/Services/Api/cargo-type.service';
import { TempRecordService } from 'src/app/Services/Api/temp-record.service';
import { WareContainerService } from 'src/app/Services/Api/ware-container.service';
import { DebouncerService } from 'src/app/Services/Utils/debouncer.service';
import { ShipingContainerClass } from 'src/app/Shared/Class/shipment/ShipingContainerClass';

@Component({
	selector: 'shiping-container',
	templateUrl: './shiping-container.component.html',
	styleUrls: ['./shiping-container.component.scss']
})
export class ShipingContainerComponent implements OnInit {
	@Output() delete: EventEmitter<number> = new EventEmitter<number>();

	@Input() index: number = 0;
	@Input() isDataLoaded: boolean = false;
	@Input() canEdit: boolean = true;
	@Input() cargoTypeSelector: any[] = [];
	@Input() unitTypeSelector: any[] = [];
	@Input() tempRecordSelector: any[] = [];
	@Input() container: any = {
		idWarecontainer: 209,
		plate: null,
		seal: null,
		units: 15,
		unitType: 'test',
		weight: 508342,
		volume: 350,
		hasThermalBlanket: false,
		hasVentilation: true,
		minTemp: null,
		maxTemp: null,
		isEmpty: false,
		isFlexi: false,
		isReinforced: true,
		tempRecord: 'Ticket',
		idDangerousgoods: 61,
		technicalName: 'wrtret',
		containerTypeName: "20' REEFER CONTAINER ",
		idCargotype: 1,
		cargoTypeName: 'FCL',
		acceptance: '1111A',
		delivery: '2222B',
		imoClasses: [
			{
				idDgimo: 112,
				idImoclass: 3,
				imoClassName: '3. Flammable liquids'
			},
			{
				idDgimo: 113,
				idImoclass: 6,
				imoClassName: '6. Toxics'
			},
			{
				idDgimo: 114,
				idImoclass: 7,
				imoClassName: '7. Radioactive materials'
			}
		]
	};

	editButtonClasslist: string = 'generalButton editButton';
	isRefer: boolean = false;
	extraClass: ShipingContainerClass = new ShipingContainerClass();

	constructor(
		private renderer: Renderer2,
		private wareContainerSE: WareContainerService,
		private debouncerSE: DebouncerService
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['cargoTypeSelector']) {
			if (changes['cargoTypeSelector'].currentValue) {
				this.extraClass.cargoTypeSelectorOptions.items =
					changes['cargoTypeSelector'].currentValue;
			}
		}

		if (changes['unitTypeSelector']) {
			if (changes['unitTypeSelector'].currentValue) {
				this.extraClass.unitTypeSelectorOptions.items =
					changes['unitTypeSelector'].currentValue;
			}
		}

		if (changes['tempRecordSelector']) {
			if (changes['tempRecordSelector'].currentValue) {
				this.extraClass.tempRecordSelectorOptions.items =
					changes['tempRecordSelector'].currentValue;
			}
		}

		if (changes['container']) {
			if (changes['container'].currentValue) {
				this.extraClass.setData(this.container);
				this.container.editable = true;
				this.isRefer = this.container?.containerTypeName?.includes('REEFER');
				setTimeout(() => {
					this.onClickEdit(this.container);
				}, 10);
			}
		}
	}

	/**
	 * HANDLERS
	 */

	successUpdateWareContainerHandler(res: iResultHttp) {
		let { data } = res;
	}

	/**
	 * FUNCTIONALITIES
	 */

	getEditableContent(event, key) {
		if (!event.target.innerHTML) return;
		let text = event.target.innerHTML.replace(',', '.').trim();
		// If it is a number
		if (this.extraClass.numberRegex.test(text)) {
			this.debouncerSE.debounce(
				() => this.updateWareContainerHook(text, key),
				500
			);
		} else {
			// We are filtering the text to only allow numbers
			event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
		}
	}

	onClickEdit(container) {
		container.editable = !container.editable;
		let thisContainer = document.getElementById(
			container.idWarecontainer
		) as HTMLElement;
		let arrayOfEditables: NodeListOf<HTMLParagraphElement> =
			thisContainer?.querySelectorAll('p.editable');
		if (arrayOfEditables?.length > 0 && arrayOfEditables) {
			arrayOfEditables.forEach((editable) => {
				if (container.editable) {
					this.renderer.setAttribute(editable, 'contenteditable', 'true');
					this.editButtonClasslist = 'generalButton editButton editing';
				} else {
					this.renderer.setAttribute(editable, 'contenteditable', 'false');
					this.editButtonClasslist = 'generalButton editButton';
				}
			});
		}
	}

	onClickDeleteThisWareContainer(ids) {
		this.delete.emit(ids);
	}

	updateWareContainerHook(value, key) {
		let objectToPass = this.extraClass.objectToPass;
		objectToPass.id = this.container.idWarecontainer;
		objectToPass[key] = value;
		this.updateWareContainer(objectToPass);
		delete objectToPass[key];
	}

	switchStateButton(container, option) {
		if (!this.canEdit || !this.container.editable) return;
		container[option] = !container[option];
		this.updateWareContainerHook(container[option], option);
	}

	updateSelector(value, key) {
		if (key === 'cargoTypeId') {
			this.container.cargoTypeName =
				this.extraClass.cargoTypeSelectorOptions.items.find(
					(item) => item.id === value
				)?.name;
		}
		if (key === 'unitType') {
			this.container.unitType = value;
		}
		if (key === 'tempRecord') {
			this.container.tempRecord = value;
		}
		this.updateWareContainerHook(value, key);
	}

	/**
	 * API CALLS
	 */

	updateWareContainer(objectToPass) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.wareContainerSE.updateWareContainer(behaviorSubject, objectToPass);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successUpdateWareContainerHandler(res)
				});
			});
	}
}
