<div
	class="topLineForm"
	skeleton
	[isDataLoaded]="isDataLoadedHeaderData"
>
	<div class="infoLine">
		<div class="titleCodeAndOffer">
			<p class="bold big">{{ headerData?.caseName ?? '-' }}</p>
			<p class="bold big">·</p>
			<span class="idBox">
				<p class="bold small">{{ headerData?.uniqueCode }}</p>
			</span>
		</div>
		<div
			class="statusSelector"
			[ngClass]="{
				draftState: selectedState === 1,
				accepted: selectedState === 2,
				rejected: selectedState === 3,
				generated: selectedState === 4
			}"
		>
			<div class="statusAndState">
				<p>Status</p>
				<p class="stateText">{{ headerData.state }}</p>
			</div>
			<!-- <div class="icon" [ngClass]="{rotate: isStatusSelectorVisible}">
        <mat-icon>expand_more</mat-icon>
      </div> -->

			<!-- selector box -->

			<!-- <div class="statusSelectorBox" *ngIf="isStatusSelectorVisible">
        <div class="statusSelectorBoxLine" [ngClass]="{selected: state.id === selectedState}" *ngFor="let state of statusArray" (click)="setState(state)">
          {{state.name}}
        </div>
      </div> -->
		</div>
	</div>
	<div class="buttonsLine">
		<!-- <btn *ngIf="!isDocumentation" classList="generalButton secondaryButton small" (click)="sendDollarFolder()"><img src="/assets/Icons/Shiping/folderDollar.svg" alt="dollarFolder" ></btn> -->
		<btn
			*ngIf="!isDocumentation"
			classList="generalButton secondaryButton small"
			(click)="sendFolder()"
		>
			<img
				src="/assets/Icons/Shiping/folder.svg"
				alt="folder"
			/>
		</btn>
		<btn
			*ngIf="isDocumentation"
			classList="generalButton secondaryButton small"
			(click)="sendDollarFolder()"
		>
			<mat-icon>menu</mat-icon>
		</btn>
	</div>
</div>
