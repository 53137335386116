<div
	class="threadMessageListGeneralContainer"
	[ngClass]="{ minimiced: minimiceWindow }"
>
	<div class="header">
		<p>List of pinned threads</p>
		<div class="icons">
			<mat-icon (click)="toggleMinimiceWindow()">expand_more</mat-icon>
			<mat-icon (click)="closeResponseMessage()">close</mat-icon>
		</div>
	</div>
	<div class="body">
		<mat-tab-group dynamicHeight>
			<ng-container *ngFor="let message of messages">
				<mat-tab label="{{ message.name }}">
					<!-- <span class="closeIcon" > <mat-icon>close</mat-icon> </span> -->
					<div
						class="messageBody"
						skeleton
					>
						<div class="whiteSeparator"></div>
						<ng-container *ngFor="let threadMessage of message.data">
							<div
								class="messageContainer"
								(mousedown)="mouseDownTime = $event.timeStamp"
								(mouseup)="mouseUpTime = $event.timeStamp"
								(click)="
									mouseUpTime - mouseDownTime <= 200 &&
										toggleOpenedThreadMessages(threadMessage)
								"
								[ngClass]="{
									opened: messageClass.threadMessagesSelected.includes(
										threadMessage.id
									)
								}"
							>
								<div class="info">
									<image
										class="circleImage"
										[image]="threadMessage.profileImg"
										[placeHolderImage]="
											'/assets/Images/Placeholder/userPlaceholder.png'
										"
									></image>
									<div class="data">
										<div class="titleContainer">
											<span>
												{{
													threadMessage.selfMessage
														? name
														: threadMessage.thirdName ??
															threadMessage.fromNameLead
												}}
											</span>
											<span>
												{{ threadMessage.dateSent | date: 'dd/MM/YYYY hh:mm' }}
											</span>
										</div>
										<span class="message">{{ threadMessage.body }}</span>
									</div>
								</div>
								<span class="openedMessage">{{ threadMessage.body }}</span>
								<ng-container *ngIf="threadMessage.documentUrls?.length > 0">
									<div class="attachGeneralContainer">
										<ng-container
											*ngFor="
												let file of threadMessage.documentUrls;
												let i = index
											"
										>
											<div
												class="attachContainer"
												(click)="$event.stopPropagation(); openFile(file)"
											>
												<div class="icon">
													<div class="white">
														<img
															src="/assets/Icons/Messages/line.svg"
															alt=""
														/>
														<img
															src="/assets/Icons/Messages/line.svg"
															alt=""
														/>
														<img
															src="/assets/Icons/Messages/line.svg"
															alt=""
														/>
														<img
															src="/assets/Icons/Messages/line.svg"
															alt=""
														/>
														<img
															src="/assets/Icons/Messages/line.svg"
															alt=""
														/>
													</div>
												</div>
												<span>Attached document {{ i + 1 }}</span>
											</div>
										</ng-container>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</mat-tab>
			</ng-container>
		</mat-tab-group>
	</div>
</div>

<div
	class="minimicedWindow"
	[ngClass]="{ minimiced: minimiceWindow }"
	(click)="toggleMinimiceWindow()"
>
	<p>List of pinned threads</p>
	<div class="icons">
		<mat-icon (click)="$event.stopPropagation(); toggleMinimiceWindow()">
			expand_less
		</mat-icon>
		<mat-icon (click)="$event.stopPropagation(); closeResponseMessage()">
			close
		</mat-icon>
	</div>
</div>
