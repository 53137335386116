<div
	class="toggleWithText"
	id="{{ extraId ? extraId : options.id }}"
>
	<p
		class="generalLabel red"
		*ngIf="showText"
	>
		No
	</p>
	<mat-slide-toggle
		[checked]="options.checked || checked"
		(change)="updateToggleLabel(options.id ?? extraId, $event.checked)"
		*ngIf="options.visible"
	></mat-slide-toggle>
</div>
