import { iBaseObject } from '@quasar-dynamics/basic-designsystem';

export interface iThirdPartyList extends iBaseObject {
	id: number | null;
	name: string;
	email: string;
	phone: string;
	mobilePhone: string;
	contactPerson: string;
	taxId: string;
	address: string;
	city: string;
	country: string;
	types: Array<string>;
}
export interface iThirdPartyListFilter {
	returns_per_page: number;
	page_number: number;
	type?: string;
	genericFilter?: string;
	total?: number;
	country?: number | null;
}

export class iThirdPartyList extends iBaseObject {
	static convertFromCollection(ob: any): Array<iThirdPartyList> {
		let ithirdPartyListCollection: Array<iThirdPartyList> = [];
		ob.forEach((element: any) => {
			ithirdPartyListCollection.push(this.convertFromObject(element));
		});
		return ithirdPartyListCollection;
	}

	static convertFromObject(ob: any): iThirdPartyList {
		let iThirdPartyListObj = new iThirdPartyList();
		iThirdPartyListObj.id = ob.id;
		iThirdPartyListObj.name = ob.name;
		iThirdPartyListObj.email = ob.email;
		iThirdPartyListObj.phone = ob.phone;
		iThirdPartyListObj.mobilePhone = ob.mobilePhone;
		iThirdPartyListObj.contactPerson = ob.contactPerson;
		iThirdPartyListObj.taxId = ob.taxId;
		iThirdPartyListObj.address = ob.address;
		iThirdPartyListObj.city = ob.city;
		iThirdPartyListObj.country = ob.country;
		iThirdPartyListObj.types = ob.types;
		return iThirdPartyListObj;
	}

	static createEmptyObject(): iThirdPartyList {
		let iThirdPartyListObj = new iThirdPartyList();
		iThirdPartyListObj.id = null;
		iThirdPartyListObj.name = '';
		iThirdPartyListObj.email = '';
		iThirdPartyListObj.phone = '';
		iThirdPartyListObj.mobilePhone = '';
		iThirdPartyListObj.contactPerson = '';
		iThirdPartyListObj.taxId = '';
		iThirdPartyListObj.address = '';
		iThirdPartyListObj.city = '';
		iThirdPartyListObj.country = '';
		iThirdPartyListObj.types = [];
		return iThirdPartyListObj;
	}
}
