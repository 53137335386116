import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'transformColumnName'
})
export class TransformColumnNamePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if (typeof value !== 'string') {
			return value;
		}
		const words = value
			.replace(/([A-Z])/g, ' $1')
			.trim()
			.split(' ');
		const transformedText = words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');

		return transformedText;
	}
}
