import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	IOptionCustomTextArea,
	StaticUtilitiesService,
	iDate,
	iOptionsCustomInput,
	iOptionsSelector,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { EdiService } from 'src/app/Services/Api/edi.service';
import { ShipmentService } from 'src/app/Services/Api/shipment.service';

@Component({
	selector: 'app-shiping-fullfill-edi-popup',
	templateUrl: './shiping-fullfill-edi-popup.component.html',
	styleUrls: ['./shiping-fullfill-edi-popup.component.scss']
})
export class ShipingFullfillEdiPopupComponent implements OnInit {
	emailCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Consignee email',
		label: 'Consignee email',
		classList: 'form',
		type: 'email'
	};

	kindOfMessageCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Kind of message',
		label: 'Kind of message',
		classList: 'form',
		type: 'text',
		readOnly: true
	};

	sentByCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Sent by',
		label: 'Sent by',
		classList: 'form',
		type: 'text',
		readOnly: true
	};

	messageReferenceCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Message reference',
		label: 'Message reference',
		classList: 'form',
		type: 'text',
		readOnly: true
	};

	sentOnCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Sent on',
		label: 'Sent on',
		classList: 'form',
		type: 'text',
		readOnly: true
	};

	containerTypeOptions: iOptionsCustomInput = {
		placeholder: 'Container',
		label: 'Container',
		classList: 'form',
		type: 'text',
		readOnly: true
	};

	receivedOnCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Received on',
		label: 'Received on',
		classList: 'form',
		type: 'text',
		readOnly: true
	};

	commentsCustomTextAriaOptions: IOptionCustomTextArea = {
		placeholder: 'Enter your comments here',
		cols: 30,
		rows: 5,
		classList: 'form'
	};

	// selector options
	containerSelectorOptions: iOptionsSelector = {
		items: [],
		placeholder: 'Container',
		bindLabel: 'name',
		bindValue: 'id',
		search: true,
		classList: 'specialFormSelector'
	};

	editable: boolean = false;
	isTransportOrder: boolean = false;

	email: string | null = null;
	emailArray: string[] = [];
	regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

	containerType: string | null = null;
	kindOfMessage: string | null = null;
	sentBy: string | null = null;
	messageReference: string | null = null;
	sentOn: string | null = null;
	receivedOn: string | null = null;
	comments: string | null = null;
	pcs: string | null = null;
	selectedContainer: number | null = null;

	constructor(
		public dialogRef: MatDialogRef<ShipingFullfillEdiPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any,
		private ediSE: EdiService,
		private shipmentSE: ShipmentService
	) {}

	ngOnInit() {
		this.setData();
		if (this.modalData.data.editable) this.getEDIPredata();
		if (!this.modalData.data.editable && this.modalData.data.bodyItem.id)
			this.getEDIById();
		if (this.modalData.data.type === 'to')
			this.getShipmentContainerSelectorById();
	}

	/**
	 * HANDLERS
	 */

	successGetEDIByIdHandler(res: iResultHttp) {
		let { data } = res;
		this.setDataFromEdiById(data);
	}

	successCreateEDIHandler(res: iResultHttp) {
		let { data } = res;
	}

	successGetEDIPredataHandler(res: iResultHttp) {
		let { data } = res;
		this.setPredataData(data);
	}

	successGetShipmentContainerSelectorByIdHandler(res: iResultHttp) {
		let { data } = res;
		this.containerSelectorOptions.items = data;
	}

	/**
	 * FUNCTIONALITIES
	 */

	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}

	forceClosePopup() {
		this.dialogRef.close();
	}

	generateBodyToSend() {
		const body = {
			message: this.comments,
			type: this.modalData.data.type,
			shipmentId: this.modalData.data.shipmentId,
			transportOrderId: this.selectedContainer,
			cancelEdi: !this.editable && this.modalData.data.type === 'to'
		};
		this.closePopup(body);
	}

	/**
	 * GETTERS AND SETTERS
	 */

	setData() {
		let data = this.modalData.data;
		this.editable = data.editable;
		this.isTransportOrder = data.isTransportOrder;
	}

	setPredataData(data) {
		this.email = data.recipient;
		this.kindOfMessage = data.kom;
		this.sentBy = data.by;
		this.messageReference = data.messageReference;
		this.pcs = data.pcs;
	}

	setDataFromEdiById(data) {
		this.email = data.to;
		this.kindOfMessage = data.kindOfMessage ?? null;
		this.sentBy = data.by;
		this.messageReference = data.messageReference ?? null;
		this.pcs = data.pcs ?? null;
		this.sentOn = iDate
			.javascriptConvert(new Date(data.sendingDate))
			.toStringDate('EU');
		this.receivedOn = iDate
			.javascriptConvert(new Date(data.receivedDate))
			.toStringDate('EU');
		this.comments = data.message;
		this.selectedContainer = data?.containerId;
		this.containerType = data?.containerType;
	}

	/**
	 * API CALLS
	 */

	getEDIById() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.ediSE.getEDIById(behaviorSubject, this.modalData.data.bodyItem.id);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetEDIByIdHandler(res)
				});
			});
	}

	createEDI(objectToPass) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.ediSE.create(behaviorSubject, objectToPass);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successCreateEDIHandler(res)
				});
			});
	}

	getEDIPredata() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.ediSE.getEDIPredata(behaviorSubject, this.modalData.data.shipmentId, {
			type: this.modalData.data.type
		});
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetEDIPredataHandler(res)
				});
			});
	}

	getShipmentContainerSelectorById() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.shipmentSE.getShipmentContainerSelectorById(
			behaviorSubject,
			this.modalData.data.shipmentId
		);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetShipmentContainerSelectorByIdHandler(res)
				});
			});
	}
}
