import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EdiService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();

	constructor(private http: HttpClient) {
		super();
	}

	getEDIList(
		behaviorSubject: BehaviorSubject<any>,
		shipmentId: string | number,
		params: any
	) {
		this.http
			.get(
				this.urlApi + '/api/edis/' + shipmentId + this.optionsGet(params),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getEDIList,
						args: [behaviorSubject, shipmentId, params]
					});
				}
			});
	}

	getEDIPredata(
		behaviorSubject: BehaviorSubject<any>,
		shipmentId: string | number,
		params: any
	) {
		this.http
			.get(
				this.urlApi +
					'/api/edi/predata/' +
					shipmentId +
					this.optionsGet(params),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getEDIPredata,
						args: [behaviorSubject, shipmentId, params]
					});
				}
			});
	}

	getEDIById(behaviorSubject: BehaviorSubject<any>, id: string | number) {
		this.http.get(this.urlApi + '/api/edi/' + id, this.getHeader()).subscribe({
			next: (data: any) => {
				this.res = new iResultHttp({
					status: 200,
					ok: true,
					message: '',
					data: data
				});
				this.sendNextObservable(behaviorSubject, this.res);
			},
			error: (error: any) => {
				this.res = new iResultHttp({
					status: error.status,
					ok: false,
					message: error.error.message
				});
				this.sendNextObservable(behaviorSubject, this.res, true, {
					method: this.getEDIById,
					args: [behaviorSubject, id]
				});
			}
		});
	}

	create(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http.post(this.urlApi + '/api/edi', data, this.getHeader()).subscribe({
			next: (data: any) => {
				this.res = new iResultHttp({
					status: 200,
					ok: true,
					message: '',
					data: null
				});
				this.sendNextObservable(behaviorSubject, this.res);
			},
			error: (error: any) => {
				this.res = new iResultHttp({
					status: error.status,
					ok: false,
					message: error.error.message
				});
				this.sendNextObservable(behaviorSubject, this.res, true, {
					method: this.create,
					args: [behaviorSubject, data]
				});
			}
		});
	}

	cancelEdiById(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(this.urlApi + '/api/edi/cancel/' + data.id, data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.cancelEdiById,
						args: [behaviorSubject, data]
					});
				}
			});
	}
}
