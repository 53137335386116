import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';

export interface iThreadMessage {
	id: number | null;
	thread: number | null;
	dateSent: string;
	subject: string;
	body: string;
	isImportant: boolean;
	wasRead: boolean;
	sequence: number | null;
	fromNameLead: string;
	thirdName: string;
	profileImg: string;
	documentUrls?: Array<{ url: string }>;
	selfMessage: boolean;
	idStudentlead?: number | null;
}
export interface iThreadMessageFilter {
	returns_per_page: number;
	page_number: number;
}

export class iThreadMessage {
	static convertFromCollection(ob: any): Array<iThreadMessage> {
		let iThreadMessageCollection: Array<iThreadMessage> = [];
		ob.forEach((element: any) => {
			iThreadMessageCollection.push(this.convertFromObject(element));
		});
		return iThreadMessageCollection;
	}

	static convertFromObject(ob: any): iThreadMessage {
		let iThreadMessageObj = new iThreadMessage();
		iThreadMessageObj.id = ob.id;
		iThreadMessageObj.thread = ob.thread;
		iThreadMessageObj.dateSent = ob.dateSent;
		iThreadMessageObj.subject = ob.subject;
		iThreadMessageObj.body = ob.body;
		iThreadMessageObj.isImportant = ob.isImportant;
		iThreadMessageObj.wasRead = ob.wasRead;
		iThreadMessageObj.sequence = ob.sequence;
		iThreadMessageObj.fromNameLead = ob.fromNameLead;
		iThreadMessageObj.thirdName = ob.thirdName;
		iThreadMessageObj.profileImg = ob.profileImg;
		iThreadMessageObj.selfMessage = iStaticUtilities.normalizeBoolean(
			ob.selfMessage
		);
		iThreadMessageObj.documentUrls = ob.documentUrls ?? [];
		iThreadMessageObj.idStudentlead = ob.idStudentlead ?? null;
		return iThreadMessageObj;
	}

	static createEmptyObject(): iThreadMessage {
		let iThreadMessageObj = new iThreadMessage();
		iThreadMessageObj.id = null;
		iThreadMessageObj.thread = null;
		iThreadMessageObj.dateSent = '';
		iThreadMessageObj.subject = '';
		iThreadMessageObj.body = '';
		iThreadMessageObj.isImportant = false;
		iThreadMessageObj.wasRead = false;
		iThreadMessageObj.sequence = null;
		iThreadMessageObj.fromNameLead = '';
		iThreadMessageObj.thirdName = '';
		iThreadMessageObj.profileImg = '';
		iThreadMessageObj.selfMessage = false;
		iThreadMessageObj.documentUrls = [];
		iThreadMessageObj.idStudentlead = null;
		return iThreadMessageObj;
	}
}
