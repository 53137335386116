import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CitiesService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getCitiesByCountry(behaviorSubject: BehaviorSubject<any>, country: string) {
		this.http
			.get(
				'https://countriesnow.space/api/v0.1/countries/cities/q?country=' +
					country,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: data.message,
						data: null
					});
					this.sendNextObservable(behaviorSubject, data);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getCitiesByCountry,
						args: [behaviorSubject, country]
					});
				}
			});
	}
}
