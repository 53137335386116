import { Component, Input, OnInit } from '@angular/core';
import { IStatisticsPill } from '../../Interfaces/Utils/iStatisticsPill';

@Component({
	selector: 'statisticsPills',
	templateUrl: './statistics-pills.component.html',
	styleUrls: ['./statistics-pills.component.scss']
})
export class StatisticsPillsComponent implements OnInit {
	@Input() options: IStatisticsPill = new IStatisticsPill();

	constructor() {}

	ngOnInit(): void {}
}
