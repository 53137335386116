import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iShipmentListParams } from 'src/app/Shared/Interfaces/Api/Shipment/iShipmentListParams';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
	providedIn: 'root'
})
export class ShipmentService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getShipmentsByStudentToken(
		behaviorSubject: BehaviorSubject<any>,
		params: iShipmentListParams
	) {
		this.http
			.get(
				this.urlApi + '/api/shipments' + this.optionsGet(params),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedCountries = iStaticUtilities.normalizeNamesCollection(
						data.data
					);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: { data: normalizedCountries, total: data.total }
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentsByStudentToken,
						args: [behaviorSubject, params]
					});
				}
			});
	}

	getShippingHeaderById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(this.urlApi + '/api/shipment/header/' + shippingId, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCountries = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCountries
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShippingHeaderById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentGeneralDescriptionById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/description/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentGeneralDescriptionById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentServiceProvidedById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/service/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentServiceProvidedById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentCargoDescriptionsById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/wares/description/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment =
						iStaticUtilities.normalizeNamesCollection(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentCargoDescriptionsById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentGeneralWaresInfoById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/wares/info/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentGeneralWaresInfoById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentBookingRequestComments(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/booking/request/comments/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentBookingRequestComments,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentGeneralContainersById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/containers/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment =
						iStaticUtilities.normalizeNamesCollection(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentGeneralContainersById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentTransportDetailsById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/transport/details/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentTransportDetailsById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentCustomsTransportDetailsById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/custom/transport/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentCustomsTransportDetailsById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentCustomsDetailsById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(this.urlApi + '/api/shipment/custom/' + shippingId, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentCustomsDetailsById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentMasterBLDetailsById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string,
		type: { type: string }
	) {
		this.http
			.get(
				this.urlApi +
					'/api/shipment/bl/details/' +
					shippingId +
					this.optionsGet(type),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentMasterBLDetailsById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentMasterBLDocumentById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string,
		type: { type: string }
	) {
		this.http
			.get(
				this.urlApi +
					'/api/shipment/bl/data/' +
					shippingId +
					this.optionsGet(type),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentMasterBLDocumentById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentCargoDangerousGoods(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi +
					'/api/shipment/general/wares/dangerousgoods/' +
					shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment =
						iStaticUtilities.normalizeNamesCollection(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentCargoDangerousGoods,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	updateGeneralShipmentDescription(
		behaviorSubject: BehaviorSubject<any>,
		data: any
	) {
		this.http
			.put(
				this.urlApi + '/api/shipment/general/description/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateGeneralShipmentDescription,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateGeneralShipmentService(
		behaviorSubject: BehaviorSubject<any>,
		data: any
	) {
		this.http
			.put(
				this.urlApi + '/api/shipment/general/service/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateGeneralShipmentService,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateGeneralWare(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/shipment/general/wares/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateGeneralWare,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateShipmentCustomsTransportDetails(
		behaviorSubject: BehaviorSubject<any>,
		data: any
	) {
		this.http
			.put(
				this.urlApi + '/api/shipment/custom/transport/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateShipmentCustomsTransportDetails,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateShipmentPort(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/offer/shipment/port/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateShipmentPort,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateShipmentCustomsClearance(
		behaviorSubject: BehaviorSubject<any>,
		data: any
	) {
		this.http
			.put(
				this.urlApi + '/api/shipment/custom/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateShipmentCustomsClearance,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateBlData(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(this.urlApi + '/api/shipment/bl/' + data.id, data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateBlData,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	getCustomsDataById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/customs/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getCustomsDataById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getShipmentStatusById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(this.urlApi + '/api/sh/edit/status/' + shippingId, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentStatusById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	editShipmentStatusById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string,
		type: 'br' | 'si'
	) {
		this.http
			.put(
				this.urlApi + '/api/sh/edit/status/' + shippingId + '?type=' + type,
				null,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.editShipmentStatusById,
						args: [behaviorSubject, shippingId, type]
					});
				}
			});
	}

	getShipmentContainerSelectorById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/containers/selector/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment =
						iStaticUtilities.normalizeNamesCollection(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentContainerSelectorById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getshipmentCustomCost(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/custom/costs/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getshipmentCustomCost,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}
	updateShipmentCustomCost(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/shipment/custom/costs/' + data.shipmentId,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateShipmentCustomCost,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	getShipmentTransportInlandById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/transport/inlands/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment =
						iStaticUtilities.normalizeNamesCollection(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShipmentTransportInlandById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	getAditionalInformationById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + '/api/shipment/general/info/' + shippingId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAditionalInformationById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	updateAdditoinalInformation(
		behaviorSubject: BehaviorSubject<any>,
		data: any
	) {
		this.http
			.put(
				this.urlApi + '/api/shipment/general/wares/addinfo/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateAdditoinalInformation,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	getShippingInstructionsById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string,
		type: 'consignee' | 'shipper'
	) {
		this.http
			.get(
				this.urlApi + `/api/shipment/masterbl/third/${shippingId}?type=${type}`,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getShippingInstructionsById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	putShippingInstructions(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/shipment/masterbl/third/' + data.shippingId,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.putShippingInstructions,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	getHouseBLDataById(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string,
		type: 'consignee' | 'shipper'
	) {
		this.http
			.get(
				this.urlApi + `/api/shipment/housebl/third/${shippingId}?type=${type}`,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getHouseBLDataById,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	putHouseBLData(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/shipment/housebl/third/' + data.shippingId,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.putHouseBLData,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	getNotifyShipping(
		behaviorSubject: BehaviorSubject<any>,
		shippingId: number | string
	) {
		this.http
			.get(
				this.urlApi + `/api/shipment/masterbl/notify/${shippingId}`,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedShipment = iStaticUtilities.normalizeNames(data);
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedShipment
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getNotifyShipping,
						args: [behaviorSubject, shippingId]
					});
				}
			});
	}

	private processBlTypes(blTypes: any, behaviorSubject: BehaviorSubject<any>) {
		this.res = new iResultHttp({
			status: 200,
			ok: true,
			message: '',
			data: blTypes
		});
		this.sendNextObservable(behaviorSubject, this.res);
	}

	getBlTypes(behaviorSubject: BehaviorSubject<any>) {
		const blTypesFromStorage =
			LocalStorageHandlerService.getEncodedItem('bl-types');

		if (blTypesFromStorage) {
			this.processBlTypes(blTypesFromStorage, behaviorSubject);
			return;
		}

		this.http
			.get(this.urlApi + `/api/bl/types/selector`, this.getHeader())
			.subscribe({
				next: (data: any) => {
					LocalStorageHandlerService.setEncodedItem('bl-types', data);
					this.processBlTypes(data, behaviorSubject);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getBlTypes,
						args: [behaviorSubject]
					});
				}
			});
	}

	generateBl(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.post(
				this.urlApi + '/api/shipment/housebl/generate/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});

					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.generateBl,
						args: [behaviorSubject, data]
					});
				}
			});
	}
}
