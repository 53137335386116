<div
	class="secondLine"
	skeleton
	[isDataLoaded]="isDataLoadedHeaderData"
>
	<div class="port">
		<p class="portName">{{ headerData?.originCountry }}</p>
		<p class="portDate">{{ headerData?.departureDate | date: 'dd/MM/yyyy' }}</p>
	</div>

	<div class="incoterm">
		<span class="dot"></span>
		<span class="incotermBox">
			<mat-icon>flag</mat-icon>
			<p class="incotermName">{{ headerData?.incoterm }}</p>
		</span>
		<span class="dot"></span>
		<div class="line"></div>
	</div>

	<div class="port">
		<p class="portName">{{ headerData?.destinationCountry }}</p>
		<p class="portDate">{{ headerData?.arrivalDate | date: 'dd/MM/yyyy' }}</p>
	</div>

	<div class="lineInfo">
		<p class="lineInfoName">INCOTERM</p>
		<p class="lineInfoValue">{{ headerData?.incoterm }}</p>
	</div>

	<div
		class="lineInfo"
		*ngIf="!isDocumentation"
	>
		<p class="lineInfoName">Transportation method</p>
		<p class="lineInfoValue">{{ headerData?.transportationMethod }}</p>
	</div>

	<div
		class="lineInfo"
		*ngIf="!isDocumentation"
	>
		<p class="lineInfoName">Flow</p>
		<p class="lineInfoValue">{{ headerData?.flowName }}</p>
	</div>

	<div class="lineInfo">
		<p class="lineInfoName">Booking number</p>
		<p class="lineInfoValue">{{ headerData?.bookingNumber }}</p>
	</div>

	<div class="lineInfo">
		<p class="lineInfoName">Master BL</p>
		<p class="lineInfoValue">{{ headerData?.masterBLnumber }}</p>
	</div>
</div>
