import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';
import { iCalculateCostMarginPriceObject } from '../../Interfaces/Utils/iCalculateCostMarginPriceObject';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

export class AddCostPopupClass {
	// ngModels
	calculateObject: iCalculateCostMarginPriceObject = {
		cost: null,
		margin: null,
		price: null
	};

	costConcept: any = null;
	costTitel = null;
	factor: number | null = null;

	isChecked: boolean = false;

	// Custom Input options
	costCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Cost',
		type: 'number',
		classList: 'form',
		label: 'Cost',
		required: true
	};
	marginCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Margin',
		type: 'number',
		classList: 'form',
		label: 'Margin',
		readOnly: true,
		required: true
	};
	priceCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Price',
		type: 'number',
		classList: 'form',
		label: 'Price',
		readOnly: true,
		required: true
	};
	costTitleConceptCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Cost title concept (Denomination to print)',
		type: 'text',
		classList: 'form',
		label: 'Cost title concept (Denomination to print)'
	};

	// Selector options
	costConceptSelectorOptions: iOptionsSelector = {
		items: [],
		bindLabel: 'name',
		placeholder: 'Cost concept',
		search: true,
		classList: 'specialFormSelector'
	};
	factorSelectorOptions: iOptionsSelector = {
		items: StaticDataHandlerService.getFreightFactor(),
		bindLabel: 'name',
		bindValue: 'name',
		placeholder: 'Factor',
		search: true,
		classList: 'specialFormSelector'
	};
	bodyToReturn: any = null;

	rellenarBodyToReturn() {
		//convertir los costes en number decimal
		this.calculateObject.cost = Number(this.calculateObject.cost);
		this.calculateObject.margin = Number(this.calculateObject.margin);
		this.calculateObject.price = Number(this.calculateObject.price);

		//convertir el margen en porcentaje
		this.calculateObject.margin = this.calculateObject.margin / 100;

		this.bodyToReturn = {
			cost: this.calculateObject.cost,
			margin: this.calculateObject.margin,
			amount: this.calculateObject.price,
			concept: this.costConcept,
			costTitle: this.costTitel,
			factor: this.factor
		};
	}
}
