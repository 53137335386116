<div class="pageLayoutGeneralContainer">
	<customHeader></customHeader>
	<div class="bodyLayout">
		<sidebar></sidebar>
		<div
			class="body"
			id="body"
		>
			<ng-content></ng-content>
		</div>
	</div>
</div>
