<div class="leftButtons">
	<btn
		classList="generalButton filterButton"
		*ngIf="filterOptions.showFilterButton"
		(click)="emitFilter()"
	>
		<img
			src="/assets/Icons/Filters/filter.svg"
			alt="filtros"
		/>
		Filters
	</btn>

	<btn
		classList="generalButton filterButton"
		*ngIf="filterOptions.showSortButton"
		(click)="emitFilter()"
	>
		Sort
		<mat-icon>expand_more</mat-icon>
	</btn>

	<btn
		classList="generalButton filterButton"
		*ngIf="filterOptions.showColumnsButton"
		(click)="emitColumns()"
	>
		<img
			src="/assets/Icons/Filters/settings.svg"
			alt="filtros"
		/>
		Columns
	</btn>
</div>
