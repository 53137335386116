export interface iThirdPartySelector {
	id: number | null;
	name: string;
}

export interface iThirdPartySelectorOutput {
	thirdPartyId: number | null;
	addressId: number | null;
}

export class iThirdPartySelector {
	static convertFromCollection(ob: any): Array<iThirdPartySelector> {
		let iThirdPartySelectorCollection: Array<iThirdPartySelector> = [];
		ob.forEach((element: any) => {
			iThirdPartySelectorCollection.push(this.convertFromObject(element));
		});
		return iThirdPartySelectorCollection;
	}

	static convertFromObject(ob: any): iThirdPartySelector {
		let iThirdPartySelectorObj = new iThirdPartySelector();
		iThirdPartySelectorObj.id = ob.id;
		iThirdPartySelectorObj.name = ob.name;
		return iThirdPartySelectorObj;
	}

	static createEmptyObject(): iThirdPartySelector {
		let iThirdPartySelectorObj = new iThirdPartySelector();
		iThirdPartySelectorObj.id = null;
		iThirdPartySelectorObj.name = '';
		return iThirdPartySelectorObj;
	}
}
