<div
	class="messageContainer"
	#notification
	*ngIf="isNotificationActive"
>
	<p class="generalLabel">Hey! You have a new message.</p>
	<btn
		style="justify-content: flex-end"
		classList="generalButton widthFit create"
		(click)="goToMessages()"
	>
		Open message
	</btn>
</div>
