import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iCommercialOfferTableContainer } from '../../Interfaces/Utils/iCommercialOfferTableContainer';

@Component({
	selector: 'commercial-offer-table-container',
	templateUrl: './commercial-offer-table-container.component.html',
	styleUrls: ['./commercial-offer-table-container.component.scss']
})
export class CommercialOfferTableContainerComponent implements OnInit {
	@Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() edit: EventEmitter<any> = new EventEmitter<any>();
	@Output() delete: EventEmitter<any> = new EventEmitter<any>();

	@Input() readOnly: boolean = false;
	@Input() content: iCommercialOfferTableContainer = {
		title: 'Cargo freight (Container)',
		buttonText: 'Add freight',
		headerLine: [
			{
				display: 'Port of origin',
				id: 'portOfOrigin'
			},
			{
				display: 'Port of destination',
				id: 'portOfDestination'
			},
			{
				display: 'Cost',
				id: 'cost',
				currency: true
			},
			{
				display: 'Margin',
				id: 'margin',
				percent: true
			},
			{
				display: 'Amount',
				id: 'amount',
				currency: true
			}
		],
		bodyLines: [
			{
				portOfOrigin: 'Porto de Santos',
				portOfDestination: 'Porto de Santos',
				cost: '500 ',
				margin: '500 ',
				amount: '500 '
			},
			{
				portOfOrigin: 'Porto de Santos',
				portOfDestination: 'Porto de Santos',
				cost: '500 ',
				margin: '500 ',
				amount: '500 '
			},
			{
				portOfOrigin: 'Porto de Santos',
				portOfDestination: 'Porto de Santos',
				cost: '500 ',
				margin: '500 ',
				amount: '500 '
			}
		]
	};

	constructor() {}

	ngOnInit() {}

	onClick() {
		this.buttonClicked.emit(true);
	}
	sendEditOutput(line, index) {
		let linePlusIndex = {
			line,
			index
		};
		this.edit.emit(linePlusIndex);
	}
	sendDeleteOutput(line, index) {
		let linePlusIndex = {
			line,
			index
		};
		this.delete.emit(linePlusIndex);
	}
}
