import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iFilterOptions } from 'src/app/Shared/Interfaces/Utils/iFilterOptions';

@Component({
	selector: 'filter-sort-columns',
	templateUrl: './filter-sort-columns.component.html',
	styleUrls: ['./filter-sort-columns.component.scss']
})
export class FilterSortColumnsComponent implements OnInit {
	@Output() filter: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() sort: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() columns: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() filterOptions: iFilterOptions = {
		showColumnsButton: true,
		showFilterButton: true,
		showSortButton: true
	};

	constructor() {}

	ngOnInit(): void {}

	emitFilter() {
		this.filter.emit(true);
	}
	emitSort() {
		this.sort.emit(true);
	}
	emitColumns() {
		this.columns.emit(true);
	}
}
