import { Component, OnInit } from '@angular/core';
import {
	StaticUtilitiesService,
	iResultHttp,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/login.service';
import { MessageService } from 'src/app/Services/Api/message.service';
import { ResponseMessageBridgeService } from 'src/app/Services/Utils/responseMessageBridge.service';

@Component({
	selector: 'customHeader',
	templateUrl: './custom-header.component.html',
	styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements OnInit {
	name: string = localStorage.getItem('name') ?? '';
	urlActive: string = '';
	bubbleUnreadMessage: number = 0;
	isNotificationActive: boolean = false;
	messageCountSubject: Subject<any> = new Subject<any>();
	roles: string[] = LoginService.getUserPermissionsAndRoles();
	interval: any = null;

	constructor(
		private staticUtilitiesSE: StaticUtilitiesService,
		private responseMessageBridgeSE: ResponseMessageBridgeService,
		private messageSE: MessageService
	) {}

	ngOnInit(): void {
		this.urlActive = this.staticUtilitiesSE.getActualRoute();
		setTimeout(() => {
			this.bubbleUnreadMessage = Number(
				sessionStorage.getItem('unreadMessagesCount')
			);
		}, 250);
		this.connectToBehaviourSubjectCountMessage();
	}

	ngOnDestroy(): void {
		this.messageCountSubject.next(true);
		this.messageCountSubject.complete();
		if (this.interval) clearInterval(this.interval);
	}

	/**
	 * FUNCTIONALITIES
	 */
	goToHome() {
		if (this.roles.includes('ROLE_TEACHER')) {
			this.staticUtilitiesSE.goTo(
				'/teacher',
				slideNavigationTypes.slideToBottom
			);
			return;
		}
		if (this.roles.includes('ROLE_STUDENT')) {
			this.staticUtilitiesSE.goTo('/home', slideNavigationTypes.slideToBottom);
			return;
		}
	}
	goToCases() {
		this.staticUtilitiesSE.goTo('/cases', slideNavigationTypes.slideToBottom);
	}
	goToMessages() {
		this.staticUtilitiesSE.goTo(
			'/messages',
			slideNavigationTypes.slideToBottom
		);
	}
	goToNavHunter() {
		this.staticUtilitiesSE.goTo(
			'/nav-hunter',
			slideNavigationTypes.slideToBottom,
			{},
			true
		);
	}

	/**
	 * HANDLERS
	 */
	successGetCountUnreadMessages(res) {
		sessionStorage.setItem('unreadMessagesCount', res.data);
		if (Number(res.data) > this.bubbleUnreadMessage) {
			this.responseMessageBridgeSE.updateMessagesData();
			this.isNotificationActive = true;
		}
		this.responseMessageBridgeSE.setMessageCount(res.data);
		setTimeout(() => {
			this.isNotificationActive = false;
		}, 5000);
	}

	connectToBehaviourSubjectCountMessage() {
		this.responseMessageBridgeSE
			.getMessageCount()
			.pipe(takeUntil(this.messageCountSubject))
			.subscribe((res) => {
				this.bubbleUnreadMessage = res;
			});
	}

	/**
	 * CALLS TO API
	 */
	getCountUnreadMessages() {
		if (!localStorage.getItem('nmsr')) return;
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.messageSE.getCountUnreadMessages(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: this.successGetCountUnreadMessages(res),
					error: false
				});
			});
	}
}
