import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	StaticUtilitiesService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DocumentsService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();

	constructor(private http: HttpClient) {
		super();
	}

	getSelector(behaviorSubject: BehaviorSubject<any>) {
		this.http
			.get(this.urlApi + '/api/document/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getSelector,
						args: [behaviorSubject]
					});
				}
			});
	}

	getFiles(behaviorSubject: BehaviorSubject<any>, id: string) {
		this.http
			.get(
				this.urlApi + `/api/document/${id}?page_number=1&returns_per_page=100`,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getFiles,
						args: [behaviorSubject]
					});
				}
			});
	}

	postFiles(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.post(this.urlApi + '/api/document/attach', data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.postFiles,
						args: [behaviorSubject, data]
					});
				}
			});
	}
}
