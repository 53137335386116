import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { Subject } from 'rxjs';

export class ShipingContainerClass {
	// Object to pass
	objectToPass: any = {
		id: null
	};

	// ngModels
	ngModels = {
		unitType: null,
		idCargotype: null,
		imoClass1: null,
		tempRecord: null
	};

	// numberRegex
	numberRegex = /^-?\d*([.,]\d*)?$/;

	// Selector options
	cargoTypeSelectorOptions: iOptionsSelector = {
		items: [],
		search: true,
		classList: 'specialFormSelector',
		bindLabel: 'name',
		bindValue: 'id',
		placeholder: 'Cargo type'
	};
	unitTypeSelectorOptions: iOptionsSelector = {
		items: [],
		search: false,
		classList: 'specialFormSelector',
		bindLabel: 'name',
		bindValue: 'id',
		placeholder: 'Unit'
	};
	tempRecordSelectorOptions: iOptionsSelector = {
		items: [],
		search: true,
		classList: 'specialFormSelector',
		placeholder: 'Tº record'
	};

	// Functions
	setData(container) {
		this.ngModels.idCargotype = container?.idCargotype;
		this.ngModels.imoClass1 = container?.imoClasses[0]?.imoClassName;
		this.ngModels.tempRecord = container?.tempRecord;
		this.ngModels.unitType = container?.unitType;
	}
}
