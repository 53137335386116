<div class="headerGeneralContainer">
	<div class="left">
		<div class="info">
			<span
				class="logo"
				(click)="goToHome()"
			>
				<img
					src="/assets/Icons/Header/SOLOGO.svg"
					alt="logo Valencia Cargo Expert"
				/>
				<b>VALENCIA</b>
				<p>CARGO EXPERT</p>
			</span>
			<div class="separator"></div>
			<span class="greeting">Hello, {{ name }}</span>
		</div>
		<div
			class="menu"
			*ngIf="!roles.includes('ROLE_TEACHER')"
		>
			<btn
				class="commonButton"
				tooltip
				[text]="'Notifications'"
				[delay]="0"
			>
				<ng-container>
					<img
						src="/assets/Icons/Header/notifications.svg"
						alt=""
					/>
					<!-- <div class="bubble">1</div> -->
				</ng-container>
			</btn>
			<btn
				class="commonButton"
				tooltip
				[text]="'Cases or Leads'"
				[delay]="0"
				(click)="goToCases()"
				[ngClass]="{ active: urlActive.includes('cases') }"
			>
				<ng-container>
					<img
						src="/assets/Icons/Header/casesOrLeads.svg"
						alt=""
					/>
					<!-- <div class="bubble">1</div> -->
				</ng-container>
			</btn>

			<btn
				class="commonButton"
				tooltip
				[text]="'Messages'"
				[delay]="0"
				id="message"
				(click)="goToMessages()"
				[ngClass]="{ active: urlActive.includes('messages') }"
			>
				<ng-container>
					<img
						src="/assets/Icons/Header/messages.svg"
						alt=""
					/>
					<div class="bubble">{{ bubbleUnreadMessage }}</div>
				</ng-container>
			</btn>
			<btn
				class="commonButton"
				tooltip
				[text]="'Qualifications'"
				[delay]="0"
			>
				<ng-container>
					<img
						src="/assets/Icons/Header/qualifications.svg"
						alt=""
					/>
				</ng-container>
			</btn>
			<btn
				class="commonButton"
				tooltip
				[text]="'Glossary & help'"
				[delay]="0"
			>
				<ng-container>
					<img
						src="/assets/Icons/Header/glossary.svg"
						alt=""
					/>
				</ng-container>
			</btn>
			<btn
				class="commonButton"
				tooltip
				[text]="'Search'"
				[delay]="0"
			>
				<ng-container>
					<img
						src="/assets/Icons/Header/search.svg"
						alt=""
					/>
				</ng-container>
			</btn>
		</div>
	</div>
	<div
		class="right"
		*ngIf="!roles.includes('ROLE_TEACHER')"
	>
		<btn (click)="goToNavHunter()">
			<ng-container>
				<img
					src="/assets/Icons/NavHunterHeader/navHunterIcon.png"
					alt=""
				/>
			</ng-container>
		</btn>
	</div>
</div>
