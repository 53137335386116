<div
	class="staticsticsGeneralContainer"
	[ngClass]="{ metrics: options.type == 'metrics' }"
>
	<div class="data">
		<p>
			<ng-container *ngIf="options.dataType == 'string'">
				{{ options.data }}
			</ng-container>
			<ng-container *ngIf="options.dataType == 'currency'">
				{{ options.data | currency: 'EUR' : 'EUR' }}
			</ng-container>
			<ng-container *ngIf="options.dataType == 'number'">
				{{ options.data | number }}
			</ng-container>
			<ng-container *ngIf="options.dataType == 'percentaje'">
				{{ options.data | percent }}
			</ng-container>
		</p>
	</div>

	<p class="text">{{ options.text }}</p>
</div>
