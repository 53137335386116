import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';

export interface iMainMessageList {
	messageCount: number | null;
	id: number | null;
	thread: number | null;
	subject: string;
	body: string;
	isImportant: boolean;
	wasRead: boolean;
	dateSent: string;
	profileImg: string;
	thirdName: string;
	fromNameLead: string | null;
	selfMessage: boolean;
	idLead: number | null;
	studentLead?: number | null;
}
export interface iMainMessageListFilter {
	returns_per_page: number;
	page_number: number;
	genericFilter?: string;
	total?: number;
}
export class iMainMessageList {
	static convertFromCollection(ob: any): Array<iMainMessageList> {
		let iMainMessageListCollection: Array<iMainMessageList> = [];
		ob.forEach((element: any) => {
			iMainMessageListCollection.push(this.convertFromObject(element));
		});
		return iMainMessageListCollection;
	}

	static convertFromObject(ob: any): iMainMessageList {
		let iMainMessageListObj = new iMainMessageList();
		iMainMessageListObj.messageCount = ob.messageCount;
		iMainMessageListObj.id = ob.idMsg;
		iMainMessageListObj.thread = ob.thread;
		iMainMessageListObj.subject = ob.subject;
		iMainMessageListObj.body = ob.body;
		iMainMessageListObj.isImportant = ob.isImportant;
		iMainMessageListObj.wasRead = ob.wasRead;
		iMainMessageListObj.dateSent = ob.dateSent;
		iMainMessageListObj.profileImg = ob.profileImg;
		iMainMessageListObj.thirdName = ob.thirdName;
		iMainMessageListObj.fromNameLead = ob.fromNameLead;
		iMainMessageListObj.idLead = ob.idLead;
		iMainMessageListObj.studentLead = ob.studentLead;
		iMainMessageListObj.selfMessage = iStaticUtilities.normalizeBoolean(
			ob.selfMessage
		);
		return iMainMessageListObj;
	}

	static createEmptyObject(): iMainMessageList {
		let iMainMessageListObj = new iMainMessageList();
		iMainMessageListObj.messageCount = null;
		iMainMessageListObj.id = null;
		iMainMessageListObj.thread = null;
		iMainMessageListObj.subject = '';
		iMainMessageListObj.body = '';
		iMainMessageListObj.isImportant = false;
		iMainMessageListObj.wasRead = false;
		iMainMessageListObj.dateSent = '';
		iMainMessageListObj.profileImg = '';
		iMainMessageListObj.thirdName = '';
		iMainMessageListObj.fromNameLead = '';
		iMainMessageListObj.selfMessage = false;
		iMainMessageListObj.idLead = null;
		iMainMessageListObj.studentLead = null;
		return iMainMessageListObj;
	}
}
