import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iPortSelector } from 'src/app/Shared/Interfaces/Api/Port/iPortSelector';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
	providedIn: 'root'
})
export class PortService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	private processPorts(ports: any, behaviorSubject: BehaviorSubject<any>) {
		const normalizedCountries = iPortSelector.convertFromCollection(
			iStaticUtilities.normalizeNamesCollection(ports)
		);

		this.res = new iResultHttp({
			status: 200,
			ok: true,
			message: '',
			data: normalizedCountries
		});
		this.sendNextObservable(behaviorSubject, this.res);
	}

	getSelector(behaviorSubject: BehaviorSubject<any>) {
		const portsFromStorage = LocalStorageHandlerService.getEncodedItem('ports');

		if (portsFromStorage) {
			this.processPorts(portsFromStorage, behaviorSubject);
			return;
		}

		this.http
			.get(this.urlApi + '/api/port/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					LocalStorageHandlerService.setEncodedItem('ports', data);
					this.processPorts(data, behaviorSubject);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getSelector,
						args: [behaviorSubject]
					});
				}
			});
	}
}
