import { iBaseObject } from '@quasar-dynamics/basic-designsystem';

export interface iPaymentMethod {
	id: number | null;
	methodName: string;
	holderName: string;
	iban: string;
	bic: string;
	isTemplate?: boolean;
}
export class iPaymentMethod {
	static convertFromCollection(ob: any): Array<iPaymentMethod> {
		let iPaymentMethodCollection: Array<iPaymentMethod> = [];
		ob.forEach((element: any) => {
			iPaymentMethodCollection.push(this.convertFromObject(element));
		});
		return iPaymentMethodCollection;
	}

	static convertFromObject(ob: any): iPaymentMethod {
		let iPaymentMethodObj = new iPaymentMethod();
		iPaymentMethodObj.id = ob.id;
		iPaymentMethodObj.methodName = ob.methodName;
		iPaymentMethodObj.holderName = ob.holderName;
		iPaymentMethodObj.iban = ob.iban;
		iPaymentMethodObj.bic = ob.bic;
		return iPaymentMethodObj;
	}

	static createEmptyObject(): iPaymentMethod {
		let iPaymentMethodObj = new iPaymentMethod();
		iPaymentMethodObj.id = null;
		iPaymentMethodObj.methodName = '';
		iPaymentMethodObj.holderName = '';
		iPaymentMethodObj.iban = '';
		iPaymentMethodObj.bic = '';
		return iPaymentMethodObj;
	}
}
