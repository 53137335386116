<form class="confirmPopupGeneralContainer">
	<div class="body">
		<!-- <p class="title">{{modalData.title}}</p> -->
		<p class="description">
			Please, to continue, enter the
			<b>lead number *</b>
			for which you are making the search:
		</p>
		<customInput
			name="leadNumber"
			[(ngModel)]="leadNumber"
			[options]="leadNumberOptions"
		></customInput>
		<p class="description">
			* You can find it in the initial email of the case.
		</p>
	</div>
	<div class="footer">
		<btn
			[classList]="'generalButton action green'"
			(click)="closePopup(leadNumber)"
		>
			<ng-container>
				<span>Continue</span>
			</ng-container>
		</btn>
	</div>
</form>
