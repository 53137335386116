<div
	class="containerLineBox"
	skeleton
	[isDataLoaded]="isDataLoaded"
	[id]="container.idWarecontainer"
>
	<div class="containerLineBox-topLine">
		<div class="containerImageBox">
			<img
				src="/assets/Images/Shiping/containerImage.svg"
				alt="container"
			/>
			<p
				class="containerLoad"
				*ngIf="!container.editable"
			>
				{{ container.cargoTypeName }}
			</p>
			<selector
				[options]="extraClass.cargoTypeSelectorOptions"
				[(ngModel)]="extraClass.ngModels.idCargotype"
				*ngIf="container.editable"
				(change)="
					updateSelector(extraClass.ngModels.idCargotype, 'cargoTypeId')
				"
			></selector>
		</div>

		<div class="info-topLine">
			<div class="indexNameAndButton">
				<div class="indexAndName">
					<span class="index">{{ index + 1 }}</span>
					<span class="name">{{ container.containerTypeName }}</span>
				</div>

				<div
					class="buttons"
					*ngIf="canEdit"
				>
					<btn
						*ngIf="container.editable"
						classList="generalButton editButton "
						(click)="onClickDeleteThisWareContainer(container.idWarecontainer)"
					>
						<mat-icon>delete</mat-icon>
					</btn>

					<btn
						[classList]="editButtonClasslist"
						(click)="onClickEdit(container)"
					>
						<mat-icon>edit</mat-icon>
					</btn>
				</div>
			</div>

			<div class="info">
				<div class="infoContainer">
					<div class="infoContainer-title">Plate</div>
					<div class="infoContainer-value">{{ container.plate ?? '-' }}</div>
				</div>
				<div class="infoContainer">
					<div class="infoContainer-title">Seal</div>
					<div class="infoContainer-value">{{ container.seal ?? '-' }}</div>
				</div>
				<div class="infoContainer">
					<div class="infoContainer-title">Acceptance</div>
					<div class="infoContainer-value">
						{{ container.acceptance ?? '-' }}
					</div>
				</div>
				<div class="infoContainer">
					<div class="infoContainer-title">Delivery</div>
					<div class="infoContainer-value">{{ container.delivery ?? '-' }}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="containerLineBox-otherLines">
		<p class="title">
			Load
			<sup class="asterisk--red">*</sup>
		</p>
		<span class="roundBox">
			<span
				class="selectorMinWidth"
				*ngIf="container.editable"
			>
				<selector
					[options]="extraClass.unitTypeSelectorOptions"
					[(ngModel)]="extraClass.ngModels.unitType"
					(change)="updateSelector(extraClass.ngModels.unitType, 'unitType')"
				></selector>
			</span>
			<p>{{ container.unitType }}</p>
			<p>·</p>
			<p
				class="editable"
				(input)="getEditableContent($event, 'units')"
			>
				{{ container.units }}
			</p>
		</span>

		<span class="roundBox">
			<p>Weight</p>
			<p>·</p>
			<p
				class="editable"
				(input)="getEditableContent($event, 'weight')"
			>
				{{ container.weight }}
			</p>
			<p>kg</p>
		</span>

		<span class="roundBox">
			<p>Volume</p>
			<p>·</p>
			<p
				class="editable"
				(input)="getEditableContent($event, 'volume')"
			>
				{{ container.volume }}
			</p>
			<p>
				m
				<sup>3</sup>
			</p>
		</span>
	</div>

	<div class="containerLineBox-otherLines">
		<p class="title">Special features</p>

		<span
			class="roundBox"
			(click)="switchStateButton(container, 'hasThermalBlanket')"
			[ngClass]="{ disabledButton: !container.hasThermalBlanket }"
		>
			<p>Thermal blanket</p>
		</span>

		<span
			class="roundBox"
			(click)="switchStateButton(container, 'isFlexi')"
			[ngClass]="{ disabledButton: !container.isFlexi }"
		>
			<p>Flexi</p>
		</span>

		<span
			class="roundBox"
			(click)="switchStateButton(container, 'isEmpty')"
			[ngClass]="{ disabledButton: !container.isEmpty }"
		>
			<p>Empty</p>
		</span>

		<span
			class="roundBox"
			(click)="switchStateButton(container, 'isReinforced')"
			[ngClass]="{ disabledButton: !container.isReinforced }"
		>
			<p>Reinforced</p>
		</span>
	</div>
	<ng-container *ngIf="isRefer">
		<div class="containerLineBox-otherLines">
			<p class="title">Reefer</p>

			<span class="roundBox">
				<p>Max Tº</p>
				<p>-</p>
				<p
					class="editable"
					(input)="getEditableContent($event, 'maxTemp')"
				>
					{{ container.maxTemp }}
				</p>
				<p>ºC</p>
			</span>

			<span class="roundBox">
				<p>Min Tº</p>
				<p>-</p>
				<p
					class="editable"
					(input)="getEditableContent($event, 'minTemp')"
				>
					{{ container.minTemp }}
				</p>
				<p>ºC</p>
			</span>

			<span
				class="roundBox"
				(click)="switchStateButton(container, 'hasVentilation')"
				[ngClass]="{ disabledButton: !container.hasVentilation }"
			>
				<p>Ventilation</p>
				<p>-</p>
				<p>{{ container.hasVentilation ? 'Yes' : 'No' }}</p>
			</span>

			<span class="roundBox">
				<p>Tº record</p>
				<p>-</p>
				<p *ngIf="!container.editable">{{ container.tempRecord }}</p>
				<span
					class="selectorMinWidth"
					*ngIf="container.editable"
				>
					<selector
						[options]="extraClass.tempRecordSelectorOptions"
						[(ngModel)]="extraClass.ngModels.tempRecord"
						(change)="
							updateSelector(extraClass.ngModels.tempRecord, 'tempRecord')
						"
					></selector>
				</span>
			</span>
		</div>
	</ng-container>

	<div
		class="containerLineBox-otherLines"
		*ngIf="container.hasDangerousGoods"
	>
		<p class="title">Dangerous goods</p>

		<span class="roundBox">
			<p>IMO Class</p>
			<p>-</p>
			<p>{{ extraClass.ngModels.imoClass1 }}</p>
		</span>

		<span class="roundBox">
			<p>Technical name</p>
			<p>-</p>
			<p>{{ container.technicalName }}</p>
		</span>
	</div>
</div>
