import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'pageLayout',
	templateUrl: './page-layout.component.html',
	styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
