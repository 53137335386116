import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iStudentLeadSelector } from 'src/app/Shared/Interfaces/StudenLeads/iStudentLeadSelector';

@Injectable({
	providedIn: 'root'
})
export class StudentLeadsService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getAllSelector(behaviorSubject: BehaviorSubject<any>) {
		this.http
			.get(this.urlApi + '/api/student/lead/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAllSelector,
						args: [behaviorSubject]
					});
				}
			});
	}

	getAvailableStudentLeads(behaviorSubject: BehaviorSubject<any>) {
		this.http
			.get(this.urlApi + '/api/student/lead/available', this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAvailableStudentLeads,
						args: [behaviorSubject]
					});
				}
			});
	}
}
