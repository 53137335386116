<div class="elementContainer">
	<p class="generalLabel">
		{{ content.title }}
	</p>

	<div class="tableGrid">
		<div
			[ngClass]="{ fiveColumns: content.fiveColumns }"
			class="gridLine topLine"
		>
			<p *ngFor="let item of content.headerLine">{{ item.display }}</p>
		</div>
		<div
			class="noItemsLine"
			*ngIf="content.bodyLines.length == 0"
		>
			<p>You haven’t added anything yet</p>
		</div>

		<ng-container *ngFor="let line of content.bodyLines; index as index">
			<div
				[ngClass]="{ fiveColumns: content.fiveColumns }"
				class="gridLine restOfTheLines"
				*ngIf="content.bodyLines.length > 0"
			>
				<ng-container *ngFor="let item of content.headerLine">
					<p *ngIf="item.currency">
						{{ line[item.id] | currency: 'EUR' }}
					</p>
					<p *ngIf="item.percent">
						{{ line[item.id] | percent: '1.0-1' }}
					</p>
					<p *ngIf="!item.currency && !item.percent">
						{{ line[item.id] }}
					</p>
				</ng-container>
				<span class="doubleIcon">
					<!-- <div class="icon" (click)="sendEditOutput(line, index)" *ngIf="!readOnly">
          <mat-icon>
            edit
          </mat-icon>
        </div> -->
					<div
						class="icon"
						(click)="sendDeleteOutput(line, index)"
						*ngIf="!readOnly"
					>
						<mat-icon>delete</mat-icon>
					</div>
				</span>
			</div>
		</ng-container>
	</div>

	<btn
		classList="generalButton widthFit create"
		[ngClass]="{ disabled: readOnly }"
		(click)="onClick()"
	>
		+ {{ content.buttonText }}
	</btn>
</div>
