import { Component, Input, OnInit } from '@angular/core';
import { iMensageIcon } from '../../Interfaces/Messages/iMensageIcon';

@Component({
	selector: 'messageIcon',
	templateUrl: './messageIcon.component.html',
	styleUrls: ['./messageIcon.component.scss']
})
export class MessageIconComponent implements OnInit {
	@Input() options: iMensageIcon = {
		url: '',
		title: ''
	};

	constructor() {}

	ngOnInit() {}

	openFile(url: string) {
		window.open(url, '_blank');
	}
}
