<div class="confirmPopupGeneralContainer">
	<div class="body">
		<p class="title">Add inland transportation cost</p>

		<div class="line doble">
			<div>
				<label class="generalLabel">
					Cost title concept (Denomination to print)
					<sup class="red">*</sup>
				</label>
				<selector
					[options]="
						addInlandTransportationCostPopupClass.costTitleConceptCustomSelectorOptions
					"
					[(ngModel)]="addInlandTransportationCostPopupClass.costTitleConcept"
					(change)="checkIfCompleted()"
				></selector>
			</div>
			<div class="customFormContainer">
				<label class="generalLabel">
					Calculation rule
					<sup class="red">*</sup>
				</label>
				<selector
					[(ngModel)]="addInlandTransportationCostPopupClass.calculationRule"
					[options]="
						addInlandTransportationCostPopupClass.calculationRuleSelectorOptions
					"
					(change)="checkIfCompleted()"
				></selector>
			</div>
		</div>

		<!-- Price per container and type -->
		<div
			class="line specialOverflow"
			*ngIf="addInlandTransportationCostPopupClass.calculationRule === 1"
		>
			<div
				class="line specialPricePerContainerAndType"
				*ngFor="
					let containerTypesLine of addInlandTransportationCostPopupClass.containerTypesLines;
					index as index
				"
			>
				<div class="customFormContainer">
					<customInput
						[(ngModel)]="containerTypesLine.quantity"
						[options]="
							addInlandTransportationCostPopupClass.quantityCustomInputOptions
						"
						(change)="checkIfCompleted()"
					></customInput>
				</div>
				<div class="customFormContainer">
					<label class="generalLabel">
						Container type
						<sup class="red">*</sup>
					</label>
					<selector
						[(ngModel)]="containerTypesLine.containerType"
						[options]="
							addInlandTransportationCostPopupClass.containerTypeSelectorOptions
						"
						(change)="checkIfCompleted()"
					></selector>
				</div>
				<cost-margin-price
					(calculated)="getCalculatedObject($event, index)"
				></cost-margin-price>
				<span
					class="icon"
					(click)="deleteThisLine(containerTypesLine, index)"
				>
					<mat-icon>close</mat-icon>
				</span>
			</div>
		</div>
		<div
			class="line"
			*ngIf="addInlandTransportationCostPopupClass.calculationRule === 1"
		>
			<btn
				classList="generalButton widthFit create"
				(click)="addLineToContainerTypesLinesArray()"
			>
				+ Add Container type
			</btn>
		</div>

		<!-- Price -->

		<div
			class="line"
			*ngIf="addInlandTransportationCostPopupClass.calculationRule === 2"
		>
			<cost-margin-price
				(calculated)="getCalculatedObjectPrice($event)"
			></cost-margin-price>
		</div>

		<!-- Distance-based scaling -->

		<ng-container
			*ngIf="addInlandTransportationCostPopupClass.calculationRule === 3"
		>
			<div class="line longAndShort">
				<div class="line doble">
					<customInput
						[(ngModel)]="addInlandTransportationCostPopupClass.origin"
						[options]="
							addInlandTransportationCostPopupClass.originCustomInputOptions
						"
					></customInput>
					<customInput
						[(ngModel)]="addInlandTransportationCostPopupClass.destination"
						[options]="
							addInlandTransportationCostPopupClass.destinationCustomInputOptions
						"
					></customInput>
				</div>

				<div class="line shortAndLong">
					<div class="customFormContainer">
						<label class="generalLabel">Google maps</label>
						<btn
							classList="icon generalButton create"
							(click)="goToMaps()"
						>
							<mat-icon>near_me</mat-icon>
						</btn>
					</div>

					<customInput
						[(ngModel)]="addInlandTransportationCostPopupClass.targetKm"
						[options]="
							addInlandTransportationCostPopupClass.distanceCustomInputoptions
						"
					></customInput>
				</div>
			</div>

			<div class="line specialOverflow">
				<div
					class="line shortAndLong"
					*ngFor="
						let scalingLine of addInlandTransportationCostPopupClass.scalingLines;
						index as index
					"
				>
					<customInput
						[(ngModel)]="scalingLine.upToKm"
						[options]="
							addInlandTransportationCostPopupClass.upToKmCustomInputOptions
						"
					></customInput>
					<div class="line longAndIcon">
						<cost-margin-price
							(calculated)="calculatedScaling($event, scalingLine)"
						></cost-margin-price>
						<span
							class="icon"
							(click)="removeThisLineScalingLines(index)"
						>
							<mat-icon>close</mat-icon>
						</span>
					</div>
				</div>
			</div>

			<div class="line shortAndLong">
				<btn
					style="align-self: flex-start"
					classList="generalButton widthFit create"
					(click)="addLineToScalingLines()"
				>
					+ Add Scaling
				</btn>

				<div class="pricePerKmAboveContainer">
					<p class="generalLabel">Price per km above the scaling</p>
					<cost-margin-price
						(calculated)="getCalculatedPricePerKmAboveContainer($event)"
					></cost-margin-price>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="footer">
		<btn
			[classList]="'generalButton action red'"
			(click)="closePopup('noreturn')"
		>
			<ng-container>
				<span>Discard</span>
			</ng-container>
		</btn>
		<btn
			[classList]="'generalButton action green'"
			[ngClass]="{ disabled: !addInlandTransportationCostPopupClass.isChecked }"
			(click)="generateReturnDataAndClosePopup()"
		>
			<ng-container>
				<span>Add cost</span>
			</ng-container>
		</btn>
	</div>
</div>
