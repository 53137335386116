<div class="scrollNavigatorElementsGeneralContainer">
	<ng-container *ngFor="let scrollNavigatorElement of scrollNavigatorElements">
		<span
			class="navSpan"
			[ngClass]="{
				active:
					selectedNavigatorElement.anchor === scrollNavigatorElement.anchor
			}"
			(click)="
				selectedNavigatorElement = scrollNavigatorElement;
				goToAnchorSelected($event)
			"
		>
			{{ scrollNavigatorElement.text }}
		</span>
	</ng-container>
</div>
