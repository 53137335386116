import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'blue-container',
	templateUrl: './blue-container.component.html',
	styleUrls: ['./blue-container.component.scss']
})
export class BlueContainerComponent implements OnInit {
	@Output() add: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() canAdd: boolean = false;
	@Input() title: string = '';

	constructor() {}

	ngOnInit() {}

	/**
	 * FUNCTIONALITIES
	 */

	onClickAddButton() {
		this.add.emit(true);
	}
}
