import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';

export class AddFreightPopup {
	// ngModel
	calculateObject: {
		cost: number | null;
		margin: number | null;
		price: number | null;
	} = {
		cost: null,
		margin: null,
		price: null
	};

	isChecked: boolean = false;

	origin: any = null;
	destination: any = null;
	transshipment: any = null;
	bodyToReturn: any = null;

	// Selector Options
	portOfOriginSelectorOptions: iOptionsSelector = {
		placeholder: 'Port of Origin',
		items: [],
		append: true,
		classList: 'specialFormSelector',
		bindLabel: 'name',
		search: true
	};
	portOfDestinationSelectorOptions: iOptionsSelector = {
		placeholder: 'Port of Destination',
		items: [],
		append: true,
		classList: 'specialFormSelector',
		bindLabel: 'name',
		search: true
	};
	transshipmentSelectorOptions: iOptionsSelector = {
		placeholder: 'Transshipment Port',
		items: [],
		append: true,
		classList: 'specialFormSelector',
		bindLabel: 'name',
		search: true
	};

	rellenarBodyToReturn() {
		//convertir los costes en number decimal
		this.calculateObject.cost = Number(this.calculateObject.cost);
		this.calculateObject.margin = Number(this.calculateObject.margin);
		this.calculateObject.price = Number(this.calculateObject.price);

		//convertir el margen en porcentaje
		this.calculateObject.margin = this.calculateObject.margin / 100;
		if (this.transshipment === null) {
			this.bodyToReturn = {
				portOfOrigin: this.origin.name,
				originId: this.origin.id,
				portOfDestination: this.destination.name,
				destinationId: this.destination.id,
				cost: this.calculateObject.cost,
				margin: this.calculateObject.margin,
				amount: this.calculateObject.price,
				costConcept: 'O.F.: Ocean Freight'
			};
		} else {
			this.bodyToReturn = {
				portOfOrigin: this.origin.name,
				originId: this.origin.id,
				portOfDestination: this.destination.name,
				destinationId: this.destination.id,
				transshipment: this.transshipment.name,
				transshipmentId: this.transshipment.id,
				cost: this.calculateObject.cost,
				margin: this.calculateObject.margin,
				amount: this.calculateObject.price,
				costConcept: 'O.F.: Ocean Freight'
			};
		}
	}
}
