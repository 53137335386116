import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	AnimationControllerService,
	DefaultBaseService,
	StaticUtilitiesService,
	TokenManagmentService,
	iResultHttp,
	iStaticUtilities,
	sha256,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { ILogin } from 'src/app/Shared/Interfaces/Api/iLogin';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoginService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	private roles: Array<any> = [
		sha256('ROLE_USER'),
		sha256('ROLE_ADMIN'),
		sha256('ROLE_STUDENT'),
		sha256('ROLE_TEACHER')
	];
	constructor(
		private http: HttpClient,
		private router: Router,
		private animationSE: AnimationControllerService
	) {
		super();
	}

	logOut() {
		localStorage.clear();
		sessionStorage.clear();
	}
	isLogged() {
		if (this.getToken() && this.getRefreshToken()) {
			return true;
		}
		return false;
	}
	redirectToRol() {
		const userTypeToken = this.getUserTypeToken();
		const roles = {
			ROLE_STUDENT: '/home',
			ROLE_TEACHER: '/teacher'
		};

		for (const role in roles) {
			if (userTypeToken.includes(sha256(role))) {
				this.router.navigate([roles[role]]);
				this.animationSE.setCurrentAnimation(slideNavigationTypes.slideToTop);
				StaticUtilitiesService.showFeedback('Successfully logged in');
				localStorage.setItem('role', sha256(role)!);
				break;
			}
		}
	}
	getUserTypeToken() {
		let roles: Array<any> = TokenManagmentService.getParseToken(
			localStorage.getItem('token')
		).roles;
		roles = roles.map((role: any) => {
			{
				return sha256(role);
			}
		});
		return roles;
	}

	static getUserPermissionsAndRoles() {
		let roles = TokenManagmentService.getParseToken(
			localStorage.getItem('token')
		).roles;
		return roles;
	}

	login(behaviorSubject: BehaviorSubject<any>, loginData: ILogin) {
		this.http.post(this.urlApi + '/login/user', loginData).subscribe({
			next: (data: any) => {
				const normalizedTokens = iStaticUtilities.normalizeNames(data);
				this.res = new iResultHttp({
					status: 200,
					ok: true,
					message: '',
					data: normalizedTokens
				});
				this.setToken(normalizedTokens['token']);
				this.setRefreshToken(normalizedTokens['refreshToken']);
				this.sendNextObservable(behaviorSubject, this.res);
			},
			error: (error: any) => {
				this.res = new iResultHttp({
					status: error.status,
					ok: false,
					message: error.error.message
				});
				this.sendNextObservable(behaviorSubject, this.res, true, {
					method: this.login,
					args: [behaviorSubject]
				});
			}
		});
	}

	refreshToken() {
		this.http
			.post(environment.urlApi + '/api/token/refresh', {
				refresh_token: localStorage.getItem('refreshToken')
			})
			.subscribe({
				next: (data) => {
					let normalizedTokens = iStaticUtilities.normalizeNames(data);
					this.setToken(normalizedTokens['token']);
					this.setRefreshToken(normalizedTokens['refreshToken']);
					TokenManagmentService.completeRefreshToken();
				},
				error: (error) => {
					TokenManagmentService.tokenLost();
				}
			});
	}

	register(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http.post(this.urlApi + '/student', data, this.getHeader()).subscribe({
			next: (data: any) => {
				this.res = new iResultHttp({
					status: 200,
					ok: true,
					message: '',
					data: null
				});
				this.sendNextObservable(behaviorSubject, this.res);
			},
			error: (error: any) => {
				this.res = new iResultHttp({
					status: error.status,
					ok: false,
					message: error.error.message
				});
				this.sendNextObservable(behaviorSubject, this.res, true, {
					method: this.register,
					args: [behaviorSubject, data]
				});
			}
		});
	}

	getTeacherSelector(behaviorSubject: BehaviorSubject<any>) {
		this.http
			.get(this.urlApi + '/teacher/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getTeacherSelector,
						args: [behaviorSubject]
					});
				}
			});
	}
}
