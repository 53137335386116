import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2
} from '@angular/core';
import { iTableIconOptions } from '../../Interfaces/Utils/iTableIconOptions';

@Component({
	selector: 'table-icon',
	templateUrl: './table-icon.component.html',
	styleUrls: ['./table-icon.component.scss']
})
export class TableIconComponent implements OnInit {
	@Output() onClick: EventEmitter<any> = new EventEmitter<any>();
	@Input() options: iTableIconOptions = {
		image: ''
	};

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {}

	clicked(element: EventTarget | null) {
		this.renderer.addClass(element, 'clicked');
		this.onClick.emit(true);
		setTimeout(() => {
			this.renderer.removeClass(element, 'clicked');
		}, 200);
	}
}
