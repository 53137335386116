<div
	class="offerOverview"
	skeleton
>
	<div class="offerOverview-header">
		<p class="offerOverview-title">Offer overview</p>
		<span
			(click)="seeDetails()"
			[ngClass]="{ disabled: !isDraft }"
		>
			<img
				src="/assets/Icons/Commercial-offers/eyeIcon.svg"
				alt="overview"
			/>
		</span>
	</div>
	<div class="offerOverview-infoContainer">
		<div class="offerOverview-info">
			<div class="offerOverview-datesAndPorts">
				<div class="dateAndPort">
					<p class="date">{{ overviewData.shippingDate | date }}</p>
					<p class="port">{{ overviewData.shippingPort }}</p>
				</div>
				<div class="dateAndPort">
					<p class="date">{{ overviewData.destinationDate | date }}</p>
					<p class="port">{{ overviewData.destinationPort }}</p>
				</div>
			</div>
			<div class="offerOverview-shippingLines">
				<div class="shippingLine">
					<img
						src="/assets/Icons/Commercial-offers/ship.svg"
						alt="shipIcon"
					/>
					<p class="shippingName">{{ overviewData.shippingLine }}</p>
				</div>
			</div>
		</div>
	</div>
	<div class="offerOverview-priceContainer">
		<!-- Freight -->
		<div class="priceBlock">
			<div class="priceLine">
				<p>Freight</p>
				<p>{{ overviewData.freightPrice | currency: 'EUR' }}</p>
			</div>
		</div>

		<!-- Freight subcharges -->

		<div class="priceBlock">
			<div class="priceLine">
				<p>Freight subcharges</p>
				<p>{{ overviewData.freightSubchargesPrice | currency: 'EUR' }}</p>
			</div>
			<div
				class="priceLine"
				*ngFor="let subcharge of overviewData.freightSubcharges"
			>
				<p>{{ subcharge.name }}</p>
				<p>{{ subcharge.price | currency: 'EUR' }}</p>
			</div>
		</div>

		<!-- Customs -->

		<div class="priceBlock">
			<div class="priceLine">
				<p>Customs</p>
				<p>{{ overviewData.customsPrice | currency: 'EUR' }}</p>
			</div>
			<div
				class="priceLine"
				*ngFor="let custom of overviewData.customs"
			>
				<p>{{ custom.name }}</p>
				<p>{{ custom.price | currency: 'EUR' }}</p>
			</div>
		</div>

		<!-- Inland transportation -->

		<div class="priceBlock">
			<div class="priceLine">
				<p>Inland transportation</p>
				<p>{{ overviewData.inlandTransportationPrice | currency: 'EUR' }}</p>
			</div>
			<div
				class="priceLine"
				*ngFor="let inlandTransportation of overviewData.inlandTransportation"
			>
				<p>{{ inlandTransportation.name }}</p>
				<p>{{ inlandTransportation.price | currency: 'EUR' }}</p>
			</div>
		</div>

		<!-- Other costs -->

		<div class="priceBlock">
			<div class="priceLine">
				<p>Other costs</p>
				<p>{{ overviewData.otherCostsPrice | currency: 'EUR' }}</p>
			</div>
			<div
				class="priceLine"
				*ngFor="let otherCosts of overviewData.otherCosts"
			>
				<p>{{ otherCosts.name }}</p>
				<p>{{ otherCosts.price | currency: 'EUR' }}</p>
			</div>
		</div>

		<div class="priceBlock">
			<div class="priceLine">
				<p>Cargo insurance</p>
				<p>{{ overviewData.insuranceCost | currency: 'EUR' }}</p>
			</div>
		</div>
	</div>
	<div class="offerOverview-total">
		<p>Total</p>
		<p>{{ overviewData.getTotal() | currency: 'EUR' }}</p>
	</div>
	<div class="offerOverview-buttons">
		<btn
			classList="generalButton action bigPadding opositRed"
			[ngClass]="{ disabled: !isDraft }"
			(click)="discardOffer()"
		>
			Discard
		</btn>
		<btn
			classList="generalButton action bigPadding green"
			[ngClass]="{ disabled: !isDraft }"
			(click)="completeOffer()"
		>
			Complete offer
		</btn>
	</div>
</div>
