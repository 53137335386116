import { NgModule } from '@angular/core';
import { CustomHeaderComponent } from './Layout/custom-header/custom-header.component';
import { CommonModule } from '@angular/common';
import { QuasarLibraryModule } from '@quasar-dynamics/basic-designsystem';
import { PageLayoutComponent } from './Layout/page-layout/page-layout.component';
import { SidebarComponent } from './Layout/sidebar/sidebar.component';
import { CaseEmojiComponent } from './Components/Case/case-emoji/case-emoji.component';
import { CaseCardComponent } from './Components/Case/case-card/case-card.component';
import { UserPillComponent } from './Components/user-pill/user-pill.component';
import { ProgressGreadientBarComponent } from './Components/progress-greadient-bar/progress-greadient-bar.component';
import { StatisticsPillsComponent } from './Components/statistics-pills/statistics-pills.component';
import { SkeletonDirective } from './Directives/Skeleton.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterSortColumnsComponent } from './Components/Filters/filter-sort-columns/filter-sort-columns.component';
import { MatIconModule } from '@angular/material/icon';
import { TableIconComponent } from './Components/table-icon/table-icon.component';
import { ConfirmPopupComponent } from '../Popups/confirm-popup/confirm-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OfferOverviewComponent } from './Components/offer-overview/offer-overview.component';
import { ScrollNavigatorComponent } from './Components/scroll-navigator/scroll-navigator.component';
import { NavHunterHeaderComponent } from './Layout/nav-hunter-header/nav-hunter-header.component';
import { TransformColumnNamePipe } from './Pipes/transformColumnName.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { NavHunterOptionIconComponent } from './Components/Nav-Hunter/nav-hunter-option-icon/nav-hunter-option-icon.component';
import { NavHunterSearchLineComponent } from './Components/Nav-Hunter/nav-hunter-search-line/nav-hunter-search-line.component';
import { NavHunterSubHeaderComponent } from './Components/Nav-Hunter/nav-hunter-sub-header/nav-hunter-sub-header.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CommercialOfferTableContainerComponent } from './Components/commercial-offer-table-container/commercial-offer-table-container.component';
import { CostMarginPriceComponent } from './Components/cost-margin-price/cost-margin-price.component';
import { ShipingTopLineComponent } from './Components/Shiping/shiping-top-line/shiping-top-line.component';
import { ShipingSecondLineComponent } from './Components/Shiping/shiping-second-line/shiping-second-line.component';
import { ToggleBtnsComponent } from './Components/toggle-btns/toggle-btns.component';
import { FilesAreaComponent } from './Components/files-area/files-area.component';
import { FormattedSelectorComponent } from './Components/formatted-selector/formatted-selector.component';
import { ShipingContainerComponent } from './Components/Shiping/shiping-container/shiping-container.component';
import { ToggleWithTextComponent } from './Components/toggle-with-text/toggle-with-text.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BlueContainerComponent } from './Components/blue-container/blue-container.component';
import { StepControllerComponent } from './Components/step-controller/step-controller.component';
import { ThirdPartySelectorComponent } from './Components/Shiping/third-party-selector/third-party-selector.component';
import { ThreadMessageListComponent } from './Components/thread-message-list/thread-message-list.component';
import { MessageIconComponent } from './Components/messageIcon/messageIcon.component';
import { MessageNotificationComponent } from './Components/message-notification/message-notification.component';
import { NavHunterInTransitComponent } from './Components/Nav-Hunter/nav-hunter-in-transit/nav-hunter-in-transit.component';
import { FeedbackShowerComponent } from './Components/feedback-shower/feedback-shower.component';

@NgModule({
	imports: [
		CommonModule,
		QuasarLibraryModule,
		DragDropModule,
		MatIconModule,
		MatCheckboxModule,
		FormsModule,
		MatIconModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSlideToggleModule
	],
	declarations: [
		CustomHeaderComponent,
		PageLayoutComponent,
		SidebarComponent,
		CaseEmojiComponent,
		UserPillComponent,
		ProgressGreadientBarComponent,
		StatisticsPillsComponent,
		CaseCardComponent,
		SkeletonDirective,
		FilterSortColumnsComponent,
		TableIconComponent,
		ConfirmPopupComponent,
		OfferOverviewComponent,
		ScrollNavigatorComponent,
		NavHunterHeaderComponent,
		NavHunterOptionIconComponent,
		NavHunterSearchLineComponent,
		NavHunterSubHeaderComponent,
		CommercialOfferTableContainerComponent,
		CostMarginPriceComponent,
		TransformColumnNamePipe,
		ShipingTopLineComponent,
		ShipingSecondLineComponent,
		ToggleBtnsComponent,
		FilesAreaComponent,
		FormattedSelectorComponent,
		ShipingContainerComponent,
		ToggleWithTextComponent,
		BlueContainerComponent,
		StepControllerComponent,
		ThirdPartySelectorComponent,
		MessageIconComponent,
		MessageNotificationComponent,
		NavHunterInTransitComponent,
		FeedbackShowerComponent
	],
	exports: [
		ThirdPartySelectorComponent,
		StepControllerComponent,
		BlueContainerComponent,
		TableIconComponent,
		CustomHeaderComponent,
		PageLayoutComponent,
		SidebarComponent,
		CaseEmojiComponent,
		UserPillComponent,
		ProgressGreadientBarComponent,
		StatisticsPillsComponent,
		CaseCardComponent,
		SkeletonDirective,
		FilterSortColumnsComponent,
		ConfirmPopupComponent,
		OfferOverviewComponent,
		ScrollNavigatorComponent,
		NavHunterHeaderComponent,
		FormsModule,
		NavHunterOptionIconComponent,
		NavHunterSearchLineComponent,
		NavHunterSubHeaderComponent,
		CommercialOfferTableContainerComponent,
		CostMarginPriceComponent,
		TransformColumnNamePipe,
		ShipingTopLineComponent,
		ShipingSecondLineComponent,
		ToggleBtnsComponent,
		FilesAreaComponent,
		FormattedSelectorComponent,
		ShipingContainerComponent,
		ToggleWithTextComponent,
		MessageIconComponent,
		MessageNotificationComponent,
		NavHunterInTransitComponent,
		FeedbackShowerComponent
	]
})
export class SharedModule {}
