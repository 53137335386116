import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	StaticUtilitiesService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CostConceptService } from 'src/app/Services/Api/cost-concept.service';
import { CalculateCostMarginPriceService } from 'src/app/Services/Utils/CalculateCostMarginPrice.service';
import { AddCostPopupClass } from 'src/app/Shared/Class/commercial-offers/addCostPopupClass';
import { iCalculateCostMarginPriceObject } from 'src/app/Shared/Interfaces/Utils/iCalculateCostMarginPriceObject';

@Component({
	selector: 'app-addCost-popup',
	templateUrl: './addCost-popup.component.html',
	styleUrls: ['./addCost-popup.component.scss']
})
export class AddCostPopupComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.closePopup('noreturn');
		}
	}

	addCostPopupClass: AddCostPopupClass = new AddCostPopupClass();

	constructor(
		public dialogRef: MatDialogRef<AddCostPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any,
		private costSE: CostConceptService,
		private calculateCostMarginPriceSE: CalculateCostMarginPriceService
	) {}

	ngOnInit() {
		this.getCostConcept();
	}

	/**
	 * HANDLERS
	 */

	saveCostConcept(res: iResultHttp) {
		let { data } = res;
		this.addCostPopupClass.costConceptSelectorOptions.items = data;
	}

	getCalculatedObject(event: iCalculateCostMarginPriceObject) {
		this.addCostPopupClass.calculateObject = event;
		this.checkIfCompleted();
	}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}
	generateBodyToReturn() {
		this.addCostPopupClass.rellenarBodyToReturn();
		let body = this.addCostPopupClass.bodyToReturn;
		this.closePopup(body);
	}

	checkIfCompleted() {
		const objectToCheck: any = {
			cost: this.addCostPopupClass.calculateObject.cost,
			amount: this.addCostPopupClass.calculateObject.price,
			concept: this.addCostPopupClass.costConcept
		};

		this.addCostPopupClass.isChecked =
			StaticUtilitiesService.isCompleteObject(objectToCheck);
	}

	/**
	 * API CALLS
	 */

	getCostConcept() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.costSE.getSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: this.saveCostConcept(res)
				});
			});
	}
}
