<div class="blueContentBox">
	<div class="blueBoxTitle">
		<p>{{ title }}</p>
		<div
			class="addButton"
			*ngIf="canAdd"
			(click)="onClickAddButton()"
		>
			<span class="iconBox">
				<mat-icon>add</mat-icon>
			</span>
		</div>
	</div>
	<div class="content">
		<ng-content></ng-content>
	</div>
</div>
