import { iBaseObject } from '@quasar-dynamics/basic-designsystem';

export interface iCountry extends iBaseObject {
	id: number | null;
	name: string;
}

export class iCountry extends iBaseObject {
	static convertFromCollection(ob: any): Array<iCountry> {
		let iCountryCollection: Array<iCountry> = [];
		ob.forEach((element: any) => {
			iCountryCollection.push(this.convertFromObject(element));
		});
		return iCountryCollection;
	}

	static convertFromObject(ob: any): iCountry {
		let iCountryObj = new iCountry();
		iCountryObj.id = ob.id;
		iCountryObj.name = ob.name;
		return iCountryObj;
	}

	static createEmptyObject(): iCountry {
		let iCountryObj = new iCountry();
		iCountryObj.id = null;
		iCountryObj.name = '';
		return iCountryObj;
	}
}
