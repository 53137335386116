import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'toggle-btns',
	templateUrl: './toggle-btns.component.html',
	styleUrls: ['./toggle-btns.component.scss']
})
export class ToggleBtnsComponent implements OnInit {
	@Input() labels: string[] = [];
	@Output() selectedOptionChange: EventEmitter<number> =
		new EventEmitter<number>();

	selectedOption: number = 0;

	constructor() {}

	ngOnInit(): void {}

	sendOutput(selectedOption: number) {
		this.selectedOptionChange.emit(selectedOption);
	}
}
