import { iBaseObject } from '@quasar-dynamics/basic-designsystem';

export interface iPortSelector extends iBaseObject {
	id: number | null;
	name: string;
}

export class iPortSelector extends iBaseObject {
	static convertFromCollection(ob: any): Array<iPortSelector> {
		let iPortSelectorCollection: Array<iPortSelector> = [];
		ob.forEach((element: any) => {
			iPortSelectorCollection.push(this.convertFromObject(element));
		});
		return iPortSelectorCollection;
	}

	static convertFromObject(ob: any): iPortSelector {
		let iPortSelectorObj = new iPortSelector();
		iPortSelectorObj.id = ob.id;
		iPortSelectorObj.name = ob.name;
		return iPortSelectorObj;
	}

	static createEmptyObject(): iPortSelector {
		let iPortSelectorObj = new iPortSelector();
		iPortSelectorObj.id = null;
		iPortSelectorObj.name = '';
		return iPortSelectorObj;
	}
}
