<div class="iconBlock">
	<span
		class="icon"
		[ngStyle]="{ 'background-color': background }"
	>
		<img
			src="{{ imageToDisplay }}"
			alt="Icon"
		/>
	</span>
	<div
		class="iconText"
		*ngIf="characteristics?.extraInfo"
	>
		<p>{{ title }}</p>
		<ng-container *ngIf="characteristics.option === 'Closer'">
			<p>{{ characteristics.extraInfo | date: 'MMM d' }}</p>
		</ng-container>
		<ng-container *ngIf="characteristics.option !== 'Closer'">
			<p>{{ characteristics.extraInfo }}</p>
		</ng-container>
	</div>
</div>
