import { Injectable } from '@angular/core';
import { LoaderService } from '@quasar-dynamics/basic-designsystem';

@Injectable({
	providedIn: 'root'
})

/**
 * GeneralLoaderService is a service that manages the state of a loader component.
 */
export class GeneralLoaderService {
	// The number of loader instances before the current one.
	private previousLoaderAmount: number = 0;

	// The current number of loader instances.
	private currentLoaderAmount: number = 0;

	// The loader component to be managed by this service.
	private loaderComponent: any = null;

	constructor(private loaderSE: LoaderService) {}

	/**
	 * Increases the count of loader instances and opens the loader if it's the first instance.
	 */
	public addToLoaderAmount() {
		this.currentLoaderAmount++;
		if (this.currentLoaderAmount === 1 && this.previousLoaderAmount === 0) {
			this.loaderSE.open(this.loaderComponent);
		}
		this.previousLoaderAmount = this.currentLoaderAmount;
	}

	/**
	 * Decreases the count of loader instances and closes the loader if there are no more instances.
	 */
	public removeFromLoaderAmount() {
		this.currentLoaderAmount--;
		if (this.currentLoaderAmount === 0 && this.previousLoaderAmount === 1) {
			this.loaderSE.close();
		}
		this.previousLoaderAmount = this.currentLoaderAmount;
	}

	/**
	 * Sets the loader component to be managed by this service.
	 * @param {any} loaderComponent - The loader component to be managed.
	 */
	public setLoaderComponent(loaderComponent: any) {
		this.loaderComponent = loaderComponent;
	}
}

/**
 * The best practice is to set the loaderComponent if needed in the ngOnInit of the app component, if there is going to be just one loader component in the app.
 * If there are multiple loader components, it is best to set the loaderComponent in the ngOnInit of the component that contains the loader component.
 */
