import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges
} from '@angular/core';
import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';
import { ResponseMessageBridgeService } from 'src/app/Services/Utils/responseMessageBridge.service';
import { iMainMessageList } from '../../Interfaces/Messages/iMainMessageList';
import { Messages } from '../../Class/messages';

@Component({
	selector: 'threadMessageList',
	templateUrl: './thread-message-list.component.html',
	styleUrls: ['./thread-message-list.component.scss']
})
export class ThreadMessageListComponent implements OnInit, OnChanges {
	pos1 = 0;
	pos2 = 0;
	pos3 = 0;
	pos4 = 0;
	mouseDownTime: number = 0;
	mouseUpTime: number = 0;
	minimiceWindow: boolean = false;
	elementHeader: HTMLElement | null = null;
	generalElement: HTMLElement | null = null;
	messages: Array<any> = [];
	messageClass = new Messages();
	name: string = localStorage.getItem('name')!;
	@Input() threads: Array<number> = [];
	constructor(
		private responseMessageBridgeSE: ResponseMessageBridgeService,
		private renderer: Renderer2,
		private elementRef: ElementRef
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['threads'] && changes['threads'].currentValue) {
			this.dataTreatmentHandler();
		}
	}

	ngOnInit(): void {
		this.minimiceWindow = iStaticUtilities.normalizeBoolean(
			sessionStorage.getItem('minimicedMessageList')
		);
		this.elementHeader = document.querySelector(
			'.threadMessageListGeneralContainer .header'
		) as HTMLElement;
		this.generalElement = document.querySelector(
			'.threadMessageListGeneralContainer'
		) as HTMLElement;

		setTimeout(() => {
			this.dragElement();
		}, 500);
	}

	/**
	 * FUNCTIONALITIES
	 */

	dragElement() {
		this.elementHeader?.addEventListener('mousedown', this.dragMouseDown);
	}

	dragMouseDown = (e: MouseEvent) => {
		e.preventDefault();
		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		document.addEventListener('mouseup', this.closeDragElement);
		document.addEventListener('mousemove', this.elementDrag);
	};

	elementDrag = (e: MouseEvent) => {
		e.preventDefault();

		if (this.generalElement) {
			this.pos1 = this.pos3 - e.clientX;
			this.pos2 = this.pos4 - e.clientY;
			this.pos3 = e.clientX;
			this.pos4 = e.clientY;
			this.generalElement.style.top =
				this.generalElement.offsetTop - this.pos2 + 'px';
			this.generalElement.style.left =
				this.generalElement.offsetLeft - this.pos1 + 'px';
		}
	};

	closeDragElement = () => {
		document.removeEventListener('mouseup', this.closeDragElement);
		document.removeEventListener('mousemove', this.elementDrag);
	};
	closeResponseMessage() {
		this.responseMessageBridgeSE.toggleOpenThreadMessageList(false);
		localStorage.removeItem('threadMessageList');
	}
	toggleMinimiceWindow() {
		this.minimiceWindow = !this.minimiceWindow;
		localStorage.setItem('minimicedMessageList', String(this.minimiceWindow));
	}
	toggleOpenedThreadMessages(threadMessage: any) {
		if (!this.messageClass.threadMessagesSelected.includes(threadMessage.id)) {
			this.messageClass.threadMessagesSelected.push(threadMessage.id);
		} else {
			this.messageClass.threadMessagesSelected.splice(
				this.messageClass.threadMessagesSelected.indexOf(threadMessage.id),
				1
			);
		}
	}
	openFile(file: any) {
		window.open(file.url, '_blank');
	}
	closeThread(arg) {
		const indexOfThread = this.messages.indexOf(arg);
		const threadId = this.threads[indexOfThread];
		let parsedListMessages: Array<iMainMessageList> = JSON.parse(
			localStorage.getItem('threadMessageList')!
		);
		parsedListMessages = parsedListMessages.filter(
			(message) => message.thread != threadId
		);
		localStorage.setItem(
			'threadMessageList',
			JSON.stringify(parsedListMessages)
		);
		this.messages.splice(indexOfThread, 1);
		if (this.messages.length == 0) {
			this.closeResponseMessage();
			return;
		}
	}
	insertACloseButtonOnLabel() {
		const labelContainers: NodeListOf<Element> =
			document.querySelectorAll('.mat-tab-label');
		labelContainers.forEach((element: Element, index: number) => {
			this.checkIfMiddleButtonIfSoCloseTab(element, index);
			const closeButton = this.renderer.createElement('span');
			this.renderer.addClass(closeButton, 'closeButton');
			const pInsideButton = this.renderer.createElement('p');
			this.renderer.appendChild(pInsideButton, this.renderer.createText('x'));
			this.renderer.appendChild(closeButton, pInsideButton);
			this.renderer.appendChild(element, closeButton);
			closeButton.addEventListener('click', () =>
				this.closeThread(this.messages[index])
			);
		});
	}

	checkIfMiddleButtonIfSoCloseTab(element, index) {
		element.addEventListener('mousedown', (event: Event) => {
			if ((event as MouseEvent).button === 1) {
				this.closeThread(this.messages[index]);
			}
		});
	}

	/**
	 * HANDLERS
	 */

	dataTreatmentHandler() {
		this.messages = [];
		const parsedListMessages: Array<iMainMessageList> = JSON.parse(
			localStorage.getItem('threadMessageList')!
		);
		this.threads.forEach((element) => {
			const obj: any = {
				name:
					parsedListMessages.filter((message) => message.thread == element)[0]
						.thirdName +
					' - ' +
					parsedListMessages.filter((message) => message.thread == element)[0]
						.subject,
				data: parsedListMessages.filter((message) => message.thread == element)
			};
			this.messages.push(obj);
		});
		setTimeout(() => {
			this.insertACloseButtonOnLabel();
		}, 1);
	}
}
