import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import {
	iMainMessageList,
	iMainMessageListFilter
} from 'src/app/Shared/Interfaces/Messages/iMainMessageList';
import { iNewMessage } from 'src/app/Shared/Interfaces/Messages/iNewMessage';
import { iReplyMessage } from 'src/app/Shared/Interfaces/Messages/iReplyMessage';
import {
	iThreadMessage,
	iThreadMessageFilter
} from 'src/app/Shared/Interfaces/Messages/iThreadMessage';

@Injectable({
	providedIn: 'root'
})
export class MessageService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getAllByStudent(
		behaviorSubject: BehaviorSubject<any>,
		filter: iMainMessageListFilter
	) {
		this.http
			.get(
				this.urlApi + '/api/tmsl' + this.optionsGet(filter),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: {
							total: data.total,
							data: iMainMessageList.convertFromCollection(
								iStaticUtilities.normalizeNamesCollection(data.data)
							)
						}
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAllByStudent,
						args: [behaviorSubject]
					});
				}
			});
	}
	templateSelectorForStudent(
		behaviorSubject: BehaviorSubject<any>,
		caseId: any
	) {
		this.http
			.get(
				this.urlApi + '/api/template/student/selector/' + caseId,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.templateSelectorForStudent,
						args: [behaviorSubject, caseId]
					});
				}
			});
	}
	getAddresForMessage(
		behaviorSubject: BehaviorSubject<any>,
		addressId: any,
		studentLead: { studentLead: number }
	) {
		this.http
			.get(
				this.urlApi +
					'/api/template/student/message/' +
					addressId +
					this.optionsGet(studentLead),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNames(data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getAddresForMessage,
						args: [behaviorSubject, addressId]
					});
				}
			});
	}

	getCountUnreadMessages(behaviorSubject: BehaviorSubject<any>) {
		this.http
			.get(this.urlApi + '/api/tmsl/unread', this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getCountUnreadMessages,
						args: [behaviorSubject]
					});
				}
			});
	}
	getAllMessageByThread(
		behaviorSubject: BehaviorSubject<any>,
		id: number,
		filter: iThreadMessageFilter
	) {
		this.http
			.get(
				this.urlApi + '/api/tmsl/thread/' + id + this.optionsGet(filter),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iThreadMessage.convertFromCollection(
							iStaticUtilities.normalizeNamesCollection(data.data)
						)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getCountUnreadMessages,
						args: [behaviorSubject]
					});
				}
			});
	}
	create(behaviorSubject: BehaviorSubject<any>, newMessage: iNewMessage) {
		this.http
			.post(this.urlApi + '/api/tmsl', newMessage, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: data.message,
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.create,
						args: [behaviorSubject, newMessage]
					});
				}
			});
	}
	response(
		behaviorSubject: BehaviorSubject<any>,
		newMessage: iReplyMessage | any
	) {
		this.http
			.post(this.urlApi + '/api/tmsl/reply', newMessage, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: data.message,
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.response,
						args: [behaviorSubject, newMessage]
					});
				}
			});
	}
	setReadMessage(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.put(this.urlApi + '/api/tmsl/read/' + id, {}, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.setReadMessage,
						args: [behaviorSubject, id]
					});
				}
			});
	}
	toggleImportant(
		behaviorSubject: BehaviorSubject<any>,
		data: { tmslId: number }
	) {
		this.http
			.put(this.urlApi + '/api/tmsl/toggle/important', data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.setReadMessage,
						args: [behaviorSubject, data]
					});
				}
			});
	}
}
