export interface iOfferOverview {
	serialNumber: string;
	shippingDate: string;
	shippingPort: string;
	destinationPort: string;
	destinationDate: string;
	freightPrice: number;
	freightSubchargesPrice: number;
	shippingLine: string;
	freightSubcharges: any[];
	customsPrice: number;
	customs: any[];
	inlandTransportationPrice: number;
	inlandTransportation: any[];
	otherCostsPrice: number;
	otherCosts: any[];
	insuranceCost: number;
}
// crear el constructor de la clase
export class iOfferOverview {
	static convertFromCollection(ob: any): Array<iOfferOverview> {
		let iOfferOverviewCollection: Array<iOfferOverview> = [];
		ob.forEach((element: any) => {
			iOfferOverviewCollection.push(this.convertFromObject(element));
		});
		return iOfferOverviewCollection;
	}

	static convertFromObject(ob: any): iOfferOverview {
		let iOfferOverviewObj = new iOfferOverview();
		iOfferOverviewObj.serialNumber = ob.serialNumber;
		iOfferOverviewObj.shippingDate = ob.shippingDate;
		iOfferOverviewObj.shippingPort = ob.shippingPort;
		iOfferOverviewObj.destinationPort = ob.destinationPort;
		iOfferOverviewObj.destinationDate = ob.destinationDate;
		iOfferOverviewObj.freightPrice = ob.freightPrice;
		iOfferOverviewObj.freightSubchargesPrice = ob.freightSubchargesPrice;
		iOfferOverviewObj.shippingLine = ob.shippingLine;
		iOfferOverviewObj.freightSubcharges = ob.freightSubcharges;
		iOfferOverviewObj.customsPrice = ob.customsPrice;
		iOfferOverviewObj.customs = ob.customs;
		iOfferOverviewObj.inlandTransportationPrice = ob.inlandTransportationPrice;
		iOfferOverviewObj.inlandTransportation = ob.inlandTransportation;
		iOfferOverviewObj.otherCostsPrice = ob.otherCostsPrice;
		iOfferOverviewObj.otherCosts = ob.otherCosts;
		iOfferOverviewObj.insuranceCost = ob.insuranceCost;
		return iOfferOverviewObj;
	}

	static createEmptyObject(): iOfferOverview {
		let iOfferOverviewObj = new iOfferOverview();
		iOfferOverviewObj.serialNumber = '';
		iOfferOverviewObj.shippingDate = '';
		iOfferOverviewObj.shippingPort = '';
		iOfferOverviewObj.destinationPort = '';
		iOfferOverviewObj.destinationDate = '';
		iOfferOverviewObj.freightPrice = 0;
		iOfferOverviewObj.freightSubchargesPrice = 0;
		iOfferOverviewObj.shippingLine = '';
		iOfferOverviewObj.freightSubcharges = [];
		iOfferOverviewObj.customsPrice = 0;
		iOfferOverviewObj.customs = [];
		iOfferOverviewObj.inlandTransportationPrice = 0;
		iOfferOverviewObj.inlandTransportation = [];
		iOfferOverviewObj.otherCostsPrice = 0;
		iOfferOverviewObj.otherCosts = [];
		iOfferOverviewObj.insuranceCost = 0;
		return iOfferOverviewObj;
	}

	startData() {
		this.serialNumber = 'MOL9876GHT2345LP';
		this.shippingDate = '';
		this.shippingPort = '-';
		this.destinationPort = '-';
		this.shippingLine = '-';
		this.destinationDate = '';
		this.freightPrice = 0;
		this.freightSubchargesPrice = 0;
		this.freightSubcharges = [];
		this.customsPrice = 0;
		this.customs = [];
		this.inlandTransportationPrice = 0;
		this.inlandTransportation = [];
		this.otherCostsPrice = 0;
		this.otherCosts = [];
		this.insuranceCost = 0;
	}

	getTotal(): number {
		let total = 0;
		total += this.freightPrice;
		total += this.freightSubchargesPrice;
		total += this.customsPrice;
		total += this.inlandTransportationPrice;
		total += this.otherCostsPrice;
		total += this.insuranceCost;
		return total;
	}
}
