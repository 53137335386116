import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}
	getMyData(behaviorSubject: BehaviorSubject<any>) {
		this.http.get(this.urlApi + '/api/me', this.getHeader()).subscribe({
			next: (data: any) => {
				const normalizedTokens = iStaticUtilities.normalizeNames(data);
				this.res = new iResultHttp({
					status: 200,
					ok: true,
					message: '',
					data: iStaticUtilities.normalizeNames(data)
				});
				this.sendNextObservable(behaviorSubject, this.res);
			},
			error: (error: any) => {
				this.res = new iResultHttp({
					status: error.status,
					ok: false,
					message: error.error.message
				});
				this.sendNextObservable(behaviorSubject, this.res, true, {
					method: this.getMyData,
					args: [behaviorSubject]
				});
			}
		});
	}
}
