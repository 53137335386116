import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { iToggleWithThextOptions } from '../../Interfaces/Utils/iToggleWithThextOptions';

@Component({
	selector: 'toggle-with-text',
	templateUrl: './toggle-with-text.component.html',
	styleUrls: ['./toggle-with-text.component.scss']
})
export class ToggleWithTextComponent implements OnInit {
	@Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() options: iToggleWithThextOptions = {
		checked: false,
		visible: true,
		id: 'toggle'
	};
	@Input() extraId: string = '';
	@Input() checked: boolean = false;
	@Input() showText: boolean = true;

	constructor() {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['options']) {
			if (changes['options'].currentValue) {
				this.updateToggleLabel(this.options.id!, this.options.checked!, true);
			}
		}
	}

	ngAfterViewInit() {
		if (
			this.options.id &&
			this.options.checked !== undefined &&
			this.options.checked !== null
		) {
			setTimeout(() => {
				this.updateToggleLabel(this.options.id!, this.options.checked!, true);
			}, 500);
		}
		if (this.extraId && this.checked !== undefined && this.checked !== null) {
			setTimeout(() => {
				this.updateToggleLabel(this.extraId, this.checked, true);
			}, 500);
		}
	}

	updateToggleLabel(
		toggleId: string,
		boolean: boolean,
		noedit?: boolean
	): void {
		const toggleElement = document.getElementById(toggleId);
		const text = toggleElement?.querySelector('.generalLabel');

		if (boolean) {
			if (text) {
				text?.classList.remove('red');
				text?.classList.add('green');
				text.innerHTML = 'Yes';
			}
		} else {
			if (text) {
				text?.classList.remove('green');
				text?.classList.add('red');
				text.innerHTML = 'No';
			}
		}

		if (noedit) return;

		this.toggleChange.emit(boolean);
	}
}
