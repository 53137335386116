import { Component, Input, OnInit } from '@angular/core';
import { iNavHunterTransitDetails } from '../../../Interfaces/Utils/iNavHunterTransitDetails';

@Component({
	selector: 'nav-hunter-in-transit',
	templateUrl: './nav-hunter-in-transit.component.html',
	styleUrls: ['./nav-hunter-in-transit.component.scss']
})
export class NavHunterInTransitComponent implements OnInit {
	private _options: iNavHunterTransitDetails = {
		hasArrived: false,
		bookingConfirmationDate: '12/05/2021',
		ETDPort: 'Valencia Port | ESVLC',
		ETDDate: '12/05/2021',
		ETADate: '12/05/2021',
		ETAPort: 'Valencia Port | ESVLC'
	};

	@Input() get options() {
		return this._options;
	}
	set options(value: iNavHunterTransitDetails) {
		this._options = value;
	}

	constructor() {}

	ngOnInit() {}
}
