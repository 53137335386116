<div class="loginGeneralContainer">
	<div class="left">
		<img
			class="logoVCE"
			src="/assets/Icons/Login/VCX_LOGO_oscuro_1.svg"
			alt=""
		/>
	</div>

	<div class="right">
		<form class="form">
			<div class="icons">
				<img
					src="/assets/Icons/Login/iconLogin1.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin2.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin3.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin4.svg"
					alt=""
				/>
				<img
					src="/assets/Icons/Login/iconLogin5.svg"
					alt=""
				/>
				<p>Ally at the port</p>
			</div>

			<label for="">Create your account</label>

			<div class="formLine">
				<customInput
					name="email"
					[options]="extraClass.emailOptions"
					[(ngModel)]="extraClass.ngModels.email"
					(change)="checkEmail(extraClass.ngModels.email)"
				></customInput>
			</div>

			<div class="formLine">
				<customInput
					name="emailRepeat"
					[options]="extraClass.repeatEmailOptions"
					[(ngModel)]="extraClass.ngModels.repeatEmail"
					(change)="checkEmail(extraClass.ngModels.repeatEmail)"
				></customInput>
			</div>

			<div class="formLine doble">
				<customInput
					name="pass"
					[options]="extraClass.passwordOptions"
					[(ngModel)]="extraClass.ngModels.password"
					(change)="checkIfCompleted()"
				></customInput>
				<formatted-selector
					name="teacher"
					class="register"
					[label]="'Select your teacher *'"
					(change)="checkIfCompleted()"
					[optionsSelector]="extraClass.teacherOptions"
					[(ngModel)]="extraClass.ngModels.teacher"
				></formatted-selector>
			</div>

			<div class="formLine doble">
				<customInput
					name="name"
					[options]="extraClass.nameOptions"
					[(ngModel)]="extraClass.ngModels.name"
					(change)="checkIfCompleted()"
				></customInput>
				<customInput
					name="surname"
					[options]="extraClass.surnameOptions"
					[(ngModel)]="extraClass.ngModels.surname"
					(change)="checkIfCompleted()"
				></customInput>
			</div>

			<div class="formLine doble">
				<formatted-selector
					name="country"
					class="register"
					[label]="'Country *'"
					[optionsSelector]="extraClass.countryOptions"
					[(ngModel)]="extraClass.ngModels.country"
					(change)="sendCountryToApi(extraClass.ngModels.country)"
				></formatted-selector>
				<formatted-selector
					name="city"
					class="register"
					[label]="'City *'"
					[optionsSelector]="extraClass.cityOptions"
					[(ngModel)]="extraClass.ngModels.city"
					(change)="checkIfCompleted()"
				></formatted-selector>
			</div>

			<btn
				[ngClass]="{ disabled: !extraClass.isDataCompleted }"
				(click)="
					extraClass.isDataCompleted ? registerUser() : $event.stopPropagation()
				"
			>
				<ng-container>
					<span>Register</span>
				</ng-container>
			</btn>
			<a
				class="forgotPassword"
				(click)="goToLogin()"
			>
				I already have an account
			</a>
		</form>
	</div>
</div>
