import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
	providedIn: 'root'
})
export class CostService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	create(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.post(this.urlApi + '/api/cost', data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.create,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	delete(behaviorSubject: BehaviorSubject<any>, ids: Array<number | string>) {
		let idsObjs = this.convertArrayObject(ids, 'ids');
		this.http
			.delete(this.urlApi + '/api/cost', this.sendBodyOptions(idsObjs))
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: null
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.delete,
						args: [behaviorSubject, ids]
					});
				}
			});
	}

	private processCostsTransportationSelector(
		costs: any,
		behaviorSubject: BehaviorSubject<any>
	) {
		this.res = new iResultHttp({
			status: 200,
			ok: true,
			message: '',
			data: costs
		});
		this.sendNextObservable(behaviorSubject, this.res);
	}

	getSelectorTitles(behaviorSubject: BehaviorSubject<any>) {
		const costsFromStorage = LocalStorageHandlerService.getEncodedItem(
			'cost-transportation'
		);

		if (costsFromStorage) {
			this.processCostsTransportationSelector(
				costsFromStorage,
				behaviorSubject
			);
			return;
		}

		this.http
			.get(
				this.urlApi + '/api/other/cost/transportation/selector',
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					LocalStorageHandlerService.setEncodedItem(
						'cost-transportation',
						data
					);
					this.processCostsTransportationSelector(data, behaviorSubject);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getSelectorTitles,
						args: [behaviorSubject]
					});
				}
			});
	}
}
