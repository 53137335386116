import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class WareContainerService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getWareContainerSelector(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/ware/container/selector/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCountries =
						iStaticUtilities.normalizeNamesCollection(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCountries
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getWareContainerSelector,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	updateWareContainer(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/ware/container/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateWareContainer,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	delete(behaviorSubject: BehaviorSubject<any>, ids: number | string) {
		let idsObjs = { id: ids };
		this.http
			.delete(
				this.urlApi + '/api/ware/container',
				this.sendBodyOptions(idsObjs)
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data.data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.delete,
						args: [behaviorSubject, ids]
					});
				}
			});
	}
	deleteOfferWareContainer(
		behaviorSubject: BehaviorSubject<any>,
		ids: number | string
	) {
		let idsObjs = { ids: ids };
		this.http
			.delete(
				this.urlApi + '/api/offer/ware/container',
				this.sendBodyOptions(idsObjs)
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data.data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.deleteOfferWareContainer,
						args: [behaviorSubject, ids]
					});
				}
			});
	}

	createInShipment(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.post(this.urlApi + '/api/wc/sh/' + data.id, data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data.data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.createInShipment,
						args: [behaviorSubject, data]
					});
				}
			});
	}
	createInOffer(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.post(
				this.urlApi + '/api/offer/ware/container/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: iStaticUtilities.normalizeNamesCollection(data.data)
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.createInOffer,
						args: [behaviorSubject, data]
					});
				}
			});
	}
}
