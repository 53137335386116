import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

@Component({
	selector: 'app-shippingLeadNumber-popup',
	templateUrl: './shippingLeadNumber-popup.component.html',
	styleUrls: ['./shippingLeadNumber-popup.component.scss']
})
export class ShippingLeadNumberPopupComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.forceClosePopup();
		}
	}

	leadNumber: number | null = null;

	leadNumberOptions: iOptionsCustomInput = {
		placeholder: 'Lead Number',
		type: 'number'
	};

	constructor(
		public dialogRef: MatDialogRef<ShippingLeadNumberPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any
	) {}

	ngOnInit() {}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}
}
