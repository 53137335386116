import {
	iBaseObject,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { iCountry } from './iCountry';

export interface iAddress {
	id?: number | null;
	type: string;
	state: string;
	city: string;
	zipCode: string;
	street: string;
	country: iCountry | number;
	isTemplate?: boolean;
}

export class iAddress {
	static convertFromCollection(ob: any): Array<iAddress> {
		let iAddressCollection: Array<iAddress> = [];
		ob.forEach((element: any) => {
			iAddressCollection.push(this.convertFromObject(element));
		});
		return iAddressCollection;
	}

	static convertFromObject(ob: any): iAddress {
		let iAddressObj = new iAddress();
		iAddressObj.id = ob.id;
		iAddressObj.type = ob.type;
		iAddressObj.state = ob.state;
		iAddressObj.city = ob.city;
		iAddressObj.zipCode = ob.zipCode;
		iAddressObj.street = ob.street;
		iAddressObj.country = iCountry.convertFromObject(
			iStaticUtilities.normalizeNames(ob.country)
		);
		return iAddressObj;
	}

	static createEmptyObject(): iAddress {
		let iAddressObj = new iAddress();
		iAddressObj.id = null;
		iAddressObj.type = '';
		iAddressObj.state = '';
		iAddressObj.city = '';
		iAddressObj.zipCode = '';
		iAddressObj.street = '';
		iAddressObj.country = iCountry.createEmptyObject();
		return iAddressObj;
	}
}
