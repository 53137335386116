<div class="confirmPopupGeneralContainer">
	<div class="body">
		<img
			*ngIf="modalData.icon"
			[src]="modalData.icon"
		/>
		<p class="title">{{ modalData.title }}</p>
		<p class="description">{{ modalData.description }}</p>
		<ng-container *ngIf="modalData.url1 && modalData.url2">
			<div class="documentsContainer">
				<messageIcon
					[options]="{ url: modalData.url1, title: 'Document 1' }"
				></messageIcon>
				<messageIcon
					[options]="{ url: modalData.url2, title: 'Document 2' }"
				></messageIcon>
			</div>
		</ng-container>
	</div>
	<div
		class="footer"
		[ngClass]="{
			jcc:
				(modalData?.buttonCancelText && !modalData?.buttonConfirmText) ||
				(!modalData?.buttonCancelText && modalData?.buttonConfirmText)
		}"
	>
		<btn
			[classList]="'generalButton action red'"
			(click)="forceClosePopup()"
			*ngIf="modalData?.buttonCancelText"
		>
			<ng-container>
				<span>{{ modalData?.buttonCancelText }}</span>
			</ng-container>
		</btn>
		<btn
			[classList]="'generalButton action green'"
			(click)="closePopup()"
			*ngIf="modalData?.buttonConfirmText"
		>
			<ng-container>
				<span>{{ modalData?.buttonConfirmText }}</span>
			</ng-container>
		</btn>
	</div>
</div>
