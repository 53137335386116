import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';

@Component({
	selector: 'formatted-selector',
	templateUrl: './formatted-selector.component.html',
	styleUrls: ['./formatted-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormattedSelectorComponent), // replace name as appropriate
			multi: true
		}
	]
})
export class FormattedSelectorComponent implements OnInit {
	@Output() change: EventEmitter<any> = new EventEmitter<any>();
	@Input() label: string = '';
	@Input() readOnly: boolean = false;
	@Input() required: boolean = false;
	@Input() optionsSelector!: iOptionsSelector;

	//INTERNAL VALUES

	private _onChange = (_: any) => {};
	private _onTouched = () => {};
	private _oldData;

	_disabled: boolean = false;
	model;

	writeValue(obj: any): void {
		if (obj !== this.model) {
			this.model = obj;
			this._onChange(obj);
			this.setReadOnlyCustomInputData();
		}
	}

	setDisabledState?(isDisabled: boolean): void {
		this._disabled = isDisabled;
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	onNgModelChange(event) {
		this._oldData = this.model;
		this.model = event;
	}

	onModelChange($event) {
		this.model = $event;
		this._onChange(this.model);
	}

	name: string = new Date().valueOf().toString();
	customInputValue: any = '';

	customInputOptionsForReadOnly: iOptionsCustomInput = {
		placeholder: '',
		type: 'text',
		readOnly: true,
		classList: 'form',
		name: 'customInput'
	};

	constructor() {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['optionsSelector']) {
			if (changes['optionsSelector'].currentValue) {
				this.setReadOnlyCustomInputData();
			}
		}
	}

	sendNgModel(event) {
		this.change.emit(event);
		this.setDataIfBindValueId();
	}

	setReadOnlyCustomInputData() {
		if (this.optionsSelector?.placeholder) {
			this.customInputOptionsForReadOnly.placeholder =
				this.optionsSelector.placeholder!;
			this.customInputOptionsForReadOnly.label = this.label!;
			this.customInputOptionsForReadOnly.required = this.required;
			this.setDataIfBindValueId();
		}
	}

	setDataIfBindValueId() {
		if (this.customInputValue) return;
		if (this.optionsSelector.bindValue === 'id') {
			this.customInputValue =
				this.optionsSelector.items.find((item) => item.id === this.model)?.[
					this.optionsSelector.bindLabel ?? 'name'
				] ?? '';
		} else {
			this.customInputValue = this.model;
		}
	}
}
