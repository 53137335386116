import { Component, HostListener, OnInit } from '@angular/core';
import {
	StaticUtilitiesService,
	iResultHttp,
	sha256,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/login.service';
import { MessageService } from 'src/app/Services/Api/message.service';
import { UserService } from 'src/app/Services/Api/user.service';
import { GeneralLoaderService } from 'src/app/Services/Utils/generalLoader.service';
import { Login } from 'src/app/Shared/Class/login';
import { BoatLoaderComponent } from 'src/app/Shared/Components/boat-loader/boat-loader.component';
import { ILogin } from 'src/app/Shared/Interfaces/Api/iLogin';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	/**
	 * VARIABLES
	 */

	loginClass: Login = new Login();
	constructor(
		private staticUtilitiesSE: StaticUtilitiesService,
		private loginSE: LoginService,
		private userSE: UserService,
		private messageSE: MessageService,
		private generalLoaderSE: GeneralLoaderService
	) {}

	ngOnInit(): void {}

	/**
	 * HANDLERS
	 */
	successGetMyDataHandler(res: iResultHttp) {
		localStorage.setItem('email', res.data.email);
		localStorage.setItem('nmsr', res.data.id);
		localStorage.setItem('name', res.data.name);
		localStorage.setItem('tId', res.data.idTarget);
	}
	successGetCountUnreadMessages(res: iResultHttp) {
		sessionStorage.setItem('unreadMessagesCount', res.data);
	}
	/**
	 * CALLS TO API
	 */

	login() {
		const newData: ILogin = {
			username: this.loginClass.loginData.username,
			password: sha256(this.loginClass.loginData.password)!
		};
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		// this.staticUtilitiesSE.openLoader(BoatLoaderComponent);
		this.generalLoaderSE.addToLoaderAmount();
		this.loginSE.login(behaviorSubject, newData);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.getMyData(),
					error: false
				});
				// this.staticUtilitiesSE.closeLoader();
				this.generalLoaderSE.removeFromLoaderAmount();
			});
	}

	getMyData() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.userSE.getMyData(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, [
					{
						method: this.successGetMyDataHandler(res),
						error: false
					},
					{
						method: this.getCountUnreadMessages(),
						error: false
					},
					{
						method: this.loginSE.redirectToRol(),
						error: false
					}
				]);
			});
	}

	getCountUnreadMessages() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.messageSE.getCountUnreadMessages(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, [
					{
						method: () => this.successGetCountUnreadMessages(res),
						error: false
					}
				]);
			});
	}

	goToRegister() {
		this.staticUtilitiesSE.goTo('register', slideNavigationTypes.slideToRight);
	}
}
