import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-navHunterConfirmRequest-popup',
	templateUrl: './navHunterConfirmRequest-popup.component.html',
	styleUrls: ['./navHunterConfirmRequest-popup.component.scss']
})
export class NavHunterConfirmRequestPopupComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.forceClosePopup();
		}
	}

	constructor(
		public dialogRef: MatDialogRef<NavHunterConfirmRequestPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any
	) {}

	ngOnInit() {}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}
}
