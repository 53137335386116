import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iNavHunterOptionIcon } from '../../Interfaces/Utils/iNavHunterOptionIcon';
import { iNavHunterSearchLine } from '../../Interfaces/Utils/iNavHunterSearchLine';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

export class NavHunterOptions {
	body: any = {};

	isDataLoaded: boolean = false;

	pricesLines: iNavHunterSearchLine[] =
		StaticDataHandlerService.getNavHunterSearchLines();

	// ngModels
	portOfOrigin: string = '';
	portOfDestination: string = '';
	type: string = '';
	shippmentDetails: string = '';
	desiredDepartureDate: string = '';

	// InputOptions
	portOfOriginOptions: iOptionsCustomInput = {
		placeholder: 'Port of origin',
		type: 'text',
		readOnly: true,
		label: 'Port of origin'
	};
	portOfDestinationOptions: iOptionsCustomInput = {
		placeholder: 'Port of destination',
		type: 'text',
		readOnly: true,
		label: 'Port of destination'
	};
	typeOptions: iOptionsCustomInput = {
		placeholder: 'Type',
		type: 'text',
		readOnly: true,
		label: 'Type'
	};
	shippmentDetailsOptions: iOptionsCustomInput = {
		placeholder: 'Shippment details',
		type: 'text',
		readOnly: true,
		label: 'Shippment details'
	};
	desiredDepartureDateOptions: iOptionsCustomInput = {
		placeholder: 'Desired departure date',
		type: 'text',
		readOnly: true,
		label: 'Desired departure date'
	};
	containerNameOptions: iOptionsCustomInput = {
		placeholder: 'Container name',
		type: 'text',
		readOnly: true,
		label: 'Container'
	};
	unitsOptions: iOptionsCustomInput = {
		placeholder: 'Units',
		type: 'text',
		readOnly: true,
		label: 'Units'
	};

	// Icon characteristics
	fasterCharacteristics: iNavHunterOptionIcon = {
		option: 'Faster',
		extraInfo: '13 días'
	};
	cheaperCharacteristics: iNavHunterOptionIcon = {
		option: 'Cheaper',
		extraInfo: '954 USD'
	};
	closerCharacteristics: iNavHunterOptionIcon = {
		option: 'Closer',
		extraInfo: '9 ago.'
	};
}
