<div class="add-shipment-document">
	<div class="formLine doble">
		<customInput
			[(ngModel)]="extraClass.modelName"
			[options]="extraClass.inputOptions"
		></customInput>
		<div class="add-shipment-document__inputs--selector customFormContainer">
			<label for>
				Document type
				<sup>*</sup>
			</label>
			<selector
				[(ngModel)]="extraClass.modelType"
				[options]="extraClass.selectorOptions"
			></selector>
		</div>
	</div>
	<files-area (filesChange)="handleChangeFile($event)"></files-area>
	<div class="add-shipment-document__btns">
		<btn
			(click)="forceClosePopup()"
			classList="generalButton action red"
		>
			Cancelar
		</btn>
		<btn
			[ngClass]="{
				disabled:
					extraClass.modelName === '' ||
					extraClass.modelType === '' ||
					extraClass.files.length === 0
			}"
			classList="generalButton action green"
			(click)="submitPopUp()"
		>
			Confirm
		</btn>
	</div>
</div>
