import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuasarLibraryModule } from '@quasar-dynamics/basic-designsystem';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { BoatLoaderComponent } from './Shared/Components/boat-loader/boat-loader.component';
import { LoginComponent } from './Pages/login/login.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localEs from '@angular/common/locales/es';
import { SharedModule } from './Shared/shared.module';
import { ResponseMessageComponent } from './Shared/Components/response-message/response-message.component';
import { MatIconModule } from '@angular/material/icon';
import { NewMessageComponent } from './Shared/Components/new-message/new-message.component';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import { ShippingLeadNumberPopupComponent } from './Popups/shippingLeadNumber-popup/shippingLeadNumber-popup.component';
import { NavHunterConfirmRequestPopupComponent } from './Popups/navHunterConfirmRequest-popup/navHunterConfirmRequest-popup.component';
import { AddFreightPopupComponent } from './Popups/CommercialOffers-Popup/addFreight-popup/addFreight-popup.component';
import { AddCostPopupComponent } from './Popups/CommercialOffers-Popup/addCost-popup/addCost-popup.component';
import { AddCustomCostsPopupComponent } from './Popups/CommercialOffers-Popup/addCustomCosts-popup/addCustomCosts-popup.component';
import { AddInlandTransportationCostPopupComponent } from './Popups/CommercialOffers-Popup/addInlandTransportationCost-popup/addInlandTransportationCost-popup.component';
import { AddShipmentDocumentComponent } from './Popups/add-shipment-document/add-shipment-document.component';
import { AddContainerLinePopupComponent } from './Popups/add-container-line-popup/add-container-line-popup.component';
import { ShipingFullfillEdiPopupComponent } from './Popups/Shiping/shiping-fullfill-edi-popup/shiping-fullfill-edi-popup.component';
import { RegisterComponent } from './Pages/login/register/register.component';
import { ThreadMessageListComponent } from './Shared/Components/thread-message-list/thread-message-list.component';
import { MatTabsModule } from '@angular/material/tabs';

registerLocaleData(localEs, 'es');
@NgModule({
	declarations: [
		AppComponent,
		BoatLoaderComponent,
		LoginComponent,
		ResponseMessageComponent,
		NewMessageComponent,
		ShippingLeadNumberPopupComponent,
		NavHunterConfirmRequestPopupComponent,
		AddFreightPopupComponent,
		AddCostPopupComponent,
		AddCustomCostsPopupComponent,
		AddInlandTransportationCostPopupComponent,
		AddShipmentDocumentComponent,
		AddContainerLinePopupComponent,
		ShipingFullfillEdiPopupComponent,
		RegisterComponent,
		ThreadMessageListComponent
	],
	providers: [
		CurrencyPipe,
		{ provide: LOCALE_ID, useValue: 'es' },
		{ provide: DateAdapter, useClass: CustomDateAdapter }
	],

	bootstrap: [AppComponent],
	imports: [
		AppRoutingModule,
		QuasarLibraryModule,
		BrowserAnimationsModule,
		NgSelectModule,
		HttpClientModule,
		FormsModule,
		DragDropModule,
		SharedModule,
		MatIconModule,
		MatTabsModule
	]
})
export class AppModule {}
