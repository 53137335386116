<div class="confirmPopupGeneralContainer">
	<div class="body">
		<img
			src="/assets/Images/NavHunter/acceptPopup.png"
			alt=""
		/>
		<p class="title">Thank you for your inquiry.</p>
		<p class="description">
			You will receive a summary of the quotation, including a breakdown of all
			expenses, shortly. We appreciate your interest and will be in touch soon.
			<br />
			<br />

			<b>You can now close this tab.</b>
		</p>
	</div>
	<div class="footer">
		<!-- <btn [classList]="'generalButton action green'" (click)="closePopup()" >
      <ng-container>
        <span>Close</span>
      </ng-container>
    </btn> -->
	</div>
</div>
