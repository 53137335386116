<div class="sidebarGeneralContainer">
	<div
		class="menu"
		*ngIf="!roles.includes('ROLE_TEACHER')"
	>
		<btn
			class="commonButton"
			tooltip
			[text]="'Home'"
			[delay]="0"
			(click)="goToHome()"
			[ngClass]="{ active: urlActive.includes('home') }"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/home.svg"
					alt=""
				/>
			</ng-container>
		</btn>
		<btn
			class="commonButton"
			tooltip
			[text]="'Offers'"
			[delay]="0"
			[ngClass]="{ active: urlActive.includes('offers') }"
			(click)="goToOffers()"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/offers.svg"
					alt=""
				/>
			</ng-container>
		</btn>
		<btn
			class="commonButton"
			tooltip
			[text]="'Shipments'"
			[delay]="0"
			[ngClass]="{ active: urlActive.includes('shipment') }"
			(click)="goToShippment()"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/shipments.svg"
					alt=""
				/>
			</ng-container>
		</btn>
		<btn
			class="commonButton"
			tooltip
			[text]="'Settings'"
			[delay]="0"
			[ngClass]="{ active: urlActive.includes('settings') }"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/settings.svg"
					alt=""
				/>
			</ng-container>
		</btn>
		<btn
			class="commonButton thirdParty"
			tooltip
			[text]="'Third parties'"
			[delay]="0"
			[ngClass]="{ active: urlActive.includes('third-parties') }"
			(click)="goToThirdParties()"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/thirdParties.svg"
					alt=""
				/>
			</ng-container>
		</btn>
	</div>
	<div class="otherActions">
		<btn
			class="specialButton"
			(click)="toggleOpenModal()"
			[ngClass]="{ modalOpened: modalOpened }"
			*ngIf="!roles.includes('ROLE_TEACHER')"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/more.svg"
					alt=""
				/>
			</ng-container>
		</btn>
		<btn
			class="commonButton"
			(click)="logOut()"
		>
			<ng-container>
				<img
					src="/assets/Icons/Sidebar/powerOff.svg"
					alt=""
				/>
			</ng-container>
		</btn>
	</div>
</div>
<div
	class="quickActionsGeneralContainer"
	[ngClass]="{ opened: modalOpened }"
>
	<div class="quickActions">
		<a (click)="modalOpened = false; goToCreateCommercialOffer()">
			<img
				src="/assets/Icons/Sidebar/createNewCommercialOffer.svg"
				alt=""
			/>
			Create new commercial offer
		</a>
		<a (click)="modalOpened = false; goToCreateShipment()">
			<img
				src="/assets/Icons/Sidebar/createNewShipment.svg"
				alt=""
			/>
			Create new shipment
		</a>
		<!-- <a (click)="modalOpened = false;"><img src="/assets/Icons/Sidebar/createNewShipmentFile.svg" alt="">Create new
      shipment file</a> -->
	</div>
</div>
