import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	StaticUtilitiesService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CommercialOfferService } from 'src/app/Services/Api/commercial-offer.service';
import { iCaseCard } from 'src/app/Shared/Interfaces/Utils/iCaseCard';

@Component({
	selector: 'case-card',
	templateUrl: './case-card.component.html',
	styleUrls: ['./case-card.component.scss']
})
export class CaseCardComponent implements OnInit {
	@Output() accept: EventEmitter<iCaseCard> = new EventEmitter<iCaseCard>();
	@Output() reject: EventEmitter<iCaseCard> = new EventEmitter<iCaseCard>();
	@Output() select: EventEmitter<iCaseCard> = new EventEmitter<iCaseCard>();

	@Input() card: iCaseCard = {
		id: 1,
		icon: 'https://picsum.photos/200/300?random=1',
		name: 'Maquinaria Agrícola SL1',
		departurePort: 'ES, Madrid',
		arrivalPort: 'TR, Ankara',
		grossWeight: '110 t',
		volume: '130',
		arrivalDate: '20/08/2023',
		difficulty: 3
	};
	@Input() isSelectCommercialOffer: boolean = false;

	@Input() isDataLoaded: boolean = false;

	constructor(private commercialOfferSE: CommercialOfferService) {}

	ngOnInit(): void {}

	// Buttons
	acceptEmmiter(): void {
		this.accept.emit(this.card);
	}
	rejectEmmiter(): void {
		this.reject.emit(this.card);
	}

	// Functionality

	setPlaceholderImage(event) {
		let randomizedNumber = Math.floor(Math.random() * 100);
		event.target.src = `https://picsum.photos/200/300?random=${randomizedNumber}`;
	}

	selectThisCaseForCommercialOffer() {
		this.select.emit(this.card);
	}
}
