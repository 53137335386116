<div class="fullPopup">
	<div class="pcsAndEmailContainer">
		<div class="pcs">
			<p>Conexión PCS</p>

			<span class="pill">
				{{ pcs }}
			</span>
		</div>

		<div class="pcs">
			<p>Consignee email</p>

			<span class="pill">
				{{ email }}
			</span>
		</div>
	</div>

	<div class="formLine">
		<formatted-selector
			[label]="'Container'"
			[optionsSelector]="containerSelectorOptions"
			*ngIf="isTransportOrder && editable"
			[(ngModel)]="selectedContainer"
		></formatted-selector>
		<customInput
			*ngIf="!editable"
			[(ngModel)]="containerType"
			[options]="containerTypeOptions"
		></customInput>
	</div>

	<div class="infoAndComment">
		<div class="formLine">
			<customInput
				name="kindOfMessage"
				[options]="kindOfMessageCustomInputOptions"
				[(ngModel)]="kindOfMessage"
			></customInput>
		</div>
		<div class="formLine doble">
			<customInput
				name="sentBy"
				[options]="sentByCustomInputOptions"
				[(ngModel)]="sentBy"
			></customInput>
			<customInput
				name="messageReference"
				[options]="messageReferenceCustomInputOptions"
				[(ngModel)]="messageReference"
			></customInput>
		</div>
		<div class="formLine doble">
			<customInput
				name="sentOn"
				[options]="sentOnCustomInputOptions"
				[(ngModel)]="sentOn"
			></customInput>
			<customInput
				name="messageReference"
				[options]="receivedOnCustomInputOptions"
				[(ngModel)]="receivedOn"
			></customInput>
		</div>
		<div class="formLine">
			<customTextArea
				[options]="commentsCustomTextAriaOptions"
				[ngClass]="{ disabled: !editable }"
				[(ngModel)]="comments"
			></customTextArea>
		</div>
	</div>

	<ng-container *ngIf="this.modalData.data.editable">
		<div class="formLine">
			<div class="buttonLine">
				<btn
					classList="generalButton create"
					(click)="closePopup('close')"
				>
					Cancel
				</btn>
				<btn
					classList="generalButton create"
					(click)="generateBodyToSend()"
				>
					Send
				</btn>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!this.modalData.data.editable">
		<div class="formLine flexEnd">
			<div class="buttonLine">
				<btn
					classList="generalButton create widthFit"
					(click)="closePopup('close')"
				>
					Close
				</btn>
				<!-- <ng-container *ngIf="modalData.data.type === 'to'" >
          <btn classList="generalButton create red" (click)="generateBodyToSend()" >Send cancelation</btn>
        </ng-container> -->
			</div>
		</div>
	</ng-container>
</div>
