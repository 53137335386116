<div
	class="responseMessageGeneralContainer"
	[ngClass]="{ minimiced: minimiceWindow }"
>
	<div class="header">
		<p>Feedback - {{ feedback?.identificator }}</p>
		<div class="icons">
			<mat-icon (click)="toggleMinimiceWindow()">expand_more</mat-icon>
			<mat-icon (click)="confirmCloseResponseMessage()">close</mat-icon>
		</div>
	</div>

	<div
		class="body"
		(click)="setFocus()"
	>
		<div
			class="feedbackMessage"
			*ngFor="let feedbackMessage of feedback?.elements"
		>
			<p class="element">{{ feedbackMessage.element }}</p>
		</div>
	</div>
</div>

<div
	class="minimicedWindow"
	[ngClass]="{ minimiced: minimiceWindow }"
	(click)="toggleMinimiceWindow()"
>
	<p>Feedback - {{ feedback?.identificator }}</p>
	<div class="icons">
		<mat-icon (click)="$event.stopPropagation(); toggleMinimiceWindow()">
			expand_less
		</mat-icon>
		<mat-icon (click)="$event.stopPropagation(); confirmCloseResponseMessage()">
			close
		</mat-icon>
	</div>
</div>
