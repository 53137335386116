import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { iCalculateCostMarginPriceObject } from '../../Interfaces/Utils/iCalculateCostMarginPriceObject';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { CalculateCostMarginPriceService } from 'src/app/Services/Utils/CalculateCostMarginPrice.service';

@Component({
	selector: 'cost-margin-price',
	templateUrl: './cost-margin-price.component.html',
	styleUrls: ['./cost-margin-price.component.scss']
})
export class CostMarginPriceComponent implements OnInit {
	@Output() calculated: EventEmitter<iCalculateCostMarginPriceObject> =
		new EventEmitter<iCalculateCostMarginPriceObject>();

	calculateObject: iCalculateCostMarginPriceObject = {
		cost: null,
		margin: null,
		price: null
	};

	costCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Cost',
		type: 'number',
		classList: 'form',
		label: 'Cost',
		required: true
	};
	marginCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Margin',
		type: 'number',
		classList: 'form',
		label: 'Margin',
		// readOnly: true,
		required: true
	};
	priceCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Price',
		type: 'number',
		classList: 'form',
		label: 'Price',
		// readOnly: true,
		required: true
	};

	constructor(
		private calculateCostMarginPriceSE: CalculateCostMarginPriceService
	) {}

	ngOnInit(): void {}

	calculate(option?: string) {
		this.calculateCostMarginPriceSE.calculate(
			this.calculateObject,
			this.priceCustomInputOptions,
			this.marginCustomInputOptions,
			option
		);
		this.calculated.emit(this.calculateObject);
	}
}
