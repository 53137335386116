<div class="animation-wrapper">
	<div class="water">
		<ul class="waves">
			<li
				class="wave-one"
				style="background-image: url('/assets/Icons/boatLoader/wave1.png')"
			></li>
			<li
				class="wave-two"
				style="background-image: url('/assets/Icons/boatLoader/wave2.png')"
			></li>
			<li
				class="wave-three"
				style="background-image: url('/assets/Icons/boatLoader/wave3.png')"
			></li>
			<li
				class="wave-four"
				style="background-image: url('/assets/Icons/boatLoader/wave4.png')"
			></li>
		</ul>
	</div>
	<div
		class="boat"
		style="background-image: url('/assets/Icons/boatLoader/containerShip.png')"
	></div>
</div>
