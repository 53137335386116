<div
	class="responseMessageGeneralContainer"
	[ngClass]="{ minimiced: minimiceWindow }"
>
	<div class="header">
		<p>New message</p>
		<div class="icons">
			<span
				class="newThirdParty"
				(click)="$event.stopPropagation(); createThirdParty()"
			>
				+
				<img
					src="/assets/Icons/Sidebar/thirdParties.svg"
					alt=""
				/>
			</span>
			<mat-icon (click)="toggleMinimiceWindow()">expand_more</mat-icon>
			<mat-icon (click)="confirmCloseResponseMessage()">close</mat-icon>
		</div>
	</div>
	<div class="caseSelector">
		<selector
			[options]="messageClass.caseOptions"
			[(ngModel)]="newMessage.studentLead"
			(change)="saveNewData(); getMessagesSelector(newMessage.studentLead)"
		></selector>
	</div>
	<div class="to">
		<selector
			[options]="messageClass.toOptions"
			[(ngModel)]="newMessage.third"
			(change)="saveNewData()"
		></selector>
	</div>
	<div class="to">
		<selector
			[options]="messageClass.messageOptions"
			[(ngModel)]="newMessage.studentTemplateId"
			(change)="
				saveNewData(); getAddresForMessage(newMessage.studentTemplateId)
			"
		></selector>
	</div>
	<div class="subject">
		<customInput
			[options]="messageClass.subjectOptions"
			[(ngModel)]="newMessage.subject"
			(change)="saveNewData()"
		></customInput>
	</div>

	<div
		class="body"
		(click)="setFocus()"
	>
		<div class="editable">
			<p>{{ newMessage.body }}</p>
		</div>
		<div class="attachGeneralContainer">
			<ng-container *ngFor="let file of newMessage.documents; let i = index">
				<div
					class="attachContainer"
					(click)="$event.stopPropagation(); openFile(file)"
				>
					<mat-icon
						(click)="$event.stopPropagation(); removeAttachedFile(file)"
					>
						close
					</mat-icon>
					<div class="icon">
						<div class="white">
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
							<img
								src="/assets/Icons/Messages/line.svg"
								alt=""
							/>
						</div>
					</div>
					<span>Attached document {{ i + 1 }}</span>
				</div>
			</ng-container>
		</div>
	</div>
	<btn
		class="attachDocuments"
		fileInput
		[accept]="formatos"
		[(ngModel)]="file"
		[multiple]="true"
		(change)="getFile(file)"
	>
		<ng-container>
			<img
				src="/assets/Icons/Messages/attachDocument.svg"
				alt=""
			/>
			<span>Attach documents</span>
		</ng-container>
	</btn>
	<btn
		class="sendMessage"
		(click)="createNewMessage()"
	>
		<ng-container>
			<img
				src="/assets/Icons/Messages/sendMessage.svg"
				alt=""
			/>
			<span>Send message</span>
		</ng-container>
	</btn>
</div>

<div
	class="minimicedWindow"
	[ngClass]="{ minimiced: minimiceWindow }"
	(click)="toggleMinimiceWindow()"
>
	<p>New message</p>
	<div class="icons">
		<span
			class="newThirdParty"
			(click)="$event.stopPropagation(); createThirdParty()"
		>
			+
			<img
				src="/assets/Icons/Sidebar/thirdParties.svg"
				alt=""
			/>
		</span>
		<mat-icon (click)="$event.stopPropagation(); toggleMinimiceWindow()">
			expand_less
		</mat-icon>
		<mat-icon (click)="$event.stopPropagation(); confirmCloseResponseMessage()">
			close
		</mat-icon>
	</div>
</div>
