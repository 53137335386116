import { iBaseObject } from '@quasar-dynamics/basic-designsystem';

export interface iContainerTypeSelector extends iBaseObject {
	id: number | null;
	name: string;
}

export class iContainerTypeSelector extends iBaseObject {
	static convertFromCollection(ob: any): Array<iContainerTypeSelector> {
		let iContainerTypeSelectorCollection: Array<iContainerTypeSelector> = [];
		ob.forEach((element: any) => {
			iContainerTypeSelectorCollection.push(this.convertFromObject(element));
		});
		return iContainerTypeSelectorCollection;
	}

	static convertFromObject(ob: any): iContainerTypeSelector {
		let iContainerTypeSelectorObj = new iContainerTypeSelector();
		iContainerTypeSelectorObj.id = ob.id;
		iContainerTypeSelectorObj.name = ob.name;
		return iContainerTypeSelectorObj;
	}

	static createEmptyObject(): iContainerTypeSelector {
		let iContainerTypeSelectorObj = new iContainerTypeSelector();
		iContainerTypeSelectorObj.id = null;
		iContainerTypeSelectorObj.name = '';
		return iContainerTypeSelectorObj;
	}
}
