import {
	IOptionCustomTextArea,
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';
import { iThirdPartySelector } from '../Interfaces/Api/ThirdParty/iThirdPartySelector';
import { thirdPartyTypes } from 'src/app/Services/Api/third-party.service';

export class ThirdPartySelectorClass {
	// Custom input options
	nameOfCOntactCustomInputOptions: iOptionsCustomInput = {
		required: true,
		placeholder: 'Name of contact',
		type: 'text',
		classList: 'form',
		label: 'Name of contact',
		readOnly: true
	};
	nameCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Name',
		type: 'text',
		classList: 'form',
		label: 'Name',
		readOnly: true
	};
	vatCustomInputOptions: iOptionsCustomInput = {
		required: true,
		placeholder: 'VAT',
		type: 'text',
		classList: 'form',
		label: 'VAT',
		readOnly: true
	};
	phoneNumberCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Phone number',
		type: 'text',
		classList: 'form',
		label: 'Phone number',
		readOnly: true
	};
	emailCustomInputOptions: iOptionsCustomInput = {
		required: true,
		placeholder: 'Email',
		type: 'text',
		classList: 'form',
		label: 'Email',
		readOnly: true
	};
	readOnlyAddress: iOptionsCustomInput = {
		placeholder: 'Address',
		type: 'text',
		classList: 'form',
		label: 'Address',
		readOnly: true
	};
	readOnlyThird: iOptionsCustomInput = {
		placeholder: 'Third name',
		type: 'text',
		classList: 'form',
		label: 'Third name',
		readOnly: true
	};

	// Custom textarea options
	addressCustomTextareaOptions: IOptionCustomTextArea = {
		cols: 30,
		rows: 5,
		placeholder: 'Preview',
		classList: 'form',
		label: 'Preview'
	};

	// Selector options
	nameSelectorOptions: iOptionsSelector = {
		items: [],
		search: true,
		placeholder: 'Name',
		classList: 'specialFormSelector',
		bindLabel: 'name',
		bindValue: 'id',
		clearable: false
	};

	// Empty address selector options
	emptyAddressSelectorOptions: iOptionsSelector = {
		items: [],
		search: true,
		placeholder: 'Address',
		classList: 'specialFormSelector',
		bindLabel: 'name',
		bindValue: 'id'
	};

	// Address selector options
	addressSelectorOptions: iOptionsSelector = this.emptyAddressSelectorOptions;

	// All third parties
	allThirdParties: iThirdPartySelector[] = [];

	// Footer data
	footerData = {
		thirdName: '',
		thirdCif: '',
		thirdEmail: '',
		thirdPhone: ''
	};

	// Preview address
	previewAddress = '';

	// Preview third party
	previewThird = '';

	// Preview address name
	previewAddressName = '';

	// Function to create required third party types
	createRequiredThirdPartyTypes = () => {
		const thirdPartiesArray: number[] = [];
		Object.values(thirdPartyTypes).forEach((value) => {
			let parsedValue = Number(value);
			if (!isNaN(parsedValue)) {
				thirdPartiesArray.push(parsedValue);
			}
		});
		return thirdPartiesArray;
	};

	// Third party types
	requiredThirdPartyTypes = this.createRequiredThirdPartyTypes();

	// Booking id
	bookingId: number | string = 0;
}
