import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'boatLoader',
	templateUrl: './boat-loader.component.html',
	styleUrls: ['./boat-loader.component.scss']
})
export class BoatLoaderComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
