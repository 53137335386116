import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'shiping-second-line',
	templateUrl: './shiping-second-line.component.html',
	styleUrls: ['./shiping-second-line.component.scss']
})
export class ShipingSecondLineComponent implements OnInit {
	@Input() isDocumentation: boolean = false;

	@Input() headerData: any = null;
	@Input() isDataLoadedHeaderData: boolean = false;

	constructor() {}

	ngOnInit() {}
}
