<div
	class="attachContainer"
	(click)="openFile(options.url)"
>
	<div class="icon">
		<div class="white">
			<img
				src="/assets/Icons/Messages/line.svg"
				alt=""
			/>
			<img
				src="/assets/Icons/Messages/line.svg"
				alt=""
			/>
			<img
				src="/assets/Icons/Messages/line.svg"
				alt=""
			/>
			<img
				src="/assets/Icons/Messages/line.svg"
				alt=""
			/>
			<img
				src="/assets/Icons/Messages/line.svg"
				alt=""
			/>
		</div>
	</div>
	<span>{{ options.title }}</span>
</div>
