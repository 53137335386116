<div
	class="case"
	[ngClass]="{ selectedCommercialOffer: isSelectCommercialOffer }"
	id="{{ card.id }}"
>
	<div class="heart"></div>

	<div class="face">
		<img
			src="/assets/Icons/EmojisCases/sadFace.svg"
			alt=""
		/>
	</div>

	<div
		class="caseTitleAndImage"
		skeleton
		[isDataLoaded]="isDataLoaded"
	>
		<img
			class="caseImage"
			src="{{ card.icon }}"
			alt="caseImage"
			(error)="setPlaceholderImage($event)"
		/>
		<p class="caseTitle">{{ card.name }}</p>
	</div>

	<div
		class="informationBlock"
		skeleton
		[isDataLoaded]="isDataLoaded"
	>
		<div class="formTo">
			<p class="info">{{ card.departurePort }}</p>
			<span class="line"></span>
			<span class="iconBox">
				<img
					src="/assets/Icons/GeneraalUseIcons/PackageBox.svg"
					alt=""
				/>
			</span>
			<span class="line"></span>
			<p class="info">{{ card.arrivalPort }}</p>
		</div>

		<div
			class="characteristicsContainer"
			skeleton
			[isDataLoaded]="isDataLoaded"
		>
			<div class="weightAndSurface">
				<div class="characteristicPill">
					<img
						src="/assets/Icons/GeneraalUseIcons/weight.svg"
						alt=""
					/>
					<p>{{ card.grossWeight }}</p>
				</div>
				<div class="characteristicPill">
					<img
						src="/assets/Icons/GeneraalUseIcons/surface.svg"
						alt=""
					/>
					<p>
						{{ card.volume }} m
						<sup>3</sup>
					</p>
				</div>
			</div>
			<div
				class="characteristicPill"
				skeleton
				[isDataLoaded]="isDataLoaded"
			>
				<p>ETA:</p>
				<p class="light">{{ card.arrivalDate }}</p>
			</div>
		</div>
	</div>

	<div
		class="informationBlock"
		skeleton
		[isDataLoaded]="isDataLoaded"
	>
		<div class="dificulty">
			<p>Difficulty</p>
			<div class="dificultymeassure">
				<span
					class="line"
					[ngClass]="{ orange: card.difficulty > 0 && card.difficulty <= 3 }"
				></span>
				<span
					class="line"
					[ngClass]="{ orange: card.difficulty == 2 || card.difficulty == 3 }"
				></span>
				<span
					class="line"
					[ngClass]="{ orange: card.difficulty == 3 }"
				></span>
			</div>
		</div>
	</div>

	<div
		class="buttonLine"
		[ngClass]="{ center: isSelectCommercialOffer }"
		skeleton
		[isDataLoaded]="isDataLoaded"
	>
		<btn
			classList="generalButton red action"
			(click)="rejectEmmiter()"
			*ngIf="!isSelectCommercialOffer"
		>
			Reject
		</btn>
		<btn
			classList="generalButton green action"
			(click)="acceptEmmiter()"
			*ngIf="!isSelectCommercialOffer"
		>
			Accept
		</btn>

		<btn
			classList="generalButton green action widthFit"
			(click)="selectThisCaseForCommercialOffer()"
			*ngIf="isSelectCommercialOffer"
		>
			Select
		</btn>
	</div>
</div>
