<span
	class="whiteContainer"
	[ngClass]="{ green: options.accepted }"
	(click)="clicked($event.currentTarget)"
>
	<mat-icon
		class="material-icons-outlined"
		*ngIf="options.icon"
		(click)="$event.preventDefault()"
	>
		{{ options.icon }}
	</mat-icon>
	<img
		src="{{ options.image }}"
		alt=""
		*ngIf="options.image"
		(click)="$event.preventDefault()"
	/>
</span>
