import {
	Component,
	EventEmitter,
	HostListener,
	OnInit,
	Output
} from '@angular/core';
import {
	StaticUtilitiesService,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { LoginService } from 'src/app/Services/Api/login.service';
import { ResponseMessageBridgeService } from 'src/app/Services/Utils/responseMessageBridge.service';

@Component({
	selector: 'sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.modalOpened ? this.toggleOpenModal() : null;
		}
	}

	modalOpened: boolean = false;
	urlActive: string = '';
	roles: string[] = LoginService.getUserPermissionsAndRoles();

	constructor(
		private staticUtilitiesSE: StaticUtilitiesService,
		private responseMessagebridgeSE: ResponseMessageBridgeService,
		private loginSE: LoginService
	) {}

	ngOnInit(): void {
		this.urlActive = this.staticUtilitiesSE.getActualRoute();
	}

	/**
	 * FUNCTIONALITIES
	 */
	logOut() {
		StaticUtilitiesService.showFeedback('Successfully logged out');
		this.staticUtilitiesSE.goTo('/', slideNavigationTypes.slideToBottom);
		this.loginSE.logOut();
		this.responseMessagebridgeSE.toggleOpenNewMessage(false);
	}
	toggleOpenModal() {
		this.modalOpened = !this.modalOpened;
	}
	goToHome() {
		this.staticUtilitiesSE.goTo('/home', slideNavigationTypes.slideToBottom);
	}
	goToThirdParties() {
		this.staticUtilitiesSE.goTo(
			'/third-parties',
			slideNavigationTypes.slideToTop
		);
	}
	goToOffers() {
		this.staticUtilitiesSE.goTo(
			'/commercial-offers',
			slideNavigationTypes.slideToTop
		);
	}
	goToCreateCommercialOffer() {
		this.staticUtilitiesSE.getActualRoute();
		this.staticUtilitiesSE.goTo(
			'/cases/commercial-offer-cases-selection',
			slideNavigationTypes.slideToTop
		);
	}
	goToCreateShipment() {
		this.staticUtilitiesSE.getActualRoute();
		this.staticUtilitiesSE.goTo(
			'/shipment/selector',
			slideNavigationTypes.slideToTop
		);
	}
	goToShippment() {
		this.staticUtilitiesSE.goTo('/shipment', slideNavigationTypes.slideToTop);
	}
}
