<div class="userPillGeneralContainer">
	<div class="userInfo">
		<div class="profileImage">
			<img
				src="/assets/Images/Placeholder/userPlaceholder.png"
				alt=""
				*ngIf="false"
			/>
			<span class="initials">
				{{ name.split(' ')[0][0] }}{{ name.split(' ')[1][0] }}
			</span>
			<progressGradientBar [value]="performance * 10"></progressGradientBar>
		</div>
		<span class="name">{{ name }}</span>
	</div>
	<div class="separator"></div>
	<div class="performance">
		<label class="performanceLabel">Performance</label>
		<span class="assessment">{{ performance }}</span>
	</div>
</div>
