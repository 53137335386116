import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCommercialOfferListQueryParams } from 'src/app/Shared/Interfaces/Api/Commercial-Offer/iCommercialOfferListQueryParams';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
	providedIn: 'root'
})
export class CommercialOfferService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	getCommercialOfferList(
		behaviorSubject: BehaviorSubject<any>,
		queryParams: iCommercialOfferListQueryParams
	) {
		this.http
			.get(
				this.urlApi + '/api/offers' + this.optionsGet(queryParams),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNamesCollection(data.data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: { data: normalizedCommercialOfferList, total: data.total }
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getCommercialOfferList,
						args: [behaviorSubject, queryParams]
					});
				}
			});
	}

	getOfferGeneral(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/offer/general/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferGeneral,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getOfferShippingCargo(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/offer/shippingcargo/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferShippingCargo,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getOfferFreight(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/offer/freight/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferFreight,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getOfferCustoms(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/offer/customs/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferCustoms,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getOfferInlandTransportation(
		behaviorSubject: BehaviorSubject<any>,
		id: number
	) {
		this.http
			.get(this.urlApi + '/api/offer/inland/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferInlandTransportation,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getOfferOtherCosts(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/offer/othercosts/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferOtherCosts,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getAcceptedOffersByStudentForShipment(
		behaviorSubject: BehaviorSubject<any>,
		params: any
	) {
		this.http
			.get(
				this.urlApi + '/api/offers/accepted' + this.optionsGet(params),
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					const normalizedCommercialOfferList =
						iStaticUtilities.normalizeNames(data);

					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: normalizedCommercialOfferList
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferOtherCosts,
						args: [behaviorSubject, params]
					});
				}
			});
	}

	private processStatesSelector(
		states: any,
		behaviorSubject: BehaviorSubject<any>
	) {
		this.res = new iResultHttp({
			status: 200,
			ok: true,
			message: '',
			data: states
		});
		this.sendNextObservable(behaviorSubject, this.res);
	}

	getCommercialOfferStatesSelector(behaviorSubject: BehaviorSubject<any>) {
		const statesFromStorage = LocalStorageHandlerService.getEncodedItem(
			'commercial-offer-states'
		);

		if (statesFromStorage) {
			this.processStatesSelector(statesFromStorage, behaviorSubject);
			return;
		}

		this.http
			.get(this.urlApi + '/api/offer/state/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					LocalStorageHandlerService.setEncodedItem(
						'commercial-offer-states',
						data
					);
					this.processStatesSelector(data, behaviorSubject);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getOfferOtherCosts,
						args: [behaviorSubject]
					});
				}
			});
	}

	create(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.post(this.urlApi + '/api/offer', data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.create,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateGeneralDescription(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/offer/general/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateGeneralDescription,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	transformOfferIntoShipment(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/offer/transform/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The shipment has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.transformOfferIntoShipment,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	submitOffer(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(this.urlApi + '/api/offer/submit/' + data.id, data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.submitOffer,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateShippingCargo(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/offer/shippingcargo/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateShippingCargo,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateCustoms(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(
				this.urlApi + '/api/offer/customs/' + data.id,
				data,
				this.getHeader()
			)
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateCustoms,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	updateInlandTransportation(behaviorSubject: BehaviorSubject<any>, data: any) {
		this.http
			.put(this.urlApi + '/api/offer/inland/' + data.id, data, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been created.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.updateInlandTransportation,
						args: [behaviorSubject, data]
					});
				}
			});
	}

	setStateAsDraft(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.put(this.urlApi + '/api/offer/state/' + id, {}, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: 'The commercial offer has been updated.',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.setStateAsDraft,
						args: [behaviorSubject, id]
					});
				}
			});
	}

	getPdfUrl(behaviorSubject: BehaviorSubject<any>, id: number) {
		this.http
			.get(this.urlApi + '/api/offer/docs/' + id, this.getHeader())
			.subscribe({
				next: (data: any) => {
					this.res = new iResultHttp({
						status: 200,
						ok: true,
						message: '',
						data: data
					});
					this.sendNextObservable(behaviorSubject, this.res);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getPdfUrl,
						args: [behaviorSubject, id]
					});
				}
			});
	}
}
