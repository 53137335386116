import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { iCommercialOfferTableContainerHeaderLine } from '../../Interfaces/Utils/iCommercialOfferTableContainer';
import { iCalculateCostMarginPriceObject } from '../../Interfaces/Utils/iCalculateCostMarginPriceObject';

export class AddInlandTransportationCostPopupClass {
	isChecked: boolean = false;

	// ngModels
	calculatedObjectPrice: iCalculateCostMarginPriceObject = {
		cost: null,
		margin: null,
		price: null
	};
	targetKm: number | null = null;
	calculationRule: number | null = 1;
	destination: string | null = null;
	origin: string | null = null;
	pricePerKmAboveTheScaling: iCalculateCostMarginPriceObject = {
		cost: null,
		margin: null,
		price: null
	};
	costTitleConcept: string | null = null;

	// Arrays
	containerTypesLines: {
		quantity: number;
		containerType: number | null;
		cost: number | null;
		margin: number | null;
		price: number | null;
	}[] = [
		{
			quantity: 0,
			containerType: null,
			cost: null,
			margin: null,
			price: null
		}
	];

	scalingLines: {
		upToKm: number | null;
		cost: number | null;
		margin: number | null;
		price: number | null;
	}[] = [
		{
			upToKm: null,
			cost: null,
			margin: null,
			price: null
		}
	];

	costTitleConceptCustomSelectorOptions: iOptionsSelector = {
		items: [],
		placeholder: 'Cost title concept (Denomination to print)',
		classList: 'specialFormSelector',
		append: true
	};
	quantityCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Quantity',
		type: 'number',
		classList: 'form',
		label: 'Quantity',
		required: true
	};
	originCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Origin',
		type: 'text',
		classList: 'form',
		label: 'Origin',
		required: true
	};
	destinationCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Destination',
		type: 'text',
		classList: 'form',
		label: 'Destination',
		required: true
	};
	distanceCustomInputoptions: iOptionsCustomInput = {
		placeholder: 'Distance (km)',
		type: 'text',
		classList: 'form',
		label: 'Distance (km)',
		required: true
	};
	upToKmCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Up to km',
		type: 'number',
		classList: 'form',
		label: 'Up to km',
		required: true
	};

	// Selector Options
	calculationRuleSelectorOptions: iOptionsSelector = {
		items: StaticDataHandlerService.getCalculationRules(),
		bindLabel: 'name',
		bindValue: 'id',
		classList: 'specialFormSelector',
		search: true,
		placeholder: 'Calculation rule',
		append: true
	};
	containerTypeSelectorOptions: iOptionsSelector = {
		items: [],
		bindLabel: 'name',
		bindValue: 'id',
		classList: 'specialFormSelector',
		search: true,
		placeholder: 'Container type',
		append: true
	};
}
