import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ResponseMessageBridgeService {
	private _toggleOpenResponseMessageBehaviourSubject = new BehaviorSubject<any>(
		null
	);
	private _toggleOpenNewMessageBehaviourSubject = new BehaviorSubject<any>(
		null
	);
	private _toggleOpenThreadMessageListBehaviourSubject =
		new BehaviorSubject<any>(null);

	private _updateThreadData = new BehaviorSubject({
		update: false
	});
	private _updateThirdData = new BehaviorSubject({
		update: false
	});
	private _messageCount: BehaviorSubject<number> = new BehaviorSubject<number>(
		0
	);

	constructor() {}

	toggleOpenResponseMessage(open: boolean) {
		this._toggleOpenResponseMessageBehaviourSubject.next(open);
		setTimeout(() => {
			this._toggleOpenResponseMessageBehaviourSubject.next(null);
		}, 150);
	}
	getToggleOpenResponseMessage() {
		return this._toggleOpenResponseMessageBehaviourSubject;
	}

	toggleOpenNewMessage(open: boolean) {
		this._toggleOpenNewMessageBehaviourSubject.next(open);
		setTimeout(() => {
			this._toggleOpenNewMessageBehaviourSubject.next(null);
		}, 150);
	}

	getToggleOpenNewMessage() {
		return this._toggleOpenNewMessageBehaviourSubject;
	}
	toggleOpenThreadMessageList(open: boolean) {
		this._toggleOpenThreadMessageListBehaviourSubject.next(open);
		setTimeout(() => {
			this._toggleOpenThreadMessageListBehaviourSubject.next(null);
		}, 150);
	}

	getToggleOpenThreadMessageList() {
		return this._toggleOpenThreadMessageListBehaviourSubject;
	}

	updateMessagesData() {
		this._updateThreadData.next({ update: true });
	}
	getUpdateThreadData() {
		return this._updateThreadData;
	}
	updateThirdData() {
		this._updateThirdData.next({ update: true });
	}
	getUpdateThirdData() {
		return this._updateThirdData;
	}
	setMessageCount(count: number) {
		this._messageCount.next(count);
	}
	getMessageCount() {
		return this._messageCount;
	}
}
