import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	DefaultBaseService,
	iResultHttp,
	iStaticUtilities
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iContainerTypeSelector } from 'src/app/Shared/Interfaces/Api/ContainerType/iContainerTypeSelector';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
	providedIn: 'root'
})
export class ContainerTypeService extends DefaultBaseService {
	res: iResultHttp = new iResultHttp();
	constructor(private http: HttpClient) {
		super();
	}

	private processContainerTypes(
		containerTypes: any,
		behaviorSubject: BehaviorSubject<any>
	) {
		const normalizedContainerTypes =
			iContainerTypeSelector.convertFromCollection(
				iStaticUtilities.normalizeNamesCollection(containerTypes)
			);
		this.res = new iResultHttp({
			status: 200,
			ok: true,
			message: '',
			data: normalizedContainerTypes
		});
		this.sendNextObservable(behaviorSubject, this.res);
	}

	getContainerTypeSelector(behaviorSubject: BehaviorSubject<any>) {
		const containerTypesFromStorage =
			LocalStorageHandlerService.getEncodedItem('containerTypes');

		if (containerTypesFromStorage) {
			this.processContainerTypes(containerTypesFromStorage, behaviorSubject);
			return;
		}

		this.http
			.get(this.urlApi + '/api/container/type/selector', this.getHeader())
			.subscribe({
				next: (data: any) => {
					LocalStorageHandlerService.setEncodedItem('containerTypes', data);
					this.processContainerTypes(data, behaviorSubject);
				},
				error: (error: any) => {
					this.res = new iResultHttp({
						status: error.status,
						ok: false,
						message: error.error.message
					});
					this.sendNextObservable(behaviorSubject, this.res, true, {
						method: this.getContainerTypeSelector,
						args: [behaviorSubject]
					});
				}
			});
	}
}
