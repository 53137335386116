import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	StaticUtilitiesService,
	iResultHttp,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CostConceptService } from 'src/app/Services/Api/cost-concept.service';
import {
	ThirdPartyService,
	thirdPartyTypes
} from 'src/app/Services/Api/third-party.service';
import { CalculateCostMarginPriceService } from 'src/app/Services/Utils/CalculateCostMarginPrice.service';
import { AddCustomCostsPopupClass } from 'src/app/Shared/Class/commercial-offers/AddCustomCostsPopupClass';
import { iCalculateCostMarginPriceObject } from 'src/app/Shared/Interfaces/Utils/iCalculateCostMarginPriceObject';

@Component({
	selector: 'app-addCustomCosts-popup',
	templateUrl: './addCustomCosts-popup.component.html',
	styleUrls: ['./addCustomCosts-popup.component.scss']
})
export class AddCustomCostsPopupComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.closePopup('noreturn');
		}
	}

	addCustomCostsPopupClass: AddCustomCostsPopupClass =
		new AddCustomCostsPopupClass();

	constructor(
		public dialogRef: MatDialogRef<AddCustomCostsPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any,
		private thirdPartiesSE: ThirdPartyService,
		private calculateCostMarginPriceSE: CalculateCostMarginPriceService,
		private costConceptSE: CostConceptService,
		private staticUtilitiesSE: StaticUtilitiesService
	) {}

	ngOnInit() {
		this.getBroker();
		this.getSelector();
	}

	/**
	 * HANDLERS
	 */

	saveBroker(res: iResultHttp) {
		let { data } = res;
		this.addCustomCostsPopupClass.customAgentCompanySelectorOptions.items =
			data;
	}

	successGetSelectorHandler(res: iResultHttp) {
		this.addCustomCostsPopupClass.contTitleSelector.items = res.data;
	}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}
	generateBodyToReturn() {
		this.addCustomCostsPopupClass.rellenarBodyToReturn();
		let body = this.addCustomCostsPopupClass.bodyToReturn;
		this.closePopup(body);
	}

	getCalculatedObject(event: iCalculateCostMarginPriceObject) {
		this.addCustomCostsPopupClass.calculateObject = event;
		this.checkIfCompleted();
	}

	goToThirdParties() {
		this.staticUtilitiesSE.goTo(
			'/third-parties',
			slideNavigationTypes.slideToTop
		);
	}

	checkIfCompleted() {
		const objectToCheck: any = {
			cost: this.addCustomCostsPopupClass.calculateObject.cost,
			amount: this.addCustomCostsPopupClass.calculateObject.price,
			customsUnit: this.addCustomCostsPopupClass.customsUnit,
			selectUnit: this.addCustomCostsPopupClass.concept
		};

		this.addCustomCostsPopupClass.isChecked =
			StaticUtilitiesService.isCompleteObject(objectToCheck);
	}

	/**
	 * API CALLS
	 */

	getBroker() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.thirdPartiesSE.getTypeSelector(behaviorSubject, [
			thirdPartyTypes.customsBroker
		]);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: this.saveBroker(res)
				});
			});
	}

	getSelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.costConceptSE.getSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetSelectorHandler(res)
				});
			});
	}
}
