import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iFileWithBase64 } from '../../Interfaces/Utils/iFileWithBase64';

@Component({
	selector: 'files-area',
	templateUrl: './files-area.component.html',
	styleUrls: ['./files-area.component.scss']
})
export class FilesAreaComponent implements OnInit {
	@Input() supportedFormats: string[] = ['.pdf'];
	@Input() multiple: boolean = false;
	@Output() filesChange: EventEmitter<iFileWithBase64[]> = new EventEmitter<
		iFileWithBase64[]
	>();

	ngFiles: iFileWithBase64[] = [];
	files: iFileWithBase64[] = [];

	constructor() {}

	ngOnInit(): void {}

	/**
	 * FUNCIONALITIES
	 */

	deleteFile(index: number | null) {
		if (index === null) {
			this.files = [];
			return;
		}
		this.files.splice(index, 1);
	}

	checkDuplicateFiles(targetFiles: iFileWithBase64[]) {
		const filtertArray = targetFiles.filter((file) => {
			const sameName = this.files.some((f) => f.file.name === file.file.name);
			const sameSize = this.files.some((f) => f.file.size === file.file.size);
			const isSameFile = sameName && sameSize;
			return !isSameFile;
		});

		return filtertArray;
	}

	getFile(newFiles: iFileWithBase64[]) {
		if (!this.multiple) {
			this.files = newFiles;
			this.filesChange.emit(this.files);
			return;
		}

		const checkedFiles = this.checkDuplicateFiles(newFiles);
		this.files = [...this.files, ...checkedFiles];
		this.filesChange.emit(this.files);
	}
}
