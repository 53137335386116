import { Component, OnInit } from '@angular/core';
import {
	StaticUtilitiesService,
	iStaticUtilities,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { NavHunterOptions } from 'src/app/Shared/Class/nav-hunter/nav-hunter-options';

@Component({
	selector: 'nav-hunter-sub-header',
	templateUrl: './nav-hunter-sub-header.component.html',
	styleUrls: ['./nav-hunter-sub-header.component.scss']
})
export class NavHunterSubHeaderComponent implements OnInit {
	navHunterOptions: NavHunterOptions = new NavHunterOptions();

	constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

	ngOnInit(): void {
		this.getBodyFromSessionStorage();
	}

	getBodyFromSessionStorage() {
		let encoded = sessionStorage.getItem('shippingWebStudentAnswer')!;

		if (!encoded) {
			this.staticUtilitiesSE.goTo(
				'nav-hunter/home',
				slideNavigationTypes.slideToRight
			);
		}

		let stringified = iStaticUtilities.simpleDecrypt(encoded);
		let body = JSON.parse(stringified);
		this.navHunterOptions.body = body;

		this.setBody(body);
	}

	setBody(body) {
		this.navHunterOptions.portOfOrigin = body.portOfOrigin;
		this.navHunterOptions.portOfDestination = body.portOfDestination;
		this.navHunterOptions.type = body.type;
		this.navHunterOptions.shippmentDetails = body.shippmentDetails;
		this.navHunterOptions.desiredDepartureDate = body.desiredDepartureDate;
	}
}
