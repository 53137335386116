<div
	class="customFormContainer"
	*ngIf="!readOnly"
>
	<label
		class="bold"
		*ngIf="label"
	>
		{{ label }}
		<sup
			style="color: red"
			*ngIf="required"
		>
			*
		</sup>
	</label>
	<selector
		ngDefaultControl
		[name]="name"
		[ngModel]="model"
		(ngModelChange)="onModelChange($event)"
		(change)="sendNgModel($event); onNgModelChange($event)"
		[options]="optionsSelector"
	></selector>
</div>

<customInput
	[options]="customInputOptionsForReadOnly"
	[ngModel]="customInputValue"
	*ngIf="readOnly"
></customInput>
