<div class="confirmPopupGeneralContainer">
	<div class="body">
		<p class="title">
			{{
				modalData.option === 'otherCosts'
					? 'Add other cost'
					: 'Add a new freight to the offer'
			}}
		</p>
		<div class="line doble">
			<div class="customFormContainer">
				<label class="generalLabel">
					Cost concept
					<sup class="red">*</sup>
				</label>
				<selector
					[(ngModel)]="addCostPopupClass.costConcept"
					[options]="addCostPopupClass.costConceptSelectorOptions"
					(change)="checkIfCompleted()"
				></selector>
			</div>
			<div class="customFormContainer">
				<label class="generalLabel">
					Factor
					<sup class="red">*</sup>
				</label>
				<selector
					[options]="addCostPopupClass.factorSelectorOptions"
					(change)="checkIfCompleted()"
					[(ngModel)]="addCostPopupClass.factor"
				></selector>
			</div>
		</div>

		<cost-margin-price
			(calculated)="getCalculatedObject($event)"
		></cost-margin-price>
		<div class="line">
			<customInput
				[(ngModel)]="addCostPopupClass.costTitel"
				[options]="addCostPopupClass.costTitleConceptCustomInputOptions"
				(change)="checkIfCompleted()"
			></customInput>
		</div>
	</div>
	<div class="footer">
		<btn
			[classList]="'generalButton action red'"
			(click)="closePopup('noreturn')"
		>
			<ng-container>
				<span>Discard</span>
			</ng-container>
		</btn>
		<btn
			[classList]="'generalButton action green'"
			[ngClass]="{ disabled: !addCostPopupClass.isChecked }"
			(click)="generateBodyToReturn()"
		>
			<ng-container>
				<span>Add cost</span>
			</ng-container>
		</btn>
	</div>
</div>
