import {
	Component,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core';
import {
	StaticUtilitiesService,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';

@Component({
	selector: 'message-notification',
	templateUrl: './message-notification.component.html',
	styleUrls: ['./message-notification.component.scss']
})
export class MessageNotificationComponent implements OnInit {
	@ViewChild('notification') notification!: ElementRef;

	@Input() isNotificationActive: boolean = false;

	constructor(
		private staticUtilitiesSE: StaticUtilitiesService,
		private renderer: Renderer2
	) {}

	ngOnInit() {}

	ngAfterViewInit() {
		setTimeout(() => {
			const messageElement = document.getElementById('message');
			if (messageElement) {
				const rect = messageElement.getBoundingClientRect();
				this.renderer.setStyle(
					this.notification.nativeElement,
					'position',
					'absolute'
				);
				this.renderer.setStyle(
					this.notification.nativeElement,
					'top',
					`${rect.top + 100}px`
				);
				this.renderer.setStyle(
					this.notification.nativeElement,
					'left',
					`${rect.left}px`
				);
			}
		}, 100);
	}

	goToMessages() {
		this.staticUtilitiesSE.goTo(
			'/messages',
			slideNavigationTypes.slideToBottom
		);
	}
}
