import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';
import { iFileWithBase64 } from '../../Interfaces/Utils/iFileWithBase64';

export class AddShipmentDocumentClass {
	// Custom input options
	inputOptions: iOptionsCustomInput = {
		type: 'text',
		placeholder: 'Write a name',
		label: 'Document name',
		required: true
	};

	// Custom selector options
	selectorOptions: iOptionsSelector = {
		items: [],
		classList: 'specialFormSelector',
		placeholder: 'Select a type'
	};

	// Files
	files: iFileWithBase64[] = [];

	// PopUp data
	modelName: string = '';
	modelType: string = '';
}
