import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'case-emoji',
	templateUrl: './case-emoji.component.html',
	styleUrls: ['./case-emoji.component.scss']
})
export class CaseEmojiComponent implements OnInit {
	@Input() emoji: string = '';

	constructor() {}

	ngOnInit(): void {}
}
