import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { ShipmentCreateNotifyClass } from '../../../Class/shipment/ShipmentCreateNotifyClass';
import { BookingService } from 'src/app/Services/Api/booking.service';
import { ThirdPartyService } from 'src/app/Services/Api/third-party.service';
import {
	StaticUtilitiesService,
	iResultHttp,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ThirdPartySelectorClass } from '../../../Class/ThirdPartySelectorClass';
import { iThirdPartySelectorOutput } from '../../../Interfaces/Api/ThirdParty/iThirdPartySelector';

@Component({
	selector: 'third-party-selector',
	templateUrl: './third-party-selector.component.html',
	styleUrls: ['./third-party-selector.component.scss']
})
export class ThirdPartySelectorComponent implements OnInit {
	extraClass: ThirdPartySelectorClass = new ThirdPartySelectorClass();

	// Emite el indice y los ids de la third party y la address seleccionada
	@Output() changeThirdParty: EventEmitter<iThirdPartySelectorOutput> =
		new EventEmitter<iThirdPartySelectorOutput>();
	@Output() clickOnDelete: EventEmitter<null> = new EventEmitter<null>();
	// Recibe los tipos de third party que se pueden seleccionar
	@Input() thirdPartyTypes: number[] = this.extraClass.requiredThirdPartyTypes;
	// Recibe si se puede editar o no
	@Input() ableToEdit: boolean = false;
	// Recibe el id de la third party seleccionada
	@Input() selectedThirdParty: number | null = null;
	// Recibe el id de la address seleccionada
	@Input() selectedAddress: number | null = null;
	// Recibe si tiene footer con la información extra o no
	@Input() haveFooter: boolean = false;
	// Si es un array, recibe la longitud del array
	@Input() arrayLength: number = 0;
	// Puede recibir el titulo del selector
	@Input() title: string = '';
	// Puede recibir si tiene botones laterales
	@Input() hasSideButtons: boolean = true;
	// Puede labels personalizados
	@Input() labels = {
		thirdPartyTitle: 'Third Party',
		addressTitle: 'Address'
	};
	// Puede recibir si es requerido
	@Input() required: boolean = false;

	isFirstFetch: boolean = true;

	constructor(
		private thirdPartySE: ThirdPartyService,
		private staticUtilitiesSE: StaticUtilitiesService
	) {}

	ngOnInit() {
		this.getThirdParties();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['selectedThirdParty']) {
			if (changes['selectedThirdParty'].currentValue && this.isFirstFetch) {
				this.obtainExtraData();
				if (changes['selectedAddress']) {
					if (changes['selectedAddress'].currentValue) {
						this.getCompleteAddress(changes['selectedAddress'].currentValue);
					}
				}
				this.isFirstFetch = false;
			}
		}
		if (changes['labels']) {
			if (changes['labels'].currentValue) {
				this.extraClass.readOnlyThird.label =
					changes['labels'].currentValue.thirdPartyTitle;
				this.extraClass.readOnlyAddress.label =
					changes['labels'].currentValue.addressTitle;
			}
		}
	}

	/**
	 * HANDLERS
	 */

	successGetThirdPartiesHandler(res: iResultHttp) {
		const { data } = res;
		this.extraClass.nameSelectorOptions.items = res.data;
		if (this.selectedThirdParty) {
			this.extraClass.previewThird = data.find(
				(element) => element.id === this.selectedThirdParty
			)?.name;
		}
	}

	handleChangeSelector(isThirdPartyChange: boolean = false) {
		this.extraClass.previewAddress = '';
		if (isThirdPartyChange) {
			this.selectedAddress = null;
			this.extraClass.previewAddress = '';
			if (this.selectedThirdParty) this.obtainExtraData();
		}

		if (this.selectedAddress) {
			// this.extraClass.previewAddress = this.extraClass.addressSelectorOptions.items.find((element) => element.id === this.selectedAddress).address;
			this.getCompleteAddress(this.selectedAddress);
		}

		this.changeThirdParty.emit({
			thirdPartyId: this.selectedThirdParty,
			addressId: this.selectedAddress
		});
	}

	handleClickOnDelete() {
		this.clickOnDelete.emit();
	}

	successGetAddressesHandler(res: iResultHttp) {
		let { data } = res;
		this.extraClass.addressSelectorOptions.items = data;
	}

	successGetThirdPartyDataHandler(res: iResultHttp) {
		const { data } = res;
		const { contactPerson, cif, email, phoneOne } = data;
		const newData = {
			thirdName: contactPerson,
			thirdCif: cif,
			thirdEmail: email,
			thirdPhone: phoneOne
		};
		this.extraClass.footerData = newData;
	}

	successGetCompleteAddressHandler(res: iResultHttp) {
		const { data } = res;
		this.extraClass.previewAddress = data.address;
	}

	/**
	 * FUNCTIONS
	 */

	getSelectedAddress(arr: any[], target: number) {
		return arr.find((element) => element.id === target);
	}

	obtainExtraData() {
		if (this.selectedThirdParty) {
			this.getAddresses(this.selectedThirdParty);
			if (!this.haveFooter) return;
			this.getThirdPartyData(this.selectedThirdParty);
		}
	}

	goToThird() {
		this.staticUtilitiesSE.goTo(
			'third-parties/new-third-party',
			slideNavigationTypes.slideToBottom
		);
	}

	goToAddress() {
		this.staticUtilitiesSE.goTo(
			'/third-parties/edit-third-party',
			slideNavigationTypes.slideToTop,
			{ thirdParty: this.selectedThirdParty }
		);
	}

	/**
	 * API CALLS
	 */

	// Se llaman todas las third parties
	getThirdParties() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.thirdPartySE.getTypeSelector(behaviorSubject, this.thirdPartyTypes);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetThirdPartiesHandler(res)
				});
			});
	}

	// Con el id del third party seleccionado te devuelve las direcciones
	getAddresses(id: number) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.thirdPartySE.getAddresses(behaviorSubject, id);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetAddressesHandler(res)
				});
			});
	}

	// Con el id del third party seleccionado te devuelve los datos del third party
	getThirdPartyData(id: number | string) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.thirdPartySE.getSingle(behaviorSubject, Number(id));
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetThirdPartyDataHandler(res)
				});
			});
	}

	getCompleteAddress(id: number) {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.thirdPartySE.getCompleteAddress(behaviorSubject, id);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetCompleteAddressHandler(res)
				});
			});
	}
}
