<div class="confirmPopupGeneralContainer">
	<div class="body">
		<p class="title">Add a new freight to the offer</p>

		<div class="line doble">
			<div class="customFormContainer">
				<label class="generalLabel">
					Port of origin
					<sup class="red">*</sup>
				</label>
				<selector
					[(ngModel)]="addFreightPopup.origin"
					[options]="addFreightPopup.portOfOriginSelectorOptions"
					(change)="checkIfCompleted()"
				></selector>
			</div>
			<div class="customFormContainer">
				<label class="generalLabel">
					Port of destination
					<sup class="red">*</sup>
				</label>
				<selector
					[(ngModel)]="addFreightPopup.destination"
					[options]="addFreightPopup.portOfDestinationSelectorOptions"
					(change)="checkIfCompleted()"
				></selector>
			</div>
		</div>

		<cost-margin-price
			(calculated)="getCalculatedObject($event)"
		></cost-margin-price>
	</div>
	<div class="footer">
		<btn
			[classList]="'generalButton action red'"
			(click)="closePopup('noreturn')"
		>
			<ng-container>
				<span>Discard</span>
			</ng-container>
		</btn>
		<btn
			[classList]="'generalButton action green'"
			[ngClass]="{ disabled: !addFreightPopup.isChecked }"
			(click)="generateBodyToReturn()"
		>
			<ng-container>
				<span>Add cost</span>
			</ng-container>
		</btn>
	</div>
</div>
