<div class="confirmPopupGeneralContainer">
	<div class="body">
		<p class="title">Add customs cost</p>

		<div class="line doble">
			<div class="customFormContainer">
				<label class="generalLabel">
					Cost concept
					<sup class="red">*</sup>
				</label>
				<selector
					[(ngModel)]="addCustomCostsPopupClass.concept"
					[options]="addCustomCostsPopupClass.contTitleSelector"
					(change)="checkIfCompleted()"
				></selector>
			</div>
			<div class="customFormContainer">
				<label class="generalLabel">
					Select Unit
					<sup class="red">*</sup>
				</label>
				<selector
					[options]="addCustomCostsPopupClass.selectUnitSelectorOptions"
					[(ngModel)]="addCustomCostsPopupClass.customsUnit"
					(change)="checkIfCompleted()"
				></selector>
			</div>
		</div>

		<cost-margin-price
			(calculated)="getCalculatedObject($event)"
		></cost-margin-price>
	</div>
	<div class="footer">
		<btn
			[classList]="'generalButton action red'"
			(click)="closePopup('noreturn')"
		>
			<ng-container>
				<span>Discard</span>
			</ng-container>
		</btn>
		<btn
			[classList]="'generalButton action green'"
			[ngClass]="{ disabled: !addCustomCostsPopupClass.isChecked }"
			(click)="generateBodyToReturn()"
		>
			<ng-container>
				<span>Add cost</span>
			</ng-container>
		</btn>
	</div>
</div>
