export interface IStatisticsPill {
	type: 'metrics';
	text: string;
	data: any;
	dataType: 'currency' | 'number' | 'percentaje' | 'string';
	id?: string;
}

export class IStatisticsPill implements IStatisticsPill {
	constructor() {}
}
