import {
	iOptionsCustomInput,
	iOptionsSelector
} from '@quasar-dynamics/basic-designsystem';
import { iCalculateCostMarginPriceObject } from '../../Interfaces/Utils/iCalculateCostMarginPriceObject';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

export class AddCustomCostsPopupClass {
	// ngModels
	calculateObject: iCalculateCostMarginPriceObject = {
		cost: null,
		margin: null,
		price: null
	};
	customsUnit: string | null = null;

	isChecked: boolean = false;

	concept: string | null = null;

	// Custom Input options
	costCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Cost',
		type: 'number',
		classList: 'form',
		label: 'Cost',
		required: true
	};
	marginCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Margin',
		type: 'number',
		classList: 'form',
		label: 'Margin',
		readOnly: true,
		required: true
	};
	priceCustomInputOptions: iOptionsCustomInput = {
		placeholder: 'Price',
		type: 'number',
		classList: 'form',
		label: 'Price',
		readOnly: true,
		required: true
	};
	contTitleSelector: iOptionsSelector = {
		items: [],
		placeholder: 'Cost title concept (Denomination to print)',
		classList: 'specialFormSelector',
		search: true,
		append: true
	};

	// Selector options
	customAgentCompanySelectorOptions: iOptionsSelector = {
		items: [],
		bindLabel: 'name',
		bindValue: 'id',
		placeholder: 'Custom agent company',
		search: true,
		classList: 'specialFormSelector'
	};
	selectUnitSelectorOptions: iOptionsSelector = {
		items: StaticDataHandlerService.getSelectUnitCustomsCost(),
		placeholder: 'Select Unit',
		search: true,
		classList: 'specialFormSelector',
		append: true
	};
	bodyToReturn;
	rellenarBodyToReturn() {
		//convertir los costes en number decimal
		this.calculateObject.cost = Number(this.calculateObject.cost);
		this.calculateObject.margin = Number(this.calculateObject.margin);
		this.calculateObject.price = Number(this.calculateObject.price);

		//convertir el margen en porcentaje
		this.calculateObject.margin = this.calculateObject.margin / 100;
		this.bodyToReturn = {
			cost: this.calculateObject.cost,
			margin: this.calculateObject.margin,
			amount: this.calculateObject.price,
			costTitleConcept: this.concept,
			costConcept: this.concept,
			customsUnit: this.customsUnit
		};
	}
}
