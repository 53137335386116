<div class="shipmentActionsBox">
	<p class="shipmentActionsBoxTitle">Shipment actions</p>
	<ng-container *ngFor="let step of stepsArray; index as index">
		<div
			class="shipmentActionsLine"
			[ngClass]="{ selected: index === selectedPosition }"
			(click)="onClickSendIndex(index, step.group)"
		>
			<div class="iconAndInfo">
				<img
					src="{{ step.img }}"
					alt="icono"
				/>
				<div class="shipmentActionsLineInfo">
					<p class="name">{{ step.name }}</p>
				</div>
			</div>
			<ng-container *ngIf="!groupesCompleted[step.group]">
				<span class="ledLight">
					<span class="ledLightStrong"></span>
				</span>
			</ng-container>
		</div>
	</ng-container>
</div>
