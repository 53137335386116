import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	StaticUtilitiesService,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { PortService } from 'src/app/Services/Api/port.service';
import { AddFreightPopup } from 'src/app/Shared/Class/commercial-offers/AddFreightPopup';
import { filter, takeUntil } from 'rxjs';
import { CalculateCostMarginPriceService } from 'src/app/Services/Utils/CalculateCostMarginPrice.service';
import { iCalculateCostMarginPriceObject } from 'src/app/Shared/Interfaces/Utils/iCalculateCostMarginPriceObject';

@Component({
	selector: 'addFreight-popup',
	templateUrl: './addFreight-popup.component.html',
	styleUrls: ['./addFreight-popup.component.scss']
})
export class AddFreightPopupComponent implements OnInit {
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.closePopup('noreturn');
		}
	}

	addFreightPopup: AddFreightPopup = new AddFreightPopup();

	constructor(
		public dialogRef: MatDialogRef<AddFreightPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any,
		private portSE: PortService,
		private calculateCostMarginPriceSE: CalculateCostMarginPriceService
	) {}

	ngOnInit() {
		this.getPorts();
	}

	/**
	 * HANDLERS
	 */

	successGetPortsHandler(res: iResultHttp) {
		let { data } = res;
		this.addFreightPopup.portOfOriginSelectorOptions.items = data;
		this.addFreightPopup.portOfDestinationSelectorOptions.items = data;
		this.addFreightPopup.transshipmentSelectorOptions.items = data;
	}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}

	getCalculatedObject(event: iCalculateCostMarginPriceObject) {
		this.addFreightPopup.calculateObject = event;
		this.checkIfCompleted();
	}

	generateBodyToReturn() {
		this.addFreightPopup.rellenarBodyToReturn();
		let body = this.addFreightPopup.bodyToReturn;
		this.closePopup(body);
	}

	checkIfCompleted() {
		const objectToCheck: any = {
			cost: this.addFreightPopup.calculateObject.cost,
			amount: this.addFreightPopup.calculateObject.price,
			origin: this.addFreightPopup.origin,
			destination: this.addFreightPopup.destination
		};

		this.addFreightPopup.isChecked =
			StaticUtilitiesService.isCompleteObject(objectToCheck);
	}

	/**
	 * API CALLS
	 */

	getPorts() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.portSE.getSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: this.successGetPortsHandler(res)
				});
			});
	}
}
