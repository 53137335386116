<main [@routerTransition]="animService.getCurrentAnimation()">
	<router-outlet></router-outlet>
</main>

<message-notification
	[isNotificationActive]="isNotificationActive"
	*ngIf="isNotificationActive"
></message-notification>

<responseMessage *ngIf="isResponseActive && !isNavHunter"></responseMessage>
<newMessage *ngIf="isNewActive && !isNavHunter"></newMessage>

<threadMessageList
	#threadMessageList
	*ngIf="isThreadMessageListActive && !isNavHunter"
	[threads]="threadsOpened"
></threadMessageList>

<feedback-shower
	*ngIf="feedback && !isNavHunter"
	[feedback]="feedback"
></feedback-shower>
