import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	StaticUtilitiesService,
	iOptionsSelector,
	iResultHttp
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ContainerTypeService } from 'src/app/Services/Api/container-type.service';

@Component({
	selector: 'add-container-line-popup',
	templateUrl: './add-container-line-popup.component.html',
	styleUrls: ['./add-container-line-popup.component.scss']
})
export class AddContainerLinePopupComponent implements OnInit {
	containerTypeSelectorOptions: iOptionsSelector = {
		items: [],
		search: true,
		classList: 'specialFormSelector',
		bindLabel: 'name',
		bindValue: 'id',
		append: true
	};

	idContainerSelected: any = null;

	constructor(
		public dialogRef: MatDialogRef<AddContainerLinePopupComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: any,
		private containerTypeSE: ContainerTypeService
	) {}

	ngOnInit() {
		this.getContainerTypeSelector();
	}

	/**
	 * HANDLERS
	 */

	successGetContainerTypeSelectorHandler(res: iResultHttp) {
		let { data } = res;
		this.containerTypeSelectorOptions.items = data;
	}

	/**
	 * FUNCTIONALITY
	 */
	closePopup(returnValue?: any) {
		let p = { returnValue: returnValue };
		this.dialogRef.close(p);
	}
	forceClosePopup() {
		this.dialogRef.close();
	}

	/**
	 * API CALLS
	 */

	getContainerTypeSelector() {
		const subject = StaticUtilitiesService.createSubject();
		const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
		this.containerTypeSE.getContainerTypeSelector(behaviorSubject);
		behaviorSubject
			.pipe(
				takeUntil(subject),
				filter((res) => res)
			)
			.subscribe((res: iResultHttp) => {
				StaticUtilitiesService.apiResponseHandler(res, subject, {
					method: () => this.successGetContainerTypeSelectorHandler(res)
				});
			});
	}
}
