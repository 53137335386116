import { Component, OnInit } from '@angular/core';
import {
	StaticUtilitiesService,
	slideNavigationTypes
} from '@quasar-dynamics/basic-designsystem';

@Component({
	selector: 'nav-hunter-header',
	templateUrl: './nav-hunter-header.component.html',
	styleUrls: ['./nav-hunter-header.component.scss']
})
export class NavHunterHeaderComponent implements OnInit {
	constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

	ngOnInit(): void {}

	goTo(route: string) {
		this.staticUtilitiesSE.goTo(route, slideNavigationTypes.slideToLeft);
	}
}
