import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iNavHunterOptionIcon } from '../../../Interfaces/Utils/iNavHunterOptionIcon';

@Component({
	selector: 'nav-hunter-option-icon',
	templateUrl: './nav-hunter-option-icon.component.html',
	styleUrls: ['./nav-hunter-option-icon.component.scss']
})
export class NavHunterOptionIconComponent implements OnInit {
	@Input() characteristics: iNavHunterOptionIcon = {
		option: 'Cheaper',
		extraInfo: ''
	};

	imageToDisplay: string = '';
	title: string = '';
	background: string = '';

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['characteristics']) {
			if (
				changes['characteristics'] &&
				changes['characteristics'].currentValue
			) {
				let characteristics = changes['characteristics'].currentValue;
				switch (characteristics.option) {
					case 'Cheaper':
						this.imageToDisplay = '/assets/Images/NavHunter/dollar-sign.svg';
						this.title = 'Cheaper';
						this.background = '#B6FFAA';
						break;
					case 'Faster':
						this.imageToDisplay = '/assets/Images/NavHunter/zap.svg';
						this.title = 'Faster';
						this.background = '#F5FFB6';
						break;
					case 'Closer':
						this.imageToDisplay = '/assets/Images/NavHunter/fast-forward.svg';
						this.title = 'Closer departure date';
						this.background = '#FFD7C0';
						break;

					default:
						break;
				}
			}
		}
	}
}
